import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServerService } from '../../services/server.service';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';

declare var $: any;

@Component({
  selector: 'app-add-article-to-section',
  templateUrl: './add-article-to-section.component.html',
  styleUrls: ['./add-article-to-section.component.scss'],
})
export class AddArticleToSectionComponent implements OnInit {

  outstanding_article = false;
  outstanding_article_temp:any;

  myForm = this.fb.group({
    title: ['', [Validators.required, Validators.minLength(2)]],
    subtitle: ['', [Validators.required, Validators.minLength(2)]],
    drafted_by: ['', [Validators.required, Validators.minLength(2)]],
    icon: ['', [Validators.required, Validators.minLength(2)]],
    outstanding_article: ['']
  });

  data: any;
  dataSection: any;

  constructor(public router: Router, public server: ServerService, private fb: FormBuilder,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.getSection();
  }

  getSection() {
    this.server.getSection(this.activatedRoute.snapshot.params.id)
      .subscribe(response => {
        this.data = response;
        this.dataSection = this.data.data;
      });
  }

  addArticleToSection() {
    this.data = null;
    this.server.addArticleToSection(this.activatedRoute.snapshot.params.id, this.myForm.value).subscribe((data) => {
      this.data = data;
      if (this.data.msg != null) {
        this.myForm.reset();
        this.router.navigate(['get-articles/' + this.activatedRoute.snapshot.params.id]);
        this.showNotification('top', 'center', 2, 2, 'ARTÍCULO AÑADIDO SATISFACTORIAMENTE!!!')
        return true;
      }
    });
    return false;
  }

  showNotification(from, align, col, time, message) {
    const type = ['', 'info', 'success', 'warning', 'danger'];

    // const color = Math.floor((Math.random() * 4) + 1);
    // 4 red 3 yellow 2 green 1 blue
    const color = col;

    $.notify({
      icon: 'notifications',
      message: message

    }, {
      type: type[color],
      timer: time * 1000,
      placement: {
        from: from,
        align: align
      },
      template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span class="text-center" data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }
}
