import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServerService } from './../../services/server.service';

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  role: string;
}
export const ROUTES: RouteInfo[] = [
  /*     { path: '/home', title: 'Inicio',  icon: 'dashboard', class: '' }, */
  { path: '/dashboard', title: 'Panel de Contenidos', icon: 'dashboard', class: '',role: 'support' },
  { path: '/chat', title: 'Soporte al Cliente', icon: 'group', class: '',role: 'support' },
  { path: '/client-view', title: 'Vista del Cliente', icon: 'supervised_user_circle', class: '',role: 'support' },
  { path: '/user-panel', title: 'Panel de Usuarios', icon: 'person', class: '',role: 'admin'},
  { path: '/user-buyer', title: 'Usuarios Compradores', icon: 'person', class: '', role: 'shopping' },
  { path: '/accounts', title: 'Cuentas', icon: 'money', class: '', role: 'admin' },
  { path: '/movements', title: 'Movimientos', icon: 'money', class: '', role: 'admin' },
  { path: '/shoppings', title: 'Compras', icon: 'shopping_cart', class: '', role: 'shopping' },
  { path: '/shippings', title: 'Envios', icon: 'send', class: '', role: 'shopping' },  
  { path: '/packages', title: 'Paquetes', icon: 'card_giftcard', class: '', role: 'admin' },
  { path: '/config', title: 'Configuraciones', icon: 'settings', class: '', role: 'admin' },
  
  /*     { path: '/transfer-indirect', title: 'Transferencia Indirecta',  icon:'attach_money', class: '' },
      { path: '/contacts', title: 'Contactos',  icon:'group', class: '' },
      { path: '/transfer-to-contact', title: 'Transferencias-Clientes',  icon:'send', class: '' },
      { path: '/transfer-to-agency', title: 'Transferencias-Agencias',  icon:'send', class: '' },
      { path: '/profile', title: 'Perfil de Usuario',  icon:'person', class: '' }, */


  // { path: '/table-list', title: 'Table List',  icon:'content_paste', class: '' },
  // { path: '/typography', title: 'Typography',  icon:'library_books', class: '' },
  // { path: '/icons', title: 'Icons',  icon:'bubble_chart', class: '' },
  // { path: '/maps', title: 'Maps',  icon:'location_on', class: '' },
  // { path: '/notifications', title: 'Notificaciones',  icon:'notifications', class: '' },
  // { path: '/upgrade', title: 'Upgrade to PRO',  icon:'unarchive', class: 'active-pro' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  data:any;
  username:any
  id:any;
  role:any;

  constructor(public _ServerService: ServerService ,public router: Router) { 
    this.username = localStorage.getItem('username');
    this.id = localStorage.getItem('user_id');
    this.role = localStorage.getItem('user_role');
  }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  };

  profileEdit(){
    this.router.navigate([`edit-user/${this.id}`])
  }

  salirApp() {
    if (confirm('¿Desea salir de la App?')) {
      this._ServerService.changeStatus(localStorage.getItem('user_id'), { status: false }).subscribe(response => {
        console.log(response);
        this.data = response;
        if (this.data.success) {
          localStorage.clear();
          this.router.navigate(['/login']);
        }
      })
    }
  }
}
