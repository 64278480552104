import { element } from 'protractor';
import { TarifHelper } from './tarif-helper';

export class CalculatorHelper {
    private tarifHelper: TarifHelper;
    public fares: any;


    public roundUpperHalf(num: number): number {
        return Math.ceil(num * 2) / 2;
    }

    public aerovaraderoTariff(weightToCharge, shippingType, packageQuantity) {
        let weight = this.roundUpperHalf(weightToCharge);
        if (shippingType === 'ENVIOS 1.5 KG') {
            console.log('tarifa 1.5 kg')
            return packageQuantity * this.fares.habana_Base_1_5.toFixed(2)


        } else {
            console.log('el weight :', weight)
            console.log('tarifa +2kg o moviles')
            return  this.tarifHelper.tarifFromWeight(weight)           
        }
    }



    public pickupService(province: string, realWeight: number): number {
        return province === 'La Habana' ? 0 : 1.5 * realWeight
    }


    public sendToProvince(province: string, realWeight: number): number {
        return province === 'La Habana' ? 0 : realWeight
    }

    public payeer(province: string, realWeight: number): number {
        return province === 'La Habana' ? 0 : realWeight * 0.2
    }

    public transportation(province: string, realWeight: number): number {
        return (province === 'La Habana' || province === 'La HabDomic') ? 0 : realWeight * 0.7
    }

    public extrasCustomDuty(province: string, realWeight: number, customsDutyType: string): number {
        console.log("calcuting customs", realWeight, customsDutyType, this.fares.price_cup_custom_cuba);
        let extrasCustomDutyOthers = province === 'La Habana'
            ? 0
            : customsDutyType === 'Arancel por importar misceláneas con peso en exceso de los 1.5 kg'
                ? ((realWeight * 20) - 30) * 24 / this.fares.price_cup_custom_cuba
                : 0
        
        if (extrasCustomDutyOthers < 0) {
            extrasCustomDutyOthers = 0;
        }
        return extrasCustomDutyOthers
    }


    public salePrice( weightToCharge: number,
                       customDutyPrice: number,
                       sendToProvincePrice: number,
                       payeerPrice: number,
                       transportationPrice: number,
                       extrasCustomDutyPrice: number,
                       pickup: number,) {
        let value;
        this.tarifHelper = new TarifHelper();
        this.tarifHelper.fares = this.fares;
        let fare = this.tarifHelper.selectClientFare(this.roundUpperHalf(weightToCharge));
        console.log(fare);    
        console.log(fare, customDutyPrice, sendToProvincePrice, payeerPrice, transportationPrice, extrasCustomDutyPrice, pickup);
        return Number(fare) + Number(customDutyPrice) + Number(sendToProvincePrice) + Number(payeerPrice) + Number(transportationPrice) + Number(extrasCustomDutyPrice) + Number(pickup);
        
    }

    public sum (element1: number,
                element2: number,
                element3: number,
                element4: number,
                element5: number,
                element6: number,
                element7: number,
                element8: number,
                element9: number,
                element10: number) {
        return element1 + element2 + element3 + element4 + element5 + element6 + element7 + element8 + element9 + element10;
        
    }

    public customDuty(realWeight: number,
                      packageQuantity: number,
                      shippingType: string,
                      province: string,) {   
        
        let value = province === 'La Habana' ? 0 :
            shippingType == 'ENVIOS 1.5 KG' ? (2 * packageQuantity / 1.1) :

                shippingType !== 'ENVIOS 1.5 KG' && realWeight <= 10 ? (3 / 1.1) :
                    shippingType !== 'ENVIOS 1.5 KG' && realWeight > 10 ? (5 / 1.1) : 0
        return value
        
    }
}