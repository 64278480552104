import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router'
import { AuthService } from '../../services/auth/auth.service';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';

declare const $: any;

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit, AfterViewInit {

  @ViewChild('username', {static:false}) usernameChild! : ElementRef;

  myForm = this.fb.group({
    username: ['', [Validators.required, Validators.minLength(3)]],
  });

  message: string;
  data: any;

  constructor(public authService: AuthService, private router: Router, private fb: FormBuilder) { }
  
  ngOnInit() {
    this.message = '';
  }
  
  ngAfterViewInit(): void {
    setTimeout(() => this.usernameChild.nativeElement.focus());
  }

  isMobile() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  };

  forgotPassword(): boolean {
    this.message = '';
    this.authService.forgotPassword(this.myForm.value)
      .subscribe(data => {
        this.data = data;
        if (this.data.msg != null) {
          this.message = 'SE LE HA ENVIADO UN CÓDIGO A SU CUENTA DE CORREO.';
          setTimeout(function () {
            this.message = '';
            this.router.navigate(['/set-new-password']);
          }.bind(this), 3000);
        }
      });
    return false;
  }

}
