import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ServerService } from '../../services/server.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ConfirmComponent } from '../confirm/confirm.component'
import { MatDatepickerModule } from '@angular/material/datepicker';
import { environment } from './../../../environments/environment';

declare var $: any;

class AccountSummary {
  name: string;
  total: number;
  entradas: number;
  salidas: number;
  totalEuros: number;
  entradasEuros: number;
  salidasEuros: number;

  constructor(
    name: string
    , total: number
    , entradas: number
    , salidas: number
    , totalEuros: number
    , entradasEuros: number
    , salidasEuros: number
  ) {
    this.name = name;
    this.total = total;
    this.entradas = entradas;
    this.salidas = salidas;

    this.totalEuros = totalEuros;
    this.entradasEuros = entradasEuros;
    this.salidasEuros = salidasEuros;
  }
}

@Component({
  selector: 'app-movements',
  templateUrl: './movements.component.html',
  styleUrls: ['./movements.component.scss'],
})


export class MovementsComponent implements OnInit {

  favoriteSeason: string = "support";
  user_role: any;

  myForm = this.fb.group({
    description: [''],
    amountgt: [''],
    amountlt: [''],
    dategt: [''],
    datelt: [''],
  });
  public accountsSummary: AccountSummary[] = [];
  public summaryTotal: AccountSummary = new AccountSummary("Total", 0, 0, 0, 0, 0, 0);
  private _movementList: any[];
  public get movementList() {
    return this._movementList;
  }

  public set movementList(data) {
    this._movementList = data;
    this.buildAccountSummary();
  };

  data: any;
  account: any;
  isLoading: boolean;

  constructor(public _ServerService: ServerService, public router: Router, private fb: FormBuilder, public dialog: MatDialog,
    public activatedRoute: ActivatedRoute) {
    this.user_role = localStorage.getItem('user_role');

  }

  getAmountEuro(movement): Number {
    return movement.amount / movement.exchangeRate;
  }

  ngOnInit() {
    this.getMovements();
  }

  private buildAccountSummary() {
    this.accountsSummary = [];
    this.movementList.forEach(movement => {
      if (movement.account){
        let currentAccount = this.accountsSummary
        .find(x =>
          x.name == movement.account.name
        );
      const account = currentAccount
        ? currentAccount
        : this.buildAccountAndAddToArray(movement.account.name);
      account.total += movement.amount;
      account.totalEuros += movement.amount / movement.exchangeRate
      account.entradas += movement.amount > 0
        ? movement.amount
        : 0
      account.entradasEuros += movement.amount > 0
        ? movement.amount / movement.exchangeRate
        : 0
      account.salidas += movement.amount < 0
        ? movement.amount
        : 0
      account.salidasEuros += movement.amount < 0
        ? movement.amount / movement.exchangeRate
        : 0
      }
    })
    const total = this.accountsSummary.length > 0 ? this.accountsSummary
      .map(x => x.total)
      .reduce((x, y) => x + y) : 0;
    const entradas = this.accountsSummary.length > 0 ? this.accountsSummary
      .map(x => x.entradas)
      .reduce((x, y) => x + y) : 0;
    const salidas = this.accountsSummary.length > 0 ? this.accountsSummary
      .map(x => x.salidas)
      .reduce((x, y) => x + y) : 0;


    const totalEuros = this.accountsSummary.length > 0 ? this.accountsSummary
      .map(x => x.totalEuros)
      .reduce((x, y) => x + y) : 0;
    const entradasEuros = this.accountsSummary.length > 0 ? this.accountsSummary
      .map(x => x.entradasEuros)
      .reduce((x, y) => x + y) : 0;
    const salidasEuros = this.accountsSummary.length > 0 ? this.accountsSummary
      .map(x => x.salidasEuros)
      .reduce((x, y) => x + y) : 0;

    this.summaryTotal = new AccountSummary(
      "Total"
      , total
      , entradas
      , salidas
      , totalEuros
      , entradasEuros
      , salidasEuros
    )

    console.log(this.summaryTotal);
  }

  private buildAccountAndAddToArray(name: string): AccountSummary {
    let account = new AccountSummary(
      name,
      0,
      0,
      0,
      0,
      0,
      0
    )
    this.accountsSummary.push(account)
    return account;
  }


  getMovements() {
    this.isLoading = true;
    this._ServerService.getAllMovements().subscribe((response) => {
      this.account = response;
      this.movementList = this.account.data;
      this.movementList.sort((a, b) => Date.parse(b.date) - Date.parse(a.date));
      this.isLoading = false;
    });
  }

  deleteMovement(id) {
    if (confirm("El movimiento será eliminado permanentemente.\n ¿Desea continuar?")) {
      this.isLoading = true;
      this._ServerService.deleteMovement(id).subscribe((response) => {
        console.log(response);
        this.getMovements();
      })
    }
  }

  verifyMovement(id) {
    this.isLoading = true;
    this._ServerService.veryfyMovement(id, { status: "verified" }).subscribe((response) => {
      console.log(response);
      this.getMovements();
    })
  }

  filterMovements() {
    console.log(this.myForm.value);
    this.isLoading = true;
    this._ServerService.findMovement(this.myForm.value).subscribe((response) => {
      this.account = response;
      this.movementList = this.account.data;
      this.movementList.sort((a, b) => Date.parse(b.date) - Date.parse(a.date));
      console.log(response);
      this.isLoading = false;
    })
  }

  getBack() {
    this.router.navigate([`/accounts`]);
  }









}
