import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HideSideBarService {

  private status = new BehaviorSubject<boolean>(true);
  public getStatus = this.status.asObservable();

  constructor() {}
  
  public changeStatus(status: boolean): void {
    this.status.next(status);
  }
}
