import { Component, Inject, OnInit } from '@angular/core';

import { FormBuilder, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServerService } from './../../services/server.service';


declare var $: any;


@Component({
  selector: 'pay-package',
  templateUrl: './pay-package.component.html',
  styleUrls: ['./pay-package.component.scss'],
})
export class PayPackageComponent implements OnInit {

  allAccounts: any;
  arrayAccounts: any;
  objInfo: any;
  public accountExchangeRateUnknow = false;
  _selectedAccount: any;

  public set selectedAccount(value) {
    const account = this.arrayAccounts.find(x => x._id == value._id);
    console.log("inside selectedAccount", account)
    this.accountExchangeRateUnknow = account.type == 'crypto' || account.type == 'mn' || account.type == 'otros'
    this._selectedAccount = value;
  }

  public get selectedAccount(){
    return this._selectedAccount;
  }

  myForm = this.fb.group({
    account: ['', [Validators.required]],
    rate: [1, [Validators.required, Validators.pattern("^[-.0-9]*$")]],
  });


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<PayPackageComponent>,
    public server: ServerService) {
  }

  ngOnInit() {
    this.getAllAccounts();
    this.objInfo = this.data;
  }

  getAllAccounts() {
    this.server.getAllAccounts().subscribe((response) => {
      this.allAccounts = response;
      this.arrayAccounts = this.allAccounts.data
      console.log('Las cuentas :', this.arrayAccounts);
    })
  }


  selectAccount() {
    this.dialogRef.close(this.myForm.value);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }



  showNotification(from, align, col, time, message) {
    const type = ['', 'info', 'success', 'warning', 'danger'];

    // const color = Math.floor((Math.random() * 4) + 1);
    // 4 red 3 yellow 2 green 1 blue
    const color = col;

    $.notify({
      icon: 'notifications',
      message: message

    }, {
      type: type[color],
      timer: time * 1000,
      placement: {
        from: from,
        align: align
      },
      template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span class="text-center" data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }


}
