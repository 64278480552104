import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServerService } from '../../services/server.service';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';

declare var $: any;

@Component({
  selector: 'app-get-paragraphs',
  templateUrl: './get-paragraphs.component.html',
  styleUrls: ['./get-paragraphs.component.scss'],
})
export class GetParagraphsComponent implements OnInit {

  data: any;
  dataArticle: any;
  dataSection: any;
  p1:number = 1;

  constructor(public router: Router, public server: ServerService, private fb: FormBuilder,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.getArticle();
    this.getSection();
  }

  getArticle() {
    this.server.getArticle(this.activatedRoute.snapshot.params.id_article)
      .subscribe(response => {
        this.data = response;
        this.dataArticle = this.data.data;
      });
  }

  getSection() {
    this.server.getSection(this.activatedRoute.snapshot.params.id_section)
      .subscribe(response => {
        this.data = response;
        this.dataSection = this.data.data;
      });
  }

  addParagraphToArticle(): any {
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['get-articles/' + this.activatedRoute.snapshot.params.id_section + '/add-paragraph-to-article/' + this.dataArticle._id]);
  }

  paragraphEdit(id: string): any {
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['get-articles/' + this.activatedRoute.snapshot.params.id_section + '/get-paragraphs/' + this.activatedRoute.snapshot.params.id_article + '/edit-paragraph/' + id]);
  }

  paragraphDelete(id: string): boolean {
    if (confirm('¿DESEA ELIMINAR ESTE PÁRRAFO?')) {
      this.data = null;
      this.server.paragraphDelete(id)
        .subscribe(data => {
          this.data = data;
          this.ngOnInit();
          this.showNotification('top', 'center', 2, 2, 'PÁRRAFO ELIMINADO SATISFACTORIAMENTE!!!')
          if (this.data.msg != null) {
            return true;
          }
        });
    }
    return false;
  }

  showNotification(from, align, col, time, message) {
    const type = ['', 'info', 'success', 'warning', 'danger'];

    // const color = Math.floor((Math.random() * 4) + 1);
    // 4 red 3 yellow 2 green 1 blue
    const color = col;

    $.notify({
      icon: 'notifications',
      message: message

    }, {
      type: type[color],
      timer: time * 1000,
      placement: {
        from: from,
        align: align
      },
      template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span class="text-center" data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }

}
