import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ServerService } from "app/services/server.service";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

export class ShippingAutoComplete {

  senderNameToFind = new FormControl();
  receptorNameToFind = new FormControl();
  filteredSenderNames: Observable<string[]>;
  filteredReceptorNames: Observable<string[]>;
  textToShow1: string;
  textToShow2: string;
  toHighlight: string;
  myForm: FormGroup;
  senderIdArray: string[];
  senderAddressArray: string[];
  senderEmailArray: string[];
  senderPhoneArray: string[];
  senderIdMultiple: boolean;
  senderAddressMultiple: boolean;
  senderEmailMultiple: boolean;
  senderPhoneMultiple: boolean;
  receptorUserMultiple: boolean;
  senderNameArray: string[] = [];
  receptorNameArray: string[] = [];
  receptorIdArray: string[];
  receptorAddressArray: string[];
  receptorEmailArray: string[];
  receptorPhoneArray: string[];
  receptorIdMultiple: boolean;
  receptorAddressMultiple: boolean;
  receptorEmailMultiple: boolean;
  receptorPhoneMultiple: boolean;
  senderSelected: boolean = false;
  receptorSelected: boolean = false;
  otherReceptorName: boolean = false;
  otherSenderName: boolean = false;
  otherReceptorAddress: boolean = true;
  receptorAddressObject;
  receptorAutocompleted: boolean = false;
  senderAutocompleted: boolean = false;
  isLoadingSenders: boolean = false;
  isLoadingReceptors: boolean = false;

  public _ServerService: ServerService;

  senderNames
  receptorNames



  getSenderNames() {
    this.isLoadingSenders = true;
    this.textToShow1 = "Cargando ...."
    this._ServerService.getSenderNameShippings().subscribe(response => {
      this.senderNames = (response as any).data;
      console.log(this.senderNames);
      this.senderNames.forEach(element => {
        this.senderNameArray.push(element.senderName);
      })
      this.filteredSenderNames = this.initializeAutocomplete(this.senderNameToFind, this.senderNameArray);
      this.textToShow1 = "Seleccione uno"
      this.isLoadingSenders = false;
    })
  }

  getReceptorsLinkedToSender(receptorObjectArray) {
    this.receptorNameArray = [];
    receptorObjectArray.forEach(element => {
      this.receptorNameArray.push(element.receptorName)
    })
    console.log('El receptorNameArray', this.receptorNameArray);
    this.senderSelected = true;

  }

  getSendersLinkedToReceptor(senderObjectArray) {
    console.log('El senderObjectArray :', senderObjectArray);
    this.senderNameArray = [];
    senderObjectArray.forEach(element => {
      this.senderNameArray.push(element.senderName)
    })
    console.log('El senderNameArray', this.senderNameArray);
    this.receptorSelected = true;

  }

  public getReceptorNames() {
    this.isLoadingReceptors = true;
    this.textToShow2 = "Cargando ...."
    this._ServerService.getReceptorNameShippings().subscribe(response => {
      this.receptorNames = (response as any).data;
      console.log(this.receptorNames);
      let receptorNameArray = [];
      this.receptorNames.forEach(element => {
        receptorNameArray.push(element.receptorName);
      })
      this.filteredReceptorNames = this.initializeAutocomplete(this.receptorNameToFind, receptorNameArray);
      this.textToShow2 = "Seleccione uno";
      this.isLoadingReceptors = false;
    })
  }

  public initializeAutocomplete(
    searchedValue: FormControl
    , variable: string[]) {
    return searchedValue.valueChanges.pipe(
      startWith(''),
      map(value => value.length >= 1 ? this._filter(value, variable) : [])
    )
  }

  public _filter(value: string, array: string[]): string[] {
    this.toHighlight = value;
    const filterValue = value.toLowerCase();
    return array.filter(option => option.toLowerCase().includes(filterValue));
  }



  patchSenderId(ids: string[]) {
    if (ids.length === 0) {
      this.myForm.patchValue({ senderId: '' });
      this.senderIdArray = [];
    } else {
      this.myForm.patchValue({ senderId: ids[0] });
      this.senderIdArray = ids;
    }
    ids.length > 1 ? this.senderIdMultiple = true : this.senderIdMultiple = false
  }

  patchReceptorId(ids: string[]) {
    if (ids.length === 0) {
      this.myForm.patchValue({ receptorId: '' });
      this.receptorIdArray = [];
    } else {
      this.myForm.patchValue({ receptorId: ids[0] });
      this.receptorIdArray = ids;
    }
    ids.length > 1 ? this.receptorIdMultiple = true : this.receptorIdMultiple = false
  }


  patchSenderAddress(addresses: string[]) {
    if (addresses.length === 0) {
      this.myForm.patchValue({ senderAddress: '' });
      this.senderAddressArray = [];
    } else {
      this.myForm.patchValue({ senderAddress: addresses[0] });
      this.senderAddressArray = addresses;
    }
    addresses.length > 1 ? this.senderAddressMultiple = true : this.senderAddressMultiple = false
  }

  patchReceptorAddress(addresses) {
    this.otherReceptorAddress = false;
    this.receptorAddressMultiple = true;
    if (addresses.length === 0) {
      this.myForm.patchValue({ receptorAddress: '', province: '' });
      this.receptorAddressArray = [];
    } else {
      if (addresses[0] && addresses[0].address && addresses[0].province) {
        this.myForm.patchValue({
          receptorAddress: addresses[0].address,
          province: addresses[0].province
        });
        this.receptorAddressArray = [];
        addresses.forEach(element => {
          this.receptorAddressArray.push(element.address);
        })
      }      
    }
    //addresses.length > 1 ? this.receptorAddressMultiple = true : this.receptorAddressMultiple = false
  }

  patchSenderEmail(emails: string[]) {
    if (emails.length === 0) {
      this.myForm.patchValue({ senderEmail: '' });
      this.senderEmailArray = [];
    } else {
      this.myForm.patchValue({ senderEmail: emails[0] });
      this.senderEmailArray = emails;
    }
    emails.length > 1 ? this.senderEmailMultiple = true : this.senderEmailMultiple = false
  }

  patchSenderPhone(phones: string[]) {
    if (phones.length === 0) {
      this.myForm.patchValue({ senderPhone: '' });
      this.senderPhoneArray = [];
    } else {
      this.myForm.patchValue({ senderPhone: phones[0] });
      this.senderPhoneArray = phones;
    }
    phones.length > 1 ? this.senderPhoneMultiple = true : this.senderPhoneMultiple = false
  }

  patchReceptorPhone(phones: string[]) {
    if (phones.length === 0) {
      this.myForm.patchValue({ receptorPhone: '' });
      this.receptorPhoneArray = [];
    } else {
      this.myForm.patchValue({ receptorPhone: phones[0] });
      this.receptorPhoneArray = phones;
    }
    phones.length > 1 ? this.receptorPhoneMultiple = true : this.receptorPhoneMultiple = false
  }

  searchSenderName(object?) {    
    let senderNameObject
    object ? senderNameObject = object :
      senderNameObject = this.senderNames.find(element => element.senderName === this.senderNameToFind.value);
    if (senderNameObject) {
      console.log(senderNameObject)
      console.log('Existe autocompletado :', senderNameObject)
      this.senderAutocompleted = true;
      this.myForm.patchValue({ senderName: senderNameObject.senderName });
      this.patchSenderId(senderNameObject.senderId);
      this.patchSenderAddress(senderNameObject.senderAddress);
      this.patchSenderEmail(senderNameObject.senderEmail);
      this.patchSenderPhone(senderNameObject.senderPhone);
      if (!this.receptorSelected && this.myForm.value.receptorName == ""){        
        this.getReceptorsLinkedToSender(senderNameObject.receptorUsers);
      }
        

    } else {
      console.log('No existe autocompletado :', senderNameObject)
      this.myForm.patchValue({
        senderName: this.senderNameToFind.value
      });
      if (this.senderSelected) {
        console.log('El sender is selected')
        this.resetSender();
        this.resetReceptor();
        
      } else {
        console.log('El sender no es selected')
        if (this.senderAutocompleted) {
          console.log('el sender es autocomplete')
          this.senderAutocompleted = false;
          this.resetSender();
        }
      }

    }
  }


  resetReceptor(other?): void {
    if (this.isLoadingReceptors) return;
    other ? this.otherReceptorName = true : this.otherReceptorName = false;
    this.receptorIdMultiple = false;
    this.receptorAddressMultiple = false;
    this.receptorPhoneMultiple = false;
    this.otherReceptorAddress = true;
    this.receptorSelected = false;
    this.receptorIdArray = [];
    this.receptorAddressArray = [];
    this.receptorPhoneArray = [];
    this.myForm.patchValue({
      receptorName: "",
      receptorId: "",
      receptorAddress: "",
      receptorPhone: "",
      province: "",
    });
  }

  resetSender(other?): void {
    other ? this.otherSenderName = true : this.otherSenderName = false;
    this.senderIdMultiple = false;
    this.senderAddressMultiple = false;
    this.senderEmailMultiple = false;
    this.senderPhoneMultiple = false;
    this.senderSelected = false;
    this.senderIdArray = [];
    this.senderAddressArray = [];
    this.senderPhoneArray = [];
    this.senderEmailArray = [];
    this.myForm.patchValue({
      senderName: "",
      senderId: "",
      senderAddress: "",
      senderEmail: "",
      senderPhone: "",
    });
  } 

  searchReceptorName(object?) {
    let receptorNameObject
    object ? receptorNameObject = object :
      receptorNameObject = this.receptorNames.find(element => element.receptorName === this.receptorNameToFind.value);
    if (receptorNameObject) {
      console.log('Existe autocompletado :', receptorNameObject)
      this.receptorAutocompleted = true;
      this.otherReceptorAddress = true;
      this.myForm.patchValue({ receptorName: receptorNameObject.receptorName });
      this.patchReceptorId(receptorNameObject.receptorId);
      this.patchReceptorAddress(receptorNameObject.receptorAddress);
      this.receptorAddressObject = receptorNameObject.receptorAddress;
      this.patchReceptorPhone(receptorNameObject.receptorPhone);
      if (!this.senderSelected && this.myForm.value.senderName == "")
        this.getSendersLinkedToReceptor(receptorNameObject.senderUsers);
    } else {
      console.log('No existe autocompletado :', receptorNameObject);
      this.myForm.patchValue({
        receptorName: this.receptorNameToFind.value
      });
      if (this.receptorSelected) {
        this.resetSender();
        this.resetReceptor();
      } else {
        if (this.receptorAutocompleted) {
          this.receptorAutocompleted = false;
          this.resetReceptor();
        }
      }
    }
  }
}