import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ServerService } from './../../services/server.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ConfirmComponent } from './../confirm/confirm.component'
import { environment } from './../../../environments/environment';

declare var $: any;

class AccountSummary {
  name: string;
  total: number;
  entradas: number;
  salidas: number;
  totalEuros: number;
  entradasEuros: number;
  salidasEuros: number;

  constructor(
    name: string
    , total: number
    , entradas: number
    , salidas: number
    , totalEuros: number
    , entradasEuros: number
    , salidasEuros: number
  ) {
    this.name = name;
    this.total = total;
    this.entradas = entradas;
    this.salidas = salidas;

    this.totalEuros = totalEuros;
    this.entradasEuros = entradasEuros;
    this.salidasEuros = salidasEuros;
  }
}

@Component({
  selector: 'app-view-recharge',
  templateUrl: './view-recharge.component.html',
  styleUrls: ['./view-recharge.component.scss'],
})


export class ViewRechargeComponent implements OnInit {

  favoriteSeason: string = "support";
  user_role: any;

  myForm = this.fb.group({
    description: [''],    
    amountgt: [''],
    amountlt: [''],
    dategt: [''],
    datelt: [''],
  });

  public accountsSummary: AccountSummary[] = [];
  public summaryTotal: AccountSummary = new AccountSummary("Total", 0,0,0, 0, 0, 0);
  private _movementList: any[];
  public get movementList() {
    return this._movementList;
  }

  public set movementList(data) {
    this._movementList = data;
    this.buildAccountSummary();
  };
  data: any;
  buyer: any;
  accounts: any[];
  isLoading: boolean;
  constructor(public _ServerService: ServerService, public router: Router, private fb: FormBuilder, public dialog: MatDialog,
    public activatedRoute: ActivatedRoute) {
    this.user_role = localStorage.getItem('user_role');

  }

  ngOnInit() {
    this.getBuyer();
  }

  filterMovements(){
    this.isLoading = true;
    console.log (this.myForm.value);
    this._ServerService.getMovementsByUser(this.activatedRoute.snapshot.params.id, this.myForm.value).subscribe((response) => {
      this.movementList = response['data'];
      this.movementList.sort((a, b) => Date.parse(b.date) -  Date.parse(a.date));
      console.log(response);
      this.isLoading = false;
    })   
  }

  getAmountEuro(movement):Number{
    return movement.amount / movement.exchangeRate;
} 

  private buildAccountAndAddToArray(name: string): AccountSummary {
    let account = new AccountSummary(
      name,
      0,
      0,
      0,
      0,
      0,
      0
    )
    this.accountsSummary.push(account)
    return account;
  }

  getBuyer(id?) {
    this.isLoading = true;
    this._ServerService.getOneBuyer(this.activatedRoute.snapshot.params.id).subscribe((response) => {
       this.buyer = response;
    //   this._ServerService.getAllAccounts().subscribe(accounts => {
    //     console.log(accounts)
    //     this.accounts = accounts['data'];
    //     this.buyer.data.movimientos.forEach(movement => {
    //       movement.account = this.accounts.find(x => x._id == movement.account);
    //     })
    //     this.movementList = this.buyer.data.movimientos
    //   })
      
    //   console.log (response)
    // });
    this.filterMovements();
    this._ServerService.getAllAccounts().subscribe(accounts => {
      this.accounts = accounts['data'];

      this.isLoading = false;
    });
  });

  }

  private buildAccountSummary(){
    this.accountsSummary = [];
    this.movementList.forEach(movement => {
      if (movement.account){
      let currentAccount = this.accountsSummary
        .find(x => 
          x.name == movement.account.name
        );
      const account = currentAccount
          ? currentAccount
          : this.buildAccountAndAddToArray(movement.account.name);
      account.total += movement.amount;
      account.totalEuros += movement.amount / movement.exchangeRate
      account.entradas += movement.amount > 0 
        ? movement.amount 
        : 0
      account.entradasEuros +=movement.amount > 0 
        ? movement.amount / movement.exchangeRate
        : 0
      account.salidas += movement.amount < 0 
        ? movement.amount 
        : 0
      account.salidasEuros += movement.amount < 0 
        ? movement.amount / movement.exchangeRate
        : 0
        }
    })
    const total = this.accountsSummary.length > 0 ? this.accountsSummary
      .map(x => x.total)
      .reduce( (x,y) => x + y) : 0;
    const entradas = this.accountsSummary.length > 0 ? this.accountsSummary
      .map(x => x.entradas)
      .reduce( (x,y) => x + y) : 0;
    const salidas = this.accountsSummary.length > 0 ? this.accountsSummary
      .map(x => x.salidas)
      .reduce( (x,y) => x + y) : 0;


      const totalEuros = this.accountsSummary.length > 0 ? this.accountsSummary
      .map(x => x.totalEuros)
      .reduce( (x,y) => x + y) : 0;
    const entradasEuros = this.accountsSummary.length > 0 ? this.accountsSummary
      .map(x => x.entradasEuros)
      .reduce( (x,y) => x + y) : 0;
    const salidasEuros = this.accountsSummary.length > 0 ? this.accountsSummary
      .map(x => x.salidasEuros)
      .reduce( (x,y) => x + y) : 0;

    this.summaryTotal = new AccountSummary(
      "Total"
      , total
      , entradas
      , salidas
      , totalEuros
      , entradasEuros
      , salidasEuros
    )
  }

  deleteMovement(id) {
    if (confirm("La transacción será eliminada permanentemente.\n ¿Desea continuar?")) {
      this._ServerService.deleteMovement(id).subscribe((response) => {
        console.log(response);
        this.showNotification("top", "center", 2, 2, "El movimiento ha sido eliminado satisfactoriamente.");
        this.getBuyer();
      })
    }

  }

  addMovement() {
    localStorage.setItem('buyer',this.buyer.data.nombre);
    let id= this.activatedRoute.snapshot.params.id
    localStorage.setItem('backId-1', id);
    this.router.navigate([`/add-movement/${id}`]);
  }

  verifyMovement(id) {
    this.isLoading = true;
    this._ServerService.veryfyMovement(id, { status: "verified" }).subscribe((response) => {
      console.log(response);
      this.getBuyer();
    })
  }
  
  getBack(){
    this.router.navigate([`/user-buyer`]);
  
  
  }

  
  showNotification(from, align, col, time, message) {
    const type = ['', 'info', 'success', 'warning', 'danger'];

    // const color = Math.floor((Math.random() * 4) + 1);
    // 4 red 3 yellow 2 green 1 blue
    const color = col;

    $.notify({
      icon: 'notifications',
      message: message

    }, {
      type: type[color],
      timer: time * 1000,
      placement: {
        from: from,
        align: align
      },
      template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span class="text-center" data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }
  

  

  
  

  

}
