import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ItemsAllowCubaService {

  constructor() { }

  getItems() {
    // tslint:disable-next-line: no-unused-expression
    return [
      {
        category: 'CAPÍTULO A - MEDICAMENTOS',
        element: [
          {
            name: '1. Medicamentos',
            um: 'kg',
            value: '0',
            quantity: 10,
            // tslint:disable-next-line: max-line-length
            observation: 'Los medicamentos, hasta los 10 kgs de peso, están exentos del pago de los derechos de aduana, siempre que los mismos vengan en sus envases originales y separados del resto de los artículos. (Resolución No. 131 el Ministerio de Salud Pública)'
          }
        ],
      },
      {
        category: 'CAPÍTULO 00 - MISCELANEAS',
        element: [
          {
            name: '1. Misceláneas',
            um: 'kg',
            value: '20',
            quantity: 95,
            // tslint:disable-next-line: max-line-length
            observation: 'Se consideran como MISCELANEAS, los artículos siguientes: calzado, confecciones, alimentos, artículos de aseo personal y del hogar, bisutería, lencería, perfumería y similares'
          }
        ],
      },
      {
        category: 'CAPÍTULO 01 - COMESTIBLES, BEBIDAS, TABACOS Y CIGARROS',
        element: [
          {
            name: '1. Preparaciones alimenticias (alimentos deshidratados o concentrados).',
            um: 'Unidad(es)',
            value: 1.5,
            quantity: 15,
            // tslint:disable-next-line: max-line-length
            observation: 'La cantidad se refiere al total de los artículos, siempre que la quantity total de todas las unidades no exceda de 5 Kg'
          },
          {
            name: '2. Conservas de todo tipo',
            um: 'Unidad(es)',
            value: 1.5,
            quantity: 20,
            // tslint:disable-next-line: max-line-length
            observation: 'La cantidad se refiere al total de los artículos, siempre que la quantity total de todas las unidades no exceda de 20 Kg'
          },
          {
            name: '3. Productos lácteos de cualquier tipo.',
            um: 'Kg.',
            value: 3,
            quantity: 10,
            observation: ''
          },
          {
            name: '4. Mantequillas, quesos, otros similares.',
            um: 'Kg.',
            value: 2.5,
            quantity: 5,
            observation: '*Hasta el límite de 5 Kg. de cada artículo contenido en la descripción.'
          },
          {
            name: '5. Miel natural.',
            um: 'Litro',
            value: 0.5,
            quantity: 3,
            observation: ''
          },
          {
            name: '6. Confituras en general.',
            um: 'Kg.',
            value: 4,
            quantity: 5,
            observation: '*La cantidad se refiere al total de los artículos.'
          },
          {
            name: '7. Café o infusiones.',
            um: 'Kg.',
            value: 5,
            quantity: 5,
            observation: 'Siempre que el total de  los artículos no exceda de 5 Kg.'
          },
          {
            name: '8. Especias.',
            um: 'Kg',
            value: 10,
            quantity: 3,
            observation: 'Siempre que el total de  los artículos no exceda de 3 Kg'
          },
          {
            name: '9. Arroz, maíz, harinas y demás cereales. Pastas alimenticias y alimentos preparados a base de harinas.',
            um: 'Kg.',
            value: 3.5,
            quantity: 10,
            observation: '* Hasta el límite de 10 Kg. del total de los artículos contenidos en la descripción.'
          },
          {
            name: '10. Aceites vegetal o animal.',
            um: 'Litro',
            value: 2,
            quantity: 5,
            observation: ''
          },
          {
            name: '11. Grasas vegetal o animal.',
            um: 'Kg.',
            value: 1.5,
            quantity: 5,
            observation: ''
          },
          {
            name: '12. Los demás productos alimenticios.',
            um: 'Kg.',
            value: 4,
            quantity: 10,
            observation: '*La cantidad se refiere al total de los artículos, hasta el límite de 10 Kg.'
          },
          {
            name: '13. Aguas minerales y refrescos.',
            um: 'Unidad(es)',
            value: 0.2,
            quantity: 24,
            observation: 'La cantidad se refiere al total de los artículos, siempre que el envase de la unidad  no exceda de 1½ litros.'
          },
          {
            name: '14. Cervezas, maltas.',
            um: 'Unidad(es)',
            value: 0.4,
            quantity: 24,
            observation: '*La cantidad se refiere al total de los artículos, siempre que el envase de la unidad no exceda de 1 litro'
          },
          {
            name: '15. Vinagres y demás.',
            um: 'Unidad(es)',
            value: 1,
            quantity: 5,
            // tslint:disable-next-line: max-line-length
            observation: '*La cantidad se refiere al total de los artículos, siempre que la suma del contenido de los envases no supere los 5 litros.'
          },
          {
            name: '16. Vinos y licores.',
            um: 'Litro',
            value: 8,
            quantity: 5,
            // tslint:disable-next-line: max-line-length
            observation: '*La cantidad se refiere al total de los artículos, siempre que la suma del contenido de los envases no supere los 5 litros.'
          },
          {
            name: '17. Bebidas alcohólicas de cualquier tipo.',
            um: 'Litro',
            value: 15,
            quantity: 5,
            // tslint:disable-next-line: max-line-length
            observation: '*La cantidad se refiere al total de los artículos, siempre que la suma del contenido de los envases no supere los 5 litros.'
          },
          {
            name: '18. Cigarrillos.',
            um: 'Cajetilla de 20 cigarrillos',
            value: 1,
            quantity: 20,
            observation: ''
          },
          {
            name: '19. Tabaco elaborado.',
            um: 'Unidad(es)',
            value: 5,
            quantity: 20,
            observation: ''
          },
          {
            name: '20. Picaduras.',
            um: 'Kg.',
            value: 5,
            quantity: 1,
            observation: ''
          }
        ]
      },
      {
        category: 'CAPÍTULO 02 - COSMETICOS, PERFUMERIA Y ARTICULOS DE LIMPIEZA.',
        element: [
          {
            name: '1. Perfumes.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 10,
            // tslint:disable-next-line: max-line-length
            observation: 'La cantidad se refiere al total de los artículos, siempre que la suma total del contenido de las unidades no exceda de 5 litros.'
          },
          {
            name: '2. Agua de tocador (agua de colonia, de tocador, de Lavanda, Portugal, etc.).',
            um: 'Unidad(es)',
            value: 5,
            quantity: 10,
            // tslint:disable-next-line: max-line-length
            observation: 'La cantidad se refiere al total de los artículos, siempre que la suma total del contenido de las unidades no exceda de 5 litros.'
          },
          {
            name: '3. Preparaciones capilares, tratamiento capilar, espuma moldeadora, champús, acondicionadores, reparadores y similares.',
            um: 'Unidad(es)',
            value: 4,
            quantity: 5,
            // tslint:disable-next-line: max-line-length
            observation: '*La cantidad se refiere al total de los artículos, siempre que la suma total del contenido de las unidades no exceda de 5 litros.'
          },
          {
            // tslint:disable-next-line: max-line-length
            name: '4. Preparaciones para maquillaje y cuidado de la piel, lociones, cremas, delineadores, brillo y creyones labiales y similares.',
            um: 'Unidad(es)',
            value: 4,
            quantity: 20,
            // tslint:disable-next-line: max-line-length
            observation: '*La cantidad se refiere al total de los artículos. Cuando se trate de artículos en estado líquido de cualquier densidad, se admiten si el envase de la Unidad(es) es en frascos o potes de hasta 200 mililitros o que la suma total de los artículos líquidos no exceda de 20 unidades y su volumen no supere los 3 litros.'
          },
          {
            name: '5. Preparaciones para higiene bucal.',
            um: 'Unidad(es)',
            value: 1,
            quantity: 10,
            // tslint:disable-next-line: max-line-length
            observation: 'La cantidad se refiere al total de los artículos siempre que la suma total del contenido de los frascos no exceda de 1 litro.'
          },
          {
            name: '6. Máquinas de afeitar duraderas.',
            um: 'Unidad(es)',
            value: 5,
            quantity: 10,
            observation: ''
          },
          {
            name: '7. Máquinas de afeitar desechables.',
            um: 'Unidad(es)',
            value: 1,
            quantity: 20,
            observation: ''
          },
          {
            name: '8. Cuchillas para máquinas de afeitar.',
            um: 'Unidad(es)',
            value: 0.5,
            quantity: 30,
            observation: ''
          },
          {
            name: '9. Preparaciones para afeitar y desodorantes.',
            um: 'Unidad(es)',
            value: 1,
            quantity: 10,
            observation: '*La cantidad se refiere al total de los artículos siempre que la suma total de su contenido no exceda de 2 litros'
          },
          {
            name: '10. Aplicadores y brochas.',
            um: 'Unidad(es)',
            value: 2,
            quantity: 5,
            observation: '*La cantidad se refiere al total de los artículos.'
          },
          {
            name: '11. Rizadores, pinzas y perfiladores.',
            um: 'Unidad(es)',
            value: 2,
            quantity: 5,
            observation: '*La cantidad se refiere al total de los artículos.'
          },
          {
            name: '12. Artículos para peinado del cabello (peines, cepillos, peinetas, y similares).',
            um: 'Unidad(es)',
            value: 0.5,
            quantity: 10,
            observation: '*La cantidad se refiere al total de los artículos.'
          },
          {
            name: '13. Artículos para adorno del cabello (cintillos, hebillas, ganchos, felpas, pellizcos, peinetas,  y similares).',
            um: 'Unidad(es)',
            value: 2,
            quantity: 24,
            observation: '*La cantidad se refiere al total de los artículos.'
          },
          {
            name: '14. Esmaltes de uñas.',
            um: 'Unidad(es)',
            value: 1,
            quantity: 5,
            observation: '*Siempre que la quantity total no exceda de 100  mililitros.'
          },
          {
            name: '15. Conjunto surtido de viaje, para aseo personal, la costura, limpieza de calzados.',
            um: 'Unidad(es)',
            value: 1,
            quantity: 2,
            observation: 'El conjunto puede estar integrado  por um total de hasta 10 artículos.'
          },
          {
            name: '16. Postizos y accesorios (pelucas, pestañas, trencitas, extensiones, moños, y similares).',
            um: 'Unidad(es)',
            value: 8,
            quantity: 10,
            observation: 'La cantidad se refiere al total de los artículos.'
          },
          {
            name: '17. Uñas postizas.',
            um: 'Juego',
            value: 8,
            quantity: 1,
            observation: 'La quantity total no puede exceder de 24 uñas.'
          },
          {
            name: '18. Conjunto para manicura y pedicura (alicates, tijeras, limas de uñas, cortauñas, separadores, etc).',
            um: 'Unidad(es)',
            value: 10,
            quantity: 2,
            observation: 'El conjunto puede estar integrado  por um total de hasta de 10 artículos.'
          },
          {
            name: '19. Útiles para manicura  y pedicura (alicates, tijeras, limas de uñas).',
            um: 'Unidad(es)',
            value: 2,
            quantity: 10,
            observation: 'La cantidad se refiere al total de los artículos.'
          },
          {
            name: '20. Set para uñas postizas (bloque, geles, pinceles, polvo acrílico, lámpara ultravioleta).',
            um: 'Unidad(es)',
            value: 100,
            quantity: 1,
            observation: 'El set puede estar integrado por um total de hasta 10 artículos'
          },
          {
            name: '21. Útiles para uñas postizas (bloque, geles, pinceles, polvo acrílico).',
            um: 'Unidad(es)',
            value: 10,
            quantity: 10,
            observation: 'La cantidad se refiere al total de los artículos'
          },
          {
            name: '22. Lámpara ultravioleta.',
            um: 'Unidad(es)',
            value: 80,
            quantity: 1,
            observation: ''
          },
          {
            name: '23. Removedores, diluentes y otros productos para manicura y pedicura.',
            um: 'Unidad(es)',
            value: 3,
            quantity: 5,
            // tslint:disable-next-line: max-line-length
            observation: '*La cantidad se refiere al total de los artículos, siempre que la suma total del contenido de todos los envases no exceda de 1 litro.'
          },
          {
            name: '24. Jabones.',
            um: 'Unidad(es)',
            value: 0.2,
            quantity: 30,
            observation: 'Cuando se trate de estuches la quantity total de los jabones no puede exceder de 30 unidades.'
          },
          {
            name: '25. Detergentes.',
            um: 'Kg',
            value: 1,
            quantity: 10,
            observation: '*La suma total del contenido de todos los envases no puede exceder de 10 Kgs'
          },
          {
            // tslint:disable-next-line: max-line-length
            name: '26. Preparaciones odoríferas, que actúan o no por combustión (inciensos, bolsitas con partes de plantas aromáticas, colgantes o fijos perfumados para habitaciones o autos y similares).',
            um: 'Unidad(es)',
            value: 0.2,
            quantity: 50,
            // tslint:disable-next-line: max-line-length
            observation: '*La cantidad se refiere al total de los artículos. Cuando se trate de estuches, la suma del total de los artículos contenidos en los mismos, no puede exceder de 50 unidades. En estado líquido el contenido de todos los envases no puede exceder de 1 litro.'
          },
          {
            name: '27. Betunes y cremas para calzado.',
            um: 'Unidad(es)',
            value: 0.5,
            quantity: 10,
            observation: '*La cantidad se refiere al total de los artículos, siempre que el envase de la unidad no exceda los 150 gramos.'
          },
          {
            name: '28. Insecticidas.',
            um: 'Unidad(es)',
            value: 0.5,
            quantity: 5,
            // tslint:disable-next-line: max-line-length
            observation: '*La cantidad se refiere al total de los artículos, siempre que la suma total del contenido de todos los envases no exceda de 5 litros.'
          },
          {
            name: '29. Lubricantes.',
            um: 'Unidad(es)',
            value: 3,
            quantity: 5,
            observation: ''
          },
          {
            name: '30. Los demás artículos de limpieza.',
            um: 'Unidad(es)',
            value: 5,
            quantity: 10,
            observation: '*La cantidad se refiere al total de los artículos.'
          }
        ]
      },
      {
        category: 'CAPÍTULO 03 - PRODUCTOS FOTOGRAFICOS Y CINEMATOGRAFICOS.',
        element: [
          {
            name: '1. Cámaras fotográficas.',
            um: 'Unidad(es)',
            value: 60,
            quantity: 2,
            observation: ''
          },
          {
            name: '2. Cámaras de vídeo.',
            um: 'Unidad(es)',
            value: 100,
            quantity: 2,
            observation: ''
          },
          {
            name: '3. Memorias de cámaras.',
            um: 'Unidad(es)',
            value: 20,
            quantity: 5,
            observation: ''
          },
          {
            name: '4. Películas fotográficas en rollos sensibilizados para fotografiar.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 5,
            observation: ''
          },
          {
            name: '5. Datashow y similares.',
            um: 'Unidad(es)',
            value: 100,
            quantity: 2,
            observation: ''
          },
          {
            // tslint:disable-next-line: max-line-length
            name: '6. Partes, piezas y accesorios de productos fotográficos y cinematográficos  (trípodes, lentes, fotómetros, ampliadora, equipos de iluminación).',
            um: 'Unidad(es)',
            value: 100,
            quantity: 10,
            observation: '*La cantidad se refiere al total de los artículos.',
          },
          {
            name: '7. Portarretratos.',
            um: 'Unidad(es)',
            value: 5,
            quantity: 5,
            observation: ''
          },
          {
            name: '8. Álbumes de fotos.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 4,
            observation: ''
          },
          {
            name: '9. Papel fotográfico.',
            um: 'Unidad(es)',
            value: 3,
            quantity: 100,
            observation: ''
          }
        ]
      },
      {
        category: 'CAPÍTULO 04 - PINTURAS, BARNICES Y  ARTICULOS DE FERRETERIA.',
        element: [
          {
            name: '1. Pinturas y barnices.',
            um: 'Litro',
            value: 4,
            quantity: 10,
            observation: 'La cantidad se refiere al total de los artículos.'
          },
          {
            name: '2. Pigmentos, colorantes y tintas.',
            um: 'Unidad(es)',
            value: 3,
            quantity: 10,
            observation: 'La cantidad se refiere al total de los artículos'
          },
          {
            name: '3. Velas.',
            um: 'Unidad(es)',
            value: 0.5,
            quantity: 30,
            observation: ''
          },
          {
            name: '4. Parafina.',
            um: 'Kg',
            value: 1,
            quantity: 3,
            observation: ''
          },
          {
            name: '5. Pastas para modelar',
            um: 'Kg',
            value: 1,
            quantity: 3,
            observation: 'La cantidad se refiere al total de los artículos'
          },
          {
            name: '6. Aparatos de medición y precisión (cintas métricas, niveles, multímetros, voltímetros, similares).',
            um: 'Unidad(es)',
            value: 10,
            quantity: 1,
            observation: 'Uno de c/u'
          },
          {
            name: '7. Interruptores – Tomacorrientes.',
            um: 'Unidad(es)',
            value: 3,
            quantity: 10,
            observation: 'La cantidad se refiere al total de los artículos'
          },
          {
            name: '8. Cables de electricidad.',
            um: 'Metro',
            value: 1,
            quantity: 15,
            observation: ''
          },
          {
            name: '9. Bombillos y tubos fluorescentes.',
            um: 'Unidad(es)',
            value: 1,
            quantity: 10,
            observation: 'La cantidad se refiere al total de los artículos'
          },
          {
            name: '10. Faroles (no eléctricos).',
            um: 'Unidad(es)',
            value: 3,
            quantity: 5,
            observation: ''
          },
          {
            name: '11. Candados, cerraduras, picaportes y demás mecanismos de cierre.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 5,
            observation: 'La cantidad se refiere al total de los artículos'
          },
          {
            name: '12. Electrodos.',
            um: 'Kg.',
            value: 1,
            quantity: 5,
            observation: ''
          },
          {
            // tslint:disable-next-line: max-line-length
            name: '13. Artículos de oficina, bolígrafos, plunones, lápices, repuestos, presilladoras, ponchadoras, saca presillas, y similares etc.',
            um: 'Unidad(es)',
            value: 0,
            quantity: 30,
            observation: 'La cantidad se refiere al total de los artículos'
          },
          {
            name: '14. Papel para impresos.',
            um: 'Millar',
            value: 5,
            quantity: 10,
            observation: ''
          },
          {
            name: '15. Fosforeras y encendedores de   todo tipo.',
            um: 'Unidad(es)',
            value: 1,
            quantity: 10,
            observation: 'La cantidad se refiere al total de los artículos'
          },
          {
            name: '16. Piedras de fosforeras.',
            um: 'Kg.',
            value: 50,
            quantity: 0.5,
            observation: ''
          },
          {
            name: '17. Sombrillas o paraguas.',
            um: 'Unidad(es)',
            value: 8,
            quantity: 5,
            observation: 'La cantidad se refiere al total de los artículos'
          },
          {
            name: '18. Pegamentos, colas  y los demás artículos para pegar o sellar.',
            um: 'Unidad(es)',
            value: 2,
            quantity: 10,
            // tslint:disable-next-line: max-line-length
            observation: 'La cantidad se refiere al total de los artículos. * El peso total de las 10 unidades no debe ser superior a los 5 Kg'
          },
          {
            name: '19. Lámparas eléctricas de señalización y alunbrado.',
            um: 'Unidad(es)',
            value: 20,
            quantity: 5,
            observation: ''
          },
          {
            name: '20. Linternas.',
            um: 'Unidad(es)',
            value: 3,
            quantity: 3,
            observation: ''
          },
          {
            name: '21. Pilas o baterías.',
            um: 'Unidad(es)',
            value: 0.5,
            quantity: 20,
            observation: 'Si se trata de juegos, la quantity total de las unidades que los integran no puede exceder de 20 unidades.'
          },
          {
            name: '22. Baterías para móviles, teléfonos inalámbricos y walkie talkie.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 5,
            observation: '* No se incluyen las baterías para vehículos automotores. (Ver Capítulo No. 14)'
          },
          {
            name: '23. Pinceles, brochas y rodillos.',
            um: 'Unidad(es)',
            value: 3,
            quantity: 5,
            observation: 'La cantidad se refiere al total de los artículos'
          },
          {
            name: '24. Grapas, tachuelas, clavos, cáncamos, puntillas, tuercas, tornillos, arandelas y similares.',
            um: 'Kg',
            value: 5,
            quantity: 5,
            observation: 'La cantidad se refiere al total de los artículos'
          },
          {
            name: '25. Tanques de agua.',
            um: 'Unidad(es)',
            value: 80,
            quantity: 1,
            observation: ''
          },
          {
            name: '26. Mangueras.',
            um: 'Metro',
            value: 5,
            quantity: 15,
            observation: ''
          },
          {
            name: '27. Escaleras domésticas.',
            um: 'Unidad(es)',
            value: 30,
            quantity: 1,
            observation: ''
          },
          {
            name: '28. Timbres.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 2,
            observation: ''
          },
          {
            name: '29. Alarmas contra intrusos.',
            um: 'Unidad(es)',
            value: 60,
            quantity: 1,
            observation: ''
          },
          {
            name: '30. Sistema de vigilancia.',
            um: 'Unidad(es)',
            value: 400,
            quantity: 1,
            observation: ''
          },
          {
            name: '31. Puertas.',
            um: 'Unidad(es)',
            value: 100,
            quantity: 2,
            observation: ''
          },
          {
            name: '32. Ventanas.',
            um: 'Unidad(es)',
            value: 50,
            quantity: 4,
            observation: ''
          },
          {
            name: '33. Taza de baño.',
            um: 'Unidad(es)',
            value: 50,
            quantity: 1,
            observation: ''
          },
          {
            name: '34. Lavamanos con o sin pedestal.',
            um: 'Unidad(es)',
            value: 40,
            quantity: 1,
            observation: ''
          },
          {
            name: '35. Bañaderas.',
            um: 'Unidad(es)',
            value: 200,
            quantity: 1,
            observation: ''
          },
          {
            name: '36. Válvulas (pilas de agua).',
            um: 'Unidad(es)',
            value: 3,
            quantity: 3,
            observation: ''
          },
          {
            name: '37. Jacuzzi.',
            um: 'Unidad(es)',
            value: 1000,
            quantity: 1,
            observation: ''
          },
          {
            name: '38. Llaves mezcladoras para fregaderos o duchas.',
            um: 'Unidad(es)',
            value: 20,
            quantity: 2,
            observation: ''
          },
          {
            name: '39. Revestimientos para el suelo, techos y paredes (linóleos  y similares).',
            um: 'Metro2',
            value: 50,
            quantity: 20,
            observation: 'La cantidad se refiere al total de los artículos'
          },
          {
            name: '40. Alfombras y similares.',
            um: 'metro',
            value: 10,
            quantity: 20,
            observation: '*La suna total de la longitud de todos los artículos no puede exceder de 20 Metros.'
          },
          {
            name: '41. Tiendas de campaña y toldos.',
            um: 'Unidad(es)',
            value: 100,
            quantity: 2,
            observation: 'La cantidad se refiere al total de los artículos'
          },
          {
            name: '42. Los demás artículos de ferretería.',
            um: 'Unidad(es)',
            value: 30,
            quantity: 5,
            observation: 'La cantidad se refiere al total de los artículos',
          }
        ]
      },
      {
        category: 'CAPÍTULO 05 – CONFECCIONES.',
        element: [
          {
            name: '1. Abrigos, sobretodos, chaquetas, sacos, chalecos.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 5,
            observation: 'La cantidad se refiere al total de los artículos'
          },
          {
            name: '2.1. Ropa Interior femenina (adulto, joven, niña).  Bluners.',
            um: 'Docena',
            value: 6,
            quantity: 2,
            observation: ''
          },
          {
            name: '2.2. Ropa Interior femenina (adulto, joven, niña). Ajustadores.',
            um: 'Docena',
            value: 6,
            quantity: 2,
            observation: ''
          },
          {
            name: '2.3. Ropa Interior femenina (adulto, joven, niña). Medias.',
            um: 'Docena',
            value: 6,
            quantity: 2,
            observation: ''
          },
          {
            name: '2.4. Ropa Interior femenina (adulto, joven, niña).  Juegos de bluners y ajustadores',
            um: 'Unidad(es)',
            value: 12,
            quantity: 10,
            observation: ''
          },
          {
            name: '3.1. Ropa Interior masculina (adulto, joven, niño). Calzoncillos.',
            um: 'Docena',
            value: 6,
            quantity: 2,
            observation: ''
          },
          {
            name: '3.2. Ropa Interior masculina (adulto, joven, niño)Camisetas.',
            um: 'Docena',
            value: 6,
            quantity: 2,
            observation: ''
          },
          {
            name: '3.3. Ropa Interior masculina (adulto, joven, niño). Medias.',
            um: 'Docena',
            value: 6,
            quantity: 2,
            observation: ''
          },
          {
            name: '4.1. Vestuario Femenino (adulto, joven, niña). Blusas.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 20,
            observation: ''
          },
          {
            name: '4.2. Vestuario Femenino (adulto, joven, niña). Pullovers con o sin mangas.',
            um: 'Unidad(es)',
            value: 7,
            quantity: 20,
            observation: 'La cantidad se refiere al total de los artículos'
          },
          {
            name: '4.3. Vestuario Femenino (adulto, joven, niña). Sayas.',
            um: 'Unidad(es)',
            value: 12,
            quantity: 10,
            observation: ''
          },
          {
            name: '4.4. Vestuario Femenino (adulto, joven, niña). Vestidos.',
            um: 'Unidad(es)',
            value: 8,
            quantity: 10,
            observation: ''
          },
          {
            name: '4.5. Vestuario Femenino (adulto, joven, niña). Pantalones.',
            um: 'Unidad(es)',
            value: 15,
            quantity: 10,
            observation: ''
          },
          {
            name: '4.6. Vestuario Femenino (adulto, joven, niña). Bermudas.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 10,
            observation: ''
          },
          {
            name: '4.7. Vestuario Femenino (adulto, joven, niña). Shorts.',
            um: 'Unidad(es)',
            value: 8,
            quantity: 10,
            observation: ''
          },
          {
            name: '4.8. Vestuario Femenino (adulto, joven, niña). Licras.',
            um: 'Unidad(es)',
            value: 6,
            quantity: 10,
            observation: ''
          },
          {
            name: '4.9. Vestuario Femenino (adulto, joven, niña). Conjuntos.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 10,
            observation: ''
          },
          {
            name: '4.10. Vestuario Femenino (adulto, joven, niña). Trajes de baño, trusas.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 5,
            observation: ''
          },
          {
            name: '4.11. Vestuario Femenino (adulto, joven, niña). Las demás piezas de vestuario (ligas, tirantes)',
            um: 'Unidad(es)',
            value: 6,
            quantity: 10,
            observation: 'La cantidad se refiere al total de los artículos'
          },
          {
            name: '5.1. Vestuario Masculino (adulto, joven, niño). Camisas.',
            um: 'Unidad(es)',
            value: 7,
            quantity: 20,
            observation: ''
          },
          {
            name: '5.2. Vestuario Masculino (adulto, joven, niño). Pullovers  con o sin mangas.',
            um: 'Unidad(es)',
            value: 7,
            quantity: 20,
            observation: 'La cantidad se refiere al total de los artículos'
          },
          {
            name: '5.3. Vestuario Masculino (adulto, joven, niño). Pantalones.',
            um: 'Unidad(es)',
            value: 15,
            quantity: 10,
            observation: ''
          },
          {
            name: '5.4. Vestuario Masculino (adulto, joven, niño). Bermudas.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 10,
            observation: ''
          },
          {
            name: '5.5. Vestuario Masculino (adulto, joven, niño).Shorts.',
            um: 'Unidad(es)',
            value: 8,
            quantity: 10,
            observation: ''
          },
          {
            name: '5.6. Vestuario Masculino (adulto, joven, niño). Licras.',
            um: 'Unidad(es)',
            value: 6,
            quantity: 10,
            observation: ''
          },
          {
            name: '5.7. Vestuario Masculino (adulto, joven, niño).Trajes de baño, trusas.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 5,
            observation: 'La cantidad se refiere al total de los artículos'
          },
          {
            name: '5.8. Vestuario Masculino (adulto, joven, niño).Conjuntos.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 10,
            observation: ''
          },
          {
            name: '5.9. Vestuario Masculino (adulto, joven, niño).Las demás piezas de vestuario',
            um: 'Unidad(es)',
            value: 20,
            quantity: 10,
            observation: 'La cantidad se refiere al total de los artículos'
          },
          {
            name: '6. Trajes de Bodas y Quince (masculino y femenino).',
            um: 'Unidad(es)',
            value: 100,
            quantity: 2,
            observation: 'La cantidad se refiere al total de los artículos'
          },
          {
            name: '7. Disfraces (masculino y femenino).',
            um: 'Unidad(es)',
            value: 100,
            quantity: 2,
            observation: 'La cantidad se refiere al total de los artículos'
          },
          {
            name: '8.1. Complementos del vestuario. Pañuelos.',
            um: 'Unidad(es)',
            value: 1,
            quantity: 20,
            observation: ''
          },
          {
            name: '8.2. Complementos del vestuario. Gorras.',
            um: 'Unidad(es)',
            value: 1,
            quantity: 10,
            observation: ''
          },
          {
            name: '8.3. Complementos del vestuario. Corbatas.',
            um: 'Unidad(es)',
            value: 1,
            quantity: 5,
            observation: ''
          },
          {
            name: '8.4. Complementos del vestuario. Guantes.',
            um: 'Par',
            value: 1,
            quantity: 2,
            observation: ''
          },
          {
            name: '8.5. Complementos del vestuario. Fajas.',
            um: 'Unidad(es)',
            value: 5,
            quantity: 2,
            observation: ''
          },
          {
            name: '8.6. Complementos del vestuario. Sombreros.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 5,
            observation: ''
          },
          {
            name: '8.7. Complementos del vestuario. Chubasqueros y capas.',
            um: 'Unidad(es)',
            value: 3,
            quantity: 5,
            observation: 'La cantidad se refiere al total de los artículos'
          },
          {
            name: '8.8. Complementos del vestuario. Chalinas y estolas.',
            um: 'Unidad(es)',
            value: 1,
            quantity: 5,
            observation: 'La cantidad se refiere al total de los artículos'
          },
          {
            name: '8.9. Complementos del vestuario.',
            um: 'Unidad(es)',
            value: 6,
            quantity: 5,
            observation: 'La cantidad se refiere al total de los artículos'
          },
          {
            name: '9.1. Artículos para confecciones. Botones, zippers y similares.',
            um: 'Docena',
            value: 1,
            quantity: 2,
            observation: 'La cantidad se refiere al total de los artículos'
          },
          {
            name: '9.2. Artículos para confecciones. Cintas, puntas de encajes, elásticos y tiras bordadas',
            um: 'Metro',
            value: 1,
            quantity: 20,
            observation: '*La suna total de la longitud de todos los artículos no puede exceder de 20 Metros.'
          }
        ]
      },
      {
        category: 'CAPÍTULO 06 - CALZADO Y TALABARTERIA.',
        element: [
          {
            name: '1.1. Calzado Femenino (adulto, joven, niña). Calzado para vestir.',
            um: 'Par',
            value: 30,
            quantity: 5,
            observation: '*La cantidad se refiere al total de los artículos (pares) con independencia del tipo'
          },
          {
            name: '1.2. Calzado Femenino (adulto, joven, niña). Calzado de sport, sandalias y chancletas',
            um: 'Par',
            value: 5,
            quantity: 5,
            observation: '*La cantidad se refiere al total de los artículos (pares) con independencia del tipo'
          },
          {
            name: '1.3. Calzado Femenino (adulto, joven, niña). Tenis, calzado deportivo y demás calzado',
            um: 'Par',
            value: 15,
            quantity: 5,
            observation: '*La cantidad se refiere al total de los artículos (pares) con independencia del tipo'
          },
          {
            name: '2.1. Calzado Masculino (adulto, joven, niño). Calzado para vestir.',
            um: 'Par',
            value: 30,
            quantity: 5,
            observation: '*La cantidad se refiere al total de los artículos (pares) con independencia del tipo'
          },
          {
            name: '2.2. Calzado Masculino (adulto, joven, niño). Calzado de sport, sandalias y chancletas',
            um: 'Par',
            value: 5,
            quantity: 5,
            observation: '*La cantidad se refiere al total de los artículos (pares) con independencia del tipo'
          },
          {
            name: '2.3. Calzado Masculino (adulto, joven, niño). Tenis, calzado deportivo y demás  calzado',
            um: 'Par',
            value: 15,
            quantity: 5,
            observation: '*La cantidad se refiere al total de los artículos (pares) con independencia del tipo'
          },
          {
            name: '3.1. Artículos de talabartería. Bolsos de mano, mochilas.',
            um: 'Unidad(es)',
            value: 15,
            quantity: 4,
            observation: '*La cantidad se refiere al total de los artículos.'
          },
          {
            name: '3.2. Artículos de talabartería.  Carteras.',
            um: 'Unidad(es)',
            value: 20,
            quantity: 4,
            observation: ''
          },
          {
            name: '3.3. Artículos de talabartería. Maletines, maletas, portafolios.',
            um: 'Unidad(es)',
            value: 20,
            quantity: 5,
            observation: '*La cantidad se refiere al total de los artículos.'
          },
          {
            name: '3.4. Artículos de talabartería. Monederos, billeteras.',
            um: 'Unidad(es)',
            value: 5,
            quantity: 6,
            observation: '*La cantidad se refiere al total de los artículos.'
          },
          {
            name: '3.5. Artículos de talabartería. Cintos.',
            um: 'Unidad(es)',
            value: 8,
            quantity: 5,
            observation: ''
          }
        ]
      },
      {
        category: 'CAPÍTULO 07 – CANASTILLA.',
        element: [
          {
            name: '1. Ropa para el bebé.',
            um: 'Juego',
            value: 1,
            quantity: 30,
            observation: ''
          },
          {
            name: '2. Baberos –  Culeros – Pañales.',
            um: 'Docena',
            value: 3,
            quantity: 50,
            observation: '*La cantidad se refiere al total de los artículos.'
          },
          {
            name: '3. Ropa de cama (sábanas, fundas, mosquiteros, protectores, almohaditas).',
            um: 'Unidad(es)',
            value: 4,
            quantity: 25,
            observation: '*La cantidad se refiere al total de los artículos.'
          },
          {
            name: '4. Teteras, biberones, cubiertos, platos.',
            um: 'Unidad(es)',
            value: 1,
            quantity: 30,
            observation: '*La cantidad se refiere al total de los artículos.'
          },
          {
            name: '5. Adornos, juguetes',
            um: 'Unidad(es)',
            value: 0.5,
            quantity: 20,
            observation: '*La cantidad se refiere al total de los artículos.'
          },
          {
            name: '6. Muebles para uso del bebé (cuna, coches, corrales, mecedoras, andadores y similares).',
            um: 'Unidad(es)',
            value: 20,
            quantity: 2,
            observation: 'Dos c/u'
          },
          {
            name: '7. Zapatos de canastilla.',
            um: 'Par',
            value: 1,
            quantity: 30,
            observation: ''
          },
          {
            name: '8. Aceites y artículos de tocador y aseo para bebé.',
            um: 'Unidad(es)',
            value: 1,
            quantity: 20,
            // tslint:disable-next-line: max-line-length
            observation: '*La cantidad se refiere al total de los artículos siempre que los frascos para líquidos o cremas no excedan de 1 litro o que la suna de todos ellos no supere los 5 litros.'
          },
          {
            name: '9. Utensilios necesarios para el bebé (esterilizadores, calentadores,  vaporizadores, nebulizadores  y similares).',
            um: 'Unidad(es)',
            value: 5,
            quantity: 2,
            observation: 'Dos c/u'
          },
          {
            name: '10. Los demás artículos de canastilla para bebé.',
            um: 'Unidad(es)',
            value: 0.5,
            quantity: 30,
            observation: '*La cantidad se refiere al total de los artículos.'
          }
        ]
      },
      {
        category: 'CAPÍTULO 08 – LENCERIA.',
        element: [
          {
            name: '1. Hilados',
            um: 'Unidad(es)',
            value: 0.5,
            quantity: 20,
            observation: '*El total de los artículos no puede exceder de los 5 Kg.',
            category: 'CAPÍTULO 08 – LENCERIA.'
          },
          {
            name: '2. Tejidos.',
            um: 'Metro',
            value: 5,
            quantity: 10,
            observation: '',
            category: 'CAPÍTULO 08 – LENCERIA.'
          },
          {
            name: '3. Toallas.',
            um: 'Unidad(es)',
            value: 3,
            quantity: 10,
            observation: '',
            category: 'CAPÍTULO 08 – LENCERIA.'
          },
          {
            name: '4. Almohadas.',
            um: 'Unidad(es)',
            value: 5,
            quantity: 4,
            observation: '',
            category: 'CAPÍTULO 08 – LENCERIA.'
          },
          {
            name: '5. Conjunto de sábanas y fundas.',
            um: 'Juego',
            value: 10,
            quantity: 5,
            observation: '',
            category: 'CAPÍTULO 08 – LENCERIA.'
          },
          {
            name: '6. Frazadas y sobrecamas.',
            um: 'Unidad(es)',
            value: 20,
            quantity: 4,
            // tslint:disable-next-line: max-line-length
            observation: '*La cantidad se refiere al total de los artículos siempre que los frascos para líquidos o cremas no excedan de 1 litro o que la suna de todos ellos no supere los 5 litros.',
            category: 'CAPÍTULO 08 – LENCERIA.'
          },
          {
            name: '7. Ropa de cocina y de mesa (manteles, delantales, doyles, servilletas, agarraderas, portavasos, similares).',
            um: 'Unidad(es)',
            value: 2,
            quantity: 10,
            observation: '*La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 08 – LENCERIA.'
          },
          {
            name: '8. Cortinas.',
            um: 'Unidad(es)',
            value: 3,
            quantity: 4,
            observation: '',
            category: 'CAPÍTULO 08 – LENCERIA.'
          }
        ]
      },
      {
        category: 'CAPÍTULO 09 - UTILES PARA EL HOGAR.',
        element: [
          {
            name: '1.1. Juego de cuchillos y cubiertos de mesa, de cualquier  material: De 4 comensales',
            um: 'Unidad(es)',
            value: 8,
            quantity: 2,
            observation: '*Los juegos se entenderán hasta de 12 comensales.',
            category: 'CAPÍTULO 09 - UTILES PARA EL HOGAR.'
          },
          {
            name: '1.2. Juego de cuchillos y cubiertos de mesa, de cualquier  material: De 6 comensales',
            um: 'Unidad(es)',
            value: 15,
            quantity: 2,
            observation: '*Los juegos se entenderán hasta de 12 comensales.',
            category: 'CAPÍTULO 09 - UTILES PARA EL HOGAR.'
          },
          {
            name: '1.3. Juego de cuchillos y cubiertos de mesa, de cualquier  material: De 12 comensales',
            um: 'Unidad(es)',
            value: 25,
            quantity: 2,
            observation: '*Los juegos se entenderán hasta de 12 comensales.',
            category: 'CAPÍTULO 09 - UTILES PARA EL HOGAR.'
          },
          {
            name: '2. Cuchillos y cubiertos de mesa, de cualquier  material.',
            um: 'Unidad(es)',
            value: 1,
            quantity: 6,
            observation: 'Seis c/u',
            category: 'CAPÍTULO 09 - UTILES PARA EL HOGAR.'
          },
          {
            name: '3. Juegos de útiles de cocina (cucharones, espunaderas, etc.).',
            um: 'Unidad(es)',
            value: 10,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 09 - UTILES PARA EL HOGAR.'
          },
          {
            name: '4. Balones para gas (excepto con gas freón)',
            um: 'Unidad(es)',
            value: 60,
            quantity: 1,
            observation: '*Balones con capacidad de hasta 100 lbs.',
            category: 'CAPÍTULO 09 - UTILES PARA EL HOGAR.'
          },
          {
            name: '5. Reguladores de gas.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 09 - UTILES PARA EL HOGAR.'
          },
          {
            name: '6. Medidores.',
            um: 'Unidad(es)',
            value: 4,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 09 - UTILES PARA EL HOGAR.'
          },
          {
            name: '7. Filtros de agua.',
            um: 'Unidad(es)',
            value: 40,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 09 - UTILES PARA EL HOGAR.'
          },
          {
            name: '8. Repuestos para filtros.',
            um: 'Unidad(es)',
            value: 5,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 09 - UTILES PARA EL HOGAR.'
          },
          {
            name: '9. Escoba, cepillos,  recogedores y haraganes.',
            um: 'Unidad(es)',
            value: 4,
            quantity: 5,
            observation: '*La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 09 - UTILES PARA EL HOGAR.'
          },
          {
            name: '10. Platos, Tazas, Vasos, y similares.',
            um: 'Unidad(es)',
            value: 1,
            quantity: 18,
            observation: 'Siempre que no exceda de 6 la quantity de artículos de cada tipo.',
            category: 'CAPÍTULO 09 - UTILES PARA EL HOGAR.'
          },
          {
            name: '11. Vajillas. Servicio de mesa y cocina',
            um: 'Juego',
            value: 60,
            quantity: 1,
            // tslint:disable-next-line: max-line-length
            observation: '* 1 juego siempre que por su decoración  (color, dibujo, material y quantity de comensales o cubiertos) se evidencie que es um juego. La quantity total de los artículos del juego que se acepta: vajilla de hasta 12 comensales y um  máximo de 100 piezas.',
            category: 'CAPÍTULO 09 - UTILES PARA EL HOGAR.'
          },
          {
            name: '12. Termos.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 09 - UTILES PARA EL HOGAR.'
          },
          {
            name: '13. Juego de neveras plásticas portátiles.',
            um: 'Juego',
            value: 80,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 09 - UTILES PARA EL HOGAR.'
          },
          {
            name: '14. Neveras plásticas portátiles.',
            um: 'Unidad(es)',
            value: 50,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 09 - UTILES PARA EL HOGAR.'
          },
          {
            name: '15. Marquetería, cofres, cajas, estuches de joyería y similares.',
            um: 'Unidad(es)',
            value: 8,
            quantity: 10,
            observation: '*La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 09 - UTILES PARA EL HOGAR.'
          },
          {
            name: '16. Objetos de adorno.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 10,
            observation: '*La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 09 - UTILES PARA EL HOGAR.'
          },
          {
            name: '17. Envases y jabas.',
            um: 'Docena',
            value: 1,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 09 - UTILES PARA EL HOGAR.'
          },
          {
            name: '18. Envolturas de cualquier material para alimentos.',
            um: 'Unidad(es)',
            value: 6,
            quantity: 1,
            observation: 'Unidad(es) hasta 10 metros',
            category: 'CAPÍTULO 09 - UTILES PARA EL HOGAR.'
          },
          {
            name: '19. Perchas.',
            um: 'Docena',
            value: 5,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 09 - UTILES PARA EL HOGAR.'
          },
          {
            name: '20. Tendederas.',
            um: 'Unidad(es)',
            value: 15,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 09 - UTILES PARA EL HOGAR.'
          },
          {
            name: '21. Cordeles para tendederas.',
            um: 'Metros',
            value: 4,
            quantity: 20,
            observation: '',
            category: 'CAPÍTULO 09 - UTILES PARA EL HOGAR.'
          },
          {
            name: '22. Cestos (para ropa y productos desechables).',
            um: 'Unidad(es)',
            value: 5,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 09 - UTILES PARA EL HOGAR.'
          },
          {
            name: '23. Cubos.',
            um: 'Unidad(es)',
            value: 3,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 09 - UTILES PARA EL HOGAR.'
          },
          {
            name: '24. Palanganas.',
            um: 'Unidad(es)',
            value: 2,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 09 - UTILES PARA EL HOGAR.'
          },
          {
            name: '25. Escurridores de platos.',
            um: 'Unidad(es)',
            value: 3,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 09 - UTILES PARA EL HOGAR.'
          },
          {
            name: '26. Zapateras.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 09 - UTILES PARA EL HOGAR.'
          },
          {
            name: '27. Vianderos.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 09 - UTILES PARA EL HOGAR.'
          },
          {
            name: '28. Espejos',
            um: 'Unidad(es)',
            value: 15,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 09 - UTILES PARA EL HOGAR.'
          },
          {
            name: '29. Los demás útiles para el hogar.',
            um: 'Unidad(es)',
            value: 30,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 09 - UTILES PARA EL HOGAR.'
          }
        ]
      },
      {
        category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.',
        element: [
          {
            name: '1. Planchas eléctricas domésticas.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '2. Refrigeradores domésticos.',
            um: 'Unidad(es)',
            value: 300,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '3. Congeladores o freezer domésticos.',
            um: 'Unidad(es)',
            value: 350,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '4. Dispensadores de agua.',
            um: 'Unidad(es)',
            value: 50,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '5. Minibar.',
            um: 'Unidad(es)',
            value: 150,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '6. Cocina y hornos de gas.',
            um: 'Unidad(es)',
            value: 80,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '7.1. Cocina y hornillas eléctricas de vitrocerámica por inducción.  De inducción (hasta 2 focos).',
            um: 'Unidad(es)',
            value: 100,
            quantity: 2,
            // tslint:disable-next-line: max-line-length
            observation: 'La cantidad se refiere al total de los artículos.. Siempre que su consuno eléctrico no exceda 1 500 Watt, por foco.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '7.2. Cocina y hornillas eléctricas de vitrocerámica por inducción.  De inducción (hasta 4 focos).',
            um: 'Unidad(es)',
            value: 200,
            quantity: 2,
            // tslint:disable-next-line: max-line-length
            observation: 'La cantidad se refiere al total de los artículos.. Siempre que su consuno eléctrico no exceda 1 500 Watt, por foco.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '7.3. Cocina y hornillas eléctricas de vitrocerámica por inducción.  De inducción (más de 4 focos).',
            um: 'Unidad(es)',
            value: 500,
            quantity: 2,
            // tslint:disable-next-line: max-line-length
            observation: 'La cantidad se refiere al total de los artículos.. Siempre que su consuno eléctrico no exceda 1 500 Watt, por foco.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '8. Horno microonda.',
            um: 'Unidad(es)',
            value: 50,
            quantity: 2,
            observation: 'Siempre que su consuno eléctrico no exceda 2 000 Watt.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '9. Implementos no eléctricos para cocción (calderos, ollas, cazuelas, sartenes).',
            um: 'Unidad(es)',
            value: 15,
            quantity: 6,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '10. Parrillada.',
            um: 'Unidad(es)',
            value: 40,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '11. Grill.',
            um: 'Unidad(es)',
            value: 50,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '12. Sartenes eléctricos.',
            um: 'Unidad(es)',
            value: 20,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '13. Ollas arroceras eléctricas.',
            um: 'Unidad(es)',
            value: 20,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '14. Ollas vaporeras eléctricas.',
            um: 'Unidad(es)',
            value: 20,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '15. Olla multifunción eléctrica.',
            um: 'Unidad(es)',
            value: 25,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '16. Freidora  eléctrica.',
            um: 'Unidad(es)',
            value: 40,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '17. Sandwichera eléctrica.',
            um: 'Unidad(es)',
            value: 30,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '18. Tostadora eléctrica de pan.',
            um: 'Unidad(es)',
            value: 20,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '19. Máquinas lavadoras/ secadoras.',
            um: 'Unidad(es)',
            value: 100,
            quantity: 2,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '20. Máquina de coser, tejer y bordar.',
            um: 'Unidad(es)',
            value: 40,
            quantity: 2,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '21. Secador o rizador / plancha de pelo.',
            um: 'Unidad(es)',
            value: 20,
            quantity: 3,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '22. Secador (para cabellos) de pie',
            um: 'Unidad(es)',
            value: 60,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '23. Máquina de pelar eléctrica.',
            um: 'Unidad(es)',
            value: 20,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '24. Aspiradora.',
            um: 'Unidad(es)',
            value: 40,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '25. Duchas eléctricas.',
            um: 'Unidad(es)',
            value: 30,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '26. Calentadores eléctricos de agua.',
            um: 'Unidad(es)',
            value: 70,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '27. Cafetera eléctrica.',
            um: 'Unidad(es)',
            value: 15,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '28. Batidora.',
            um: 'Unidad(es)',
            value: 20,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '29. Licuadora.',
            um: 'Unidad(es)',
            value: 15,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '30. Procesador de alimentos.',
            um: 'Unidad(es)',
            value: 20,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '31. Picador de especies.',
            um: 'Unidad(es)',
            value: 15,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '32. Mezcladoras.',
            um: 'Unidad(es)',
            value: 15,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '33. Molinos.',
            um: 'Unidad(es)',
            value: 20,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '34. Televisores convencionales.',
            um: 'Unidad(es)',
            value: 70,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '35. Televisores pantalla plana  hasta 32  pulgadas (LCD, plasma, LED, otras tecnologías similares).',
            um: 'Unidad(es)',
            value: 250,
            quantity: 2,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            // tslint:disable-next-line: max-line-length
            name: '36. Televisores pantalla plana mayores de 32 pulgadas y hasta 42 pulgadas (LCD, plasma, LED, otras tecnologías similares).',
            um: 'Unidad(es)',
            value: 400,
            quantity: 2,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '37. Televisores pantalla plana mayores de 42 pulgadas (LCD, plasma, LED, otras tecnologías similares).',
            um: 'Unidad(es)',
            value: 500,
            quantity: 2,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '38. Radio de bolsillo, mesa o radioreloj.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 2,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '39. Equipos de música de cualquier tipo.',
            um: 'Unidad(es)',
            value: 100,
            quantity: 2,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '40. Teatro en casa o similares.',
            um: 'Unidad(es)',
            value: 120,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '41. Reproductor de vídeo.',
            um: 'Unidad(es)',
            value: 30,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '42. Consolas de vídeo juegos.',
            um: 'Unidad(es)',
            value: 250,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '43. Ventiladores.',
            um: 'Unidad(es)',
            value: 20,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '44. Acondicionadores de aire hasta ¾ toneladas',
            um: 'Unidad(es)',
            value: 150,
            quantity: 2,
            observation: 'Siempre que su capacidad no exceda de 1 tonelada o 12 000 BTU. La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '45. Acondicionadores de aire mayor de ¾ hasta 1 tonelada.',
            um: 'Unidad(es)',
            value: 200,
            quantity: 2,
            observation: 'Siempre que su capacidad no exceda de 1 tonelada o 12 000 BTU. La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '46. Equipos de calefacción (estufas y similares).',
            um: 'Unidad(es)',
            value: 200,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '47. Partes y piezas de equipos electrodomésticos, cocina y del hogar.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 2,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '48. Microcomputadora completa',
            um: 'Unidad(es)',
            value: 200,
            quantity: 2,
            observation: 'Solo se permiten dos computadoras con independencia del tipo.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '49. Microcomputadora  portátil (laptop).',
            um: 'Unidad(es)',
            value: 250,
            quantity: 2,
            observation: 'Solo se permiten dos computadoras con independencia del tipo.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '50. Table PC.',
            um: 'Unidad(es)',
            value: 150,
            quantity: 2,
            observation: 'Solo se permiten dos computadoras con independencia del tipo.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '51. Mini laptop.',
            um: 'Unidad(es)',
            value: 100,
            quantity: 2,
            observation: 'Solo se permiten dos computadoras con independencia del tipo.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '52. Notebook.',
            um: 'Unidad(es)',
            value: 60,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '53. Dispositivos de almacenamiento, memorias, ipod, mp3, mp4, y similares.',
            um: 'Unidad(es)',
            value: 15,
            quantity: 2,
            observation: 'Dos c/u.  * La quantity total no puede exceder de 8 artículos.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '54. Torre de computadora.',
            um: 'Unidad(es)',
            value: 100,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '55. Procesadores.',
            um: 'Unidad(es)',
            value: 50,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '56. Memorias RAM.',
            um: 'Unidad(es)',
            value: 20,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '57. Disco duro incluyendo el HDD player',
            um: 'Unidad(es)',
            value: 60,
            quantity: 2,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '58. Lector o quemador de CD o DVD.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '59. Monitor de computadora.',
            um: 'Unidad(es)',
            value: 50,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '60. Backup (UPS).',
            um: 'Unidad(es)',
            value: 30,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '61. Mouse.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '62. Teclado.',
            um: 'Unidad(es)',
            value: 20,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '63. Scanner.',
            um: 'Unidad(es)',
            value: 60,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '64. Tarjeta de vídeo.',
            um: 'Unidad(es)',
            value: 40,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '65. Tarjeta de red.',
            um: 'Unidad(es)',
            value: 15,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '66. Tarjeta de sistema (motherboard).',
            um: 'Unidad(es)',
            value: 60,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '67. Las demás partes, piezas  y  accesorios de computadoras',
            um: 'Unidad(es)',
            value: 50,
            quantity: 5,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '68. Impresoras de cualquier tipo.',
            um: 'Unidad(es)',
            value: 100,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '69. Discos compactos vírgenes (CD y DVD).',
            um: 'Unidad(es)',
            value: 1,
            quantity: 50,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '70. Discos compactos grabados (CD y DVD).',
            um: 'Unidad(es)',
            value: 2,
            quantity: 50,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '71. Teléfonos.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 2,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            // tslint:disable-next-line: max-line-length
            name: '72. Teléfonos inalámbricos que operan en las siguientes bandas: de los 43.710 a 49.980 mhz; de los 1.910 a 1.930 ghz;de los 2.400 a 2.4835 ghz; de los 5.725 a 5.875 ghz',
            um: 'Unidad(es)',
            value: 40,
            quantity: 2,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '73. Teléfonos celulares gama baja',
            um: 'Unidad(es)',
            value: 30,
            quantity: 2,
            observation: '*Solo se permiten dos teléfonos celulares con independencia del tipo.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '74. Teléfonos celulares gama media.',
            um: 'Unidad(es)',
            value: 60,
            quantity: 2,
            observation: '*Solo se permiten dos teléfonos celulares con independencia del tipo.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '75. Teléfonos celulares gama alta.',
            um: 'Unidad(es)',
            value: 120,
            quantity: 2,
            observation: '*Solo se permiten dos teléfonos celulares con independencia del tipo.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '76. Partes, piezas y accesorios celulares.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 2,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '77. Intercomunicadores domésticos',
            um: 'Unidad(es)',
            value: 10,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '78. Pizarras telefónicas de todo tipo.',
            um: 'Unidad(es)',
            value: 300,
            quantity: 1,
            observation: 'Requiere autorización previa del MICOM.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '79. Fotocopiadoras.',
            um: 'Unidad(es)',
            value: 250,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '80. Fax alámbrico.',
            um: 'Unidad(es)',
            value: 100,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '81. Equipos de fax inalámbricos.',
            um: 'Unidad(es)',
            value: 150,
            quantity: 1,
            observation: 'Requiere autorización previa del MICOM.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '82. Micrófonos inalámbricos y sus accesorios',
            um: 'Unidad(es)',
            value: 50,
            quantity: 2,
            observation: 'Requiere autorización previa del MICOM. La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '83. Calculadoras y contadoras.',
            um: 'Unidad(es)',
            value: 15,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '84. Partes, piezas y accesorios de impresoras y fotocopiadoras.',
            um: 'Unidad(es)',
            value: 50,
            quantity: 5,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '85. Los demás aparatos y máquinas de oficina.',
            um: 'Unidad(es)',
            value: 50,
            quantity: 2,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '86. Dispositivos para redes de datos (routers y switches).',
            um: 'Unidad(es)',
            value: 100,
            quantity: 1,
            observation: 'Requiere autorización previa del MICOM.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '87. Transceptores de radio equipos de estaciones fijas, móviles y personales (walkie – talkie).',
            um: 'Unidad(es)',
            value: 80,
            quantity: 1,
            observation: 'Requiere autorización previa del MICOM.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '88.1. Plantas eléctricas. Hasta 900 vatios',
            um: 'Unidad(es)',
            value: 200,
            quantity: 1,
            observation: '* Solo se permite una planta eléctrica con independencia del tipo',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '88.2. Plantas eléctricas. Mayores de 900 va hasta 1500 va',
            um: 'Unidad(es)',
            value: 500,
            quantity: 1,
            observation: '* Solo se permite una planta eléctrica con independencia del tipo',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '88.3. Plantas eléctricas.Mayores de 1500 va hasta 15 Kva.',
            um: 'Unidad(es)',
            value: 1000,
            quantity: 1,
            observation: '* Solo se permite una planta eléctrica con independencia del tipo',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '89. Casco o armazón de refrigeradores, freezer y aires acondicionados con todos los componentes excepto el compresor.',
            um: 'Unidad(es)',
            value: 250,
            quantity: 1,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '90. Casco o armazón de  equipos electrodomésticos.',
            um: 'Unidad(es)',
            value: 50,
            quantity: 1,
            observation: 'La cantidad se refiere al total de los artículos y solamente el casco sin componentes adicionales.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '91. Otros tipos de motores de equipos electrodomésticos.',
            um: 'Unidad(es)',
            value: 200,
            quantity: 2,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '92. Compresores de refrigerador.',
            um: 'Unidad(es)',
            value: 50,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '93. Bombas de agua y compresores.',
            um: 'Unidad(es)',
            value: 50,
            quantity: 2,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          },
          {
            name: '94. Partes, piezas y accesorios de compresores y bombas.',
            um: 'Unidad(es)',
            value: 15,
            quantity: 5,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 10 – EFECTOS ELECTRODOMESTICOS, INFORMATICA Y COMUNICACIONES.'
          }
        ]
      },
      {
        category: 'CAPÍTULO 11 – JOYERIA.',
        element: [
          {
            name: '1. Reloj de pulsera de hombre/ mujer/niños',
            um: 'Unidad(es)',
            value: 15,
            quantity: 5,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 11 – JOYERIA.'
          },
          {
            name: '2. Partes y piezas de relojes (manillas, pines, baterías, coronas, y similares).',
            um: 'Unidad(es)',
            value: 2,
            quantity: 5,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 11 – JOYERIA.'
          },
          {
            name: '3. Artículos de orfebrería.',
            um: 'Unidad(es)',
            value: 70,
            quantity: 10,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 11 – JOYERIA.'
          },
          {
            name: '4. Las demás manufacturas (cuentas para collares, piedras artificiales y similares).',
            um: 'Kg',
            value: 25,
            quantity: 5,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 11 – JOYERIA.'
          },
          {
            name: '5. Bisutería (cadenas, anillos, aretes, prendedores, otros de fantasía).',
            um: 'Unidad(es)',
            value: 15,
            quantity: 10,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 11 – JOYERIA.'
          }
        ]
      },
      {
        category: 'CAPÍTULO 12 - INSTRUMENTOS MUSICALES.',
        element: [
          {
            name: '1. Instrunentos de cuerda.',
            um: 'Unidad(es)',
            value: 60,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 12 - INSTRunENTOS MUSICALES.'
          },
          {
            name: '2. Instrunentos de teclado.',
            um: 'Unidad(es)',
            value: 200,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 12 - INSTRunENTOS MUSICALES.'
          },
          {
            name: '3. Instrunentos de viento.',
            um: 'Unidad(es)',
            value: 200,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 12 - INSTRunENTOS MUSICALES.'
          },
          {
            name: '4. Instrunentos de percusión.',
            um: 'Unidad(es)',
            value: 200,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 12 - INSTRunENTOS MUSICALES.'
          },
          {
            name: '5. Caja de música.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 12 - INSTRunENTOS MUSICALES.'
          },
          {
            name: '6. Reproductores DJ (Disk jake).',
            um: 'Unidad(es)',
            value: 400,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 12 - INSTRunENTOS MUSICALES.'
          },
          {
            name: '7. Mezcladores sonido.',
            um: 'Unidad(es)',
            value: 500,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 12 - INSTRunENTOS MUSICALES.'
          },
          {
            name: '8. Partes, piezas y accesorios de instrunentos musicales y equipos de efectos de sonido.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 10,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 12 - INSTRunENTOS MUSICALES.'
          },
          {
            name: '9. Los demás instrunentos musicales.',
            um: 'Unidad(es)',
            value: 300,
            quantity: 2,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 12 - INSTRunENTOS MUSICALES.'
          }
        ]
      },
      {
        category: 'CAPÍTULO 13 – MOBILIARIOS.',
        element: [
          {
            name: '1. Juego de sala.',
            um: 'Juego',
            value: 150,
            quantity: 1,
            observation: 'Juego: hasta siete (7) piezas: sofá (1), mesa (1-2), butaca (12), sillones (1-2).',
            category: 'CAPÍTULO 13 – MOBILIARIOS.'
          },
          {
            name: '2. Juego de cuarto.',
            um: 'Juego',
            value: 150,
            quantity: 2,
            // tslint:disable-next-line: max-line-length
            observation: '*Juego: hasta siete (7) piezas: cama (1), cabecera (1), pielera (1), mesa de noche (1-2), butaca (1), cómoda (1).',
            category: 'CAPÍTULO 13 – MOBILIARIOS.'
          },
          {
            name: '3. Juego de comedor.',
            um: 'Juego',
            value: 120,
            quantity: 1,
            observation: 'Hasta once (11) piezas: mesa (1), silla (2-8), aparador (1), vitrina (1).',
            category: 'CAPÍTULO 13 – MOBILIARIOS.'
          },
          {
            name: '4. Piezas de muebles (silla, mesa, butaca, sillones, y similares).',
            um: 'Unidad(es)',
            value: 30,
            quantity: 4,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 13 – MOBILIARIOS.'
          },
          {
            name: '5. Sillas giratorias.',
            um: 'Unidad(es)',
            value: 30,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 13 – MOBILIARIOS.'
          },
          {
            name: '6. Buroes.',
            um: 'Unidad(es)',
            value: 50,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 13 – MOBILIARIOS.'
          },
          {
            name: '7. Mesa para computadoras.',
            um: 'Unidad(es)',
            value: 40,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 13 – MOBILIARIOS.'
          },
          {
            name: '8. Libreros.',
            um: 'Unidad(es)',
            value: 60,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 13 – MOBILIARIOS.'
          },
          {
            name: '9. Estantes.',
            um: 'Unidad(es)',
            value: 70,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 13 – MOBILIARIOS.'
          },
          {
            name: '10. Cómodas y gaveteros.',
            um: 'Unidad(es)',
            value: 100,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 13 – MOBILIARIOS.'
          },
          {
            name: '11. Repisas.',
            um: 'Unidad(es)',
            value: 50,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 13 – MOBILIARIOS.'
          },
          {
            name: '12. Colchones.',
            um: 'Unidad(es)',
            value: 50,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 13 – MOBILIARIOS.'
          },
          {
            name: '13. Los demás  muebles.',
            um: 'Unidad(es)',
            value: 150,
            quantity: 3,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 13 – MOBILIARIOS.'
          }
        ]
      },
      {
        category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.',
        element: [
          {
            name: '1. Baterías o Acunuladores.',
            um: 'Unidad(es)',
            value: 35,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '2. Carburador.',
            um: 'Unidad(es)',
            value: 40,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '3. Delco (distribuidor).',
            um: 'Unidad(es)',
            value: 30,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '4. Guardafangos.',
            um: 'Unidad(es)',
            value: 40,
            quantity: 4,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '5. Parabrisas.',
            um: 'Unidad(es)',
            value: 150,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '6. Neunáticos para autos ligeros o pesados (con o sin cámara).',
            um: 'Unidad(es)',
            value: 60,
            quantity: 4,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '7. Neunáticos para motos (con o sin cámara).',
            um: 'Unidad(es)',
            value: 35,
            quantity: 3,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '8. Cámaras para neunáticos de autos ligeros o pesados.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 4,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '9. Cámaras para neunáticos de motos.',
            um: 'Unidad(es)',
            value: 5,
            quantity: 3,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '10. Llantas para autos ligeros o pesados.',
            um: 'Unidad(es)',
            value: 60,
            quantity: 4,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '11. Llantas para motos.',
            um: 'Unidad(es)',
            value: 35,
            quantity: 3,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '12. Amortiguadores.',
            um: 'Unidad(es)',
            value: 15,
            quantity: 4,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '13. Bobina de encendido.',
            um: 'Unidad(es)',
            value: 5,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '14. Cajas de Velocidad.',
            um: 'Unidad(es)',
            value: 120,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '15. Monochasis.',
            um: 'Unidad(es)',
            value: 35,
            quantity: 3,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '16. Pizarra (con o sin relojes).',
            um: 'Unidad(es)',
            value: 100,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '17. Defensas (delantera y trasera).',
            um: 'Unidad(es)',
            value: 50,
            quantity: 2,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '18. Capó.',
            um: 'Unidad(es)',
            value: 80,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '19. Tapa maletero.',
            um: 'Unidad(es)',
            value: 80,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '20. Puertas.',
            um: 'Unidad(es)',
            value: 100,
            quantity: 4,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '21. Faroles.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 4,
            observation: '*La cantidad se refiere al total de los artículos, es decir dos (2) de cada tipo.',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '22. Bomba de aceite.',
            um: 'Unidad(es)',
            value: 20,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '23. Bomba de combustible.',
            um: 'Unidad(es)',
            value: 20,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '24. Bomba de agua.',
            um: 'Unidad(es)',
            value: 20,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '25. Bomba de cloche.',
            um: 'Unidad(es)',
            value: 20,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '26. Set de Juntas.',
            um: 'Unidad(es)',
            value: 24,
            quantity: 1,
            observation: '*La quantity no puede exceder de 12 juntas.',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '27. Juntas.',
            um: 'Unidad(es)',
            value: 2,
            quantity: 12,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '28. Filtro aire.',
            um: 'Unidad(es)',
            value: 2,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '29. Filtro aceite.',
            um: 'Unidad(es)',
            value: 2,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '30. Aros.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 24,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '31. Pasadores.',
            um: 'Unidad(es)',
            value: 2,
            quantity: 8,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '32. Árbol de levas.',
            um: 'Unidad(es)',
            value: 20,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '33. Retenes.',
            um: 'Unidad(es)',
            value: 1,
            quantity: 12,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '34. Funda árbol de levas.',
            um: 'Unidad(es)',
            value: 15,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '35. Pistones.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 8,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '36. Camisa Pistón.',
            um: 'Unidad(es)',
            value: 15,
            quantity: 8,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '37. Cadenas.',
            um: 'Unidad(es)',
            value: 5,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '38. Correas y Poleas.',
            um: 'Unidad(es)',
            value: 5,
            quantity: 2,
            observation: 'Dos c/u',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '39. Bombillos.',
            um: 'Unidad(es)',
            value: 1,
            quantity: 10,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '40. Cigüeñal.',
            um: 'Unidad(es)',
            value: 80,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '41. Motor de arranque.',
            um: 'Unidad(es)',
            value: 40,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '42. Radiador.',
            um: 'Unidad(es)',
            value: 30,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '43. Alternador.',
            um: 'Unidad(es)',
            value: 40,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '44. Cajas de fusibles.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '45. Aceites y lubricantes para vehículos y motos.',
            um: 'Litro',
            value: 2,
            quantity: 10,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '46. Bujías.',
            um: 'Unidad(es)',
            value: 2,
            quantity: 8,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '47. Sistema limpia parabrisas.',
            um: 'Unidad(es)',
            value: 30,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '48. Escobillas.',
            um: 'Unidad(es)',
            value: 4,
            quantity: 3,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '49. Espejo retrovisor (interiores y exteriores).',
            um: 'Unidad(es)',
            value: 10,
            quantity: 3,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '50. Indicadores de presión de neunáticos.',
            um: 'Unidad(es)',
            value: 15,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '51. Tiradores de puertas y seguros de cierres.',
            um: 'Unidad(es)',
            value: 8,
            quantity: 5,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '52. Encendido electrónico.',
            um: 'Unidad(es)',
            value: 40,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '53. Sistema de clima para autos.',
            um: 'Unidad(es)',
            value: 70,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '54. Kit de frenos (mangueras, pastillas, discos).',
            um: 'Juego',
            value: 20,
            quantity: 1,
            observation: 'Las piezas sueltas se valuean a 5.00 pesos cada una',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '55. Barra estabilizadora.',
            um: 'Unidad(es)',
            value: 60,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '56. Puente delantero.',
            um: 'Unidad(es)',
            value: 100,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '57. Radio reproductora CD o DVD para auto.',
            um: 'Unidad(es)',
            value: 40,
            quantity: 2,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '58. Los demás accesorios, partes piezas de vehículos y motos.',
            um: 'Unidad(es)',
            value: 20,
            quantity: 2,
            observation: 'Dos c/u. La quantity total no puede exceder de 10 artículos.',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '59. Butacas delanteras.',
            um: 'Unidad(es)',
            value: 60,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '60. Asiento trasero.',
            um: 'Unidad(es)',
            value: 80,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '61. Asiento para moto.',
            um: 'Unidad(es)',
            value: 50,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '62. Remolque o arrastre ligero sin autopropulsión no mayor de 750 kg.',
            um: 'Unidad(es)',
            value: 300,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          },
          {
            name: '63. Motores marinos que no excedan los 10 caballos de fuerza (HP).',
            um: 'Unidad(es)',
            value: 1000,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 14 - PARTES Y ACCESORIOS PARA VEHICULOS AUTOMOTORES.'
          }
        ]
      },
      {
        category: 'CAPÍTULO 15 - JUGUETES Y ARTICULOS DEPORTIVOS Y DE RECREO.',
        element: [
          {
            name: '1. Bicicletas.',
            um: 'Unidad(es)',
            value: 50,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 15 - JUGUETES Y ARTICULOS DEPORTIVOS Y DE RECREO.'
          },
          {
            name: '2. Bicicletas, patinetas y carriolas eléctricas.',
            um: 'Unidad(es)',
            value: 100,
            quantity: 2,
            // tslint:disable-next-line: max-line-length
            observation: 'La cantidad se refiere al total de los artículos. Siempre que su velocidad máxima por construcción no sea superior a 50 km/h y la potencia del motor no exceda de 1000 watt.',
            category: 'CAPÍTULO 15 - JUGUETES Y ARTICULOS DEPORTIVOS Y DE RECREO.'
          },
          {
            name: '3. Ciclomotores con motor eléctrico.',
            um: 'Unidad(es)',
            value: 200,
            quantity: 2,
            // tslint:disable-next-line: max-line-length
            observation: 'La cantidad se refiere al total de los artículos. Siempre que su velocidad máxima por construcción no sea superior a 50 km/h y la potencia del motor no exceda de 1000 watt.',
            category: 'CAPÍTULO 15 - JUGUETES Y ARTICULOS DEPORTIVOS Y DE RECREO.'
          },
          {
            name: '4. Batería para propulsión de bicicletas, patinetas, carriolas eléctricas y ciclomotores.',
            um: 'Unidad(es)',
            value: 50,
            quantity: 3,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 15 - JUGUETES Y ARTICULOS DEPORTIVOS Y DE RECREO.'
          },
          {
            name: '5. Neunáticos de bicicletas.',
            um: 'Unidad(es)',
            value: 15,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 15 - JUGUETES Y ARTICULOS DEPORTIVOS Y DE RECREO.'
          },
          {
            name: '6. Llantas de bicicletas.',
            um: 'Unidad(es)',
            value: 15,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 15 - JUGUETES Y ARTICULOS DEPORTIVOS Y DE RECREO.'
          },
          {
            name: '7. Cámaras para neunáticos de bicicleta.',
            um: 'Unidad(es)',
            value: 5,
            quantity: 2,
            observation: '',
            category: 'CAPÍTULO 15 - JUGUETES Y ARTICULOS DEPORTIVOS Y DE RECREO.'
          },
          {
            // tslint:disable-next-line: max-line-length
            name: '8. Las demás partes, piezas y accesorios de bicicletas, bicicletas eléctricas, patinetas y carriolas eléctricas y ciclomotores eléctricos.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 10,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 15 - JUGUETES Y ARTICULOS DEPORTIVOS Y DE RECREO.'
          },
          {
            name: '9. Balsas y salvavidas.',
            um: 'Unidad(es)',
            value: 20,
            quantity: 2,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 15 - JUGUETES Y ARTICULOS DEPORTIVOS Y DE RECREO.'
          },
          {
            name: '10. Piscinas.',
            um: 'Unidad(es)',
            value: 200,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 15 - JUGUETES Y ARTICULOS DEPORTIVOS Y DE RECREO.'
          },
          {
            name: '11. Juegos inflables para uso doméstico.',
            um: 'Unidad(es)',
            value: 950,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 15 - JUGUETES Y ARTICULOS DEPORTIVOS Y DE RECREO.'
          },
          {
            name: '12. Juguetes inflables para niños.',
            um: 'Unidad(es)',
            value: 5,
            quantity: 5,
            observation: '',
            category: 'CAPÍTULO 15 - JUGUETES Y ARTICULOS DEPORTIVOS Y DE RECREO.'
          },
          {
            name: '13. Juguetes de propulsión eléctrica o no para ser montados por niños.',
            um: 'Unidad(es)',
            value: 30,
            quantity: 2,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 15 - JUGUETES Y ARTICULOS DEPORTIVOS Y DE RECREO.'
          },
          {
            name: '14. Juguetes que funcionen con baterías, por control remoto, mecánicos, y similares.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 5,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 15 - JUGUETES Y ARTICULOS DEPORTIVOS Y DE RECREO.'
          },
          {
            name: '15. Muñecas.',
            um: 'Unidad(es)',
            value: 2,
            quantity: 5,
            observation: '',
            category: 'CAPÍTULO 15 - JUGUETES Y ARTICULOS DEPORTIVOS Y DE RECREO.'
          },
          {
            name: '16. Juegos de mesa (parchi, ajedrez, damas, naipes, dominó).',
            um: 'Unidad(es)',
            value: 5,
            quantity: 3,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 15 - JUGUETES Y ARTICULOS DEPORTIVOS Y DE RECREO.'
          },
          {
            name: '17. Los demás  juguetes.',
            um: 'Unidad(es)',
            value: 20,
            quantity: 10,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 15 - JUGUETES Y ARTICULOS DEPORTIVOS Y DE RECREO.'
          },
          {
            name: '18. Artículos para la práctica de deportes con pelota (bate, net, raqueta, careta, cascos, petos, guantes, guantillas).',
            um: 'Unidad(es)',
            value: 10,
            quantity: 10,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 15 - JUGUETES Y ARTICULOS DEPORTIVOS Y DE RECREO.'
          },
          {
            name: '19. Artículos para la práctica de deportes náuticos (snorkel, aletas, caretas, tablas de surf).',
            um: 'Unidad(es)',
            value: 20,
            quantity: 5,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 15 - JUGUETES Y ARTICULOS DEPORTIVOS Y DE RECREO.'
          },
          {
            name: '20. Los demás artículos para la práctica de deportes.',
            um: 'Unidad(es)',
            value: 10,
            quantity: 5,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 15 - JUGUETES Y ARTICULOS DEPORTIVOS Y DE RECREO.'
          },
          {
            name: '21. Juegos de cubiertos, vasos y platos, desechables.',
            um: 'Docena',
            value: 5,
            quantity: 5,
            observation: 'Cinco c/u',
            category: 'CAPÍTULO 15 - JUGUETES Y ARTICULOS DEPORTIVOS Y DE RECREO.'
          },
          {
            name: '22. Caretas, gorros, máscaras, antifaces, tiaras, silbatos',
            um: 'Unidad(es)',
            value: 5,
            quantity: 50,
            observation: '50 c/u. El value se refiere al total de cada artículo.',
            category: 'CAPÍTULO 15 - JUGUETES Y ARTICULOS DEPORTIVOS Y DE RECREO.'
          },
          {
            name: '23. Hércules.',
            um: 'Unidad(es)',
            value: 600,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 15 - JUGUETES Y ARTICULOS DEPORTIVOS Y DE RECREO.'
          },
          {
            name: '24. Caminadoras.',
            um: 'Unidad(es)',
            value: 400,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 15 - JUGUETES Y ARTICULOS DEPORTIVOS Y DE RECREO.'
          },
          {
            name: '25. Escaladora.',
            um: 'Unidad(es)',
            value: 400,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 15 - JUGUETES Y ARTICULOS DEPORTIVOS Y DE RECREO.'
          },
          {
            name: '26. Bicicleta estática.',
            um: 'Unidad(es)',
            value: 400,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 15 - JUGUETES Y ARTICULOS DEPORTIVOS Y DE RECREO.'
          }
        ]
      },
      {
        category: 'CAPÍTULO 16 - MATERIALES DE CONSTRUCCION.',
        element: [
          {
            name: '1. Baldosas, azulejos.',
            um: 'Metro2',
            value: 6,
            quantity: 50,
            observation: '',
            category: 'CAPÍTULO 16 - MATERIALES DE CONSTRUCCION.'
          },
          {
            name: '2. Matajuntas.',
            um: 'Metro2',
            value: 5,
            quantity: 20,
            observation: '',
            category: 'CAPÍTULO 16 - MATERIALES DE CONSTRUCCION.'
          },
          {
            name: '3. Cenefas.',
            um: 'Metro2',
            value: 5,
            quantity: 20,
            observation: '',
            category: 'CAPÍTULO 16 - MATERIALES DE CONSTRUCCION.'
          },
          {
            name: '4. Manufacturas p/uso de construcción.',
            um: 'Unidad(es)',
            value: 6,
            quantity: 50,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 16 - MATERIALES DE CONSTRUCCION.'
          },
          {
            name: '5. Cementos gris / blanco.',
            um: 'Bolsa',
            value: 6,
            quantity: 5,
            observation: '*La bolsa hasta 49 kilogramos. *La cantidad se refiere al total de los artículos',
            category: 'CAPÍTULO 16 - MATERIALES DE CONSTRUCCION.'
          },
          {
            name: '6. Otros cementos.',
            um: 'Bolsa',
            value: 5,
            quantity: 5,
            observation: '*La bolsa hasta 49 kilogramos. *La cantidad se refiere al total de los artículos',
            category: 'CAPÍTULO 16 - MATERIALES DE CONSTRUCCION.'
          },
          {
            name: '7. Otros herrajes de cualquier tipo.',
            um: 'Unidad(es)',
            value: 3,
            quantity: 10,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 16 - MATERIALES DE CONSTRUCCION.'
          },
          {
            name: '8. Los demás materiales de construcción',
            um: 'Unidad(es)',
            value: 5,
            quantity: 10,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 16 - MATERIALES DE CONSTRUCCION.'
          }
        ]
      },
      {
        category: 'CAPÍTULO 17  -   HERRAMIENTAS.',
        element: [
          {
            name: '1. Juego herramientas de mano.',
            um: 'Juego',
            value: 150,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 17  -   HERRAMIENTAS.'
          },
          {
            // tslint:disable-next-line: max-line-length
            name: '2. Herramientas de mano (alicates, cepillos metálicos, cutters, destornilladores, limas y escofinas, llaves ajustables, llaves allen, llaves fijas, llaves de tubo, de vaso, martillos y masas, tenazas, puntas y porta puntas, tijeras).',
            um: 'Unidad(es)',
            value: 5,
            quantity: 10,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 17  -   HERRAMIENTAS.'
          },
          {
            name: '3. Útiles intercambiables para máquinas herramientas eléctricas.',
            um: 'Unidad(es)',
            value: 5,
            quantity: 5,
            observation: '',
            category: 'CAPÍTULO 17  -   HERRAMIENTAS.'
          },
          {
            // tslint:disable-next-line: max-line-length
            name: '4. Herramientas con motor eléctrico incorporado (afiladoras, atornilladores eléctricos y neumáticos, cepillos, clavadoras, decapadoras, grapadoras, lijadoras, martillos, remachadoras, roscadoras, sierras circulares, taladros).',
            um: 'Unidad(es)',
            value: 50,
            quantity: 2,
            observation: 'La cantidad se refiere al total de los artículos.',
            category: 'CAPÍTULO 17  -   HERRAMIENTAS.'
          },
          {
            name: '5. Cortadora de césped.',
            um: 'Unidad(es)',
            value: 100,
            quantity: 1,
            observation: '',
            category: 'CAPÍTULO 17  -   HERRAMIENTAS.'
          }
        ]
      }
    ];
  }
}
