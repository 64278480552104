import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { AuthService } from '../../services/auth/auth.service';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
declare const $: any;

@Component({
  selector: 'app-set-new-password',
  templateUrl: './set-new-password.component.html',
  styleUrls: ['./set-new-password.component.scss'],
})
export class SetNewPasswordComponent implements OnInit {

  myForm = this.fb.group({
    password: ['', [Validators.required, Validators.minLength(5)]],
    forgotToken: ['', Validators.required]
  });

  message: string;
  data: any;

  constructor(public authService: AuthService, private router: Router, private fb: FormBuilder) { }

  ngOnInit() {
    this.message = '';
  }

  isMobile() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  };

  resetPassword(): boolean {
    this.message = '';
    this.authService.resetPassword(this.myForm.value)
      .subscribe(data => {
        this.data = data;
        if (this.data.msg != null) {
          this.message = 'CONTRASEÑA ACTUALIZADA!';
          setTimeout(function () {
            this.message = '';
            this.router.navigate(['/login']);
          }.bind(this), 2000);
        }
      });
    return false;
  }

}
