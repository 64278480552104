import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServerService } from '../../services/server.service';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';

declare var $: any;

@Component({
  selector: 'app-create-section',
  templateUrl: './create-section.component.html',
  styleUrls: ['./create-section.component.scss'],
})
export class CreateSectionComponent implements OnInit {

  myForm = this.fb.group({
    title: ['', [Validators.required, Validators.minLength(2)]],
    drafted_by: ['', [Validators.required, Validators.minLength(2)]],
    icon: ['', [Validators.required, Validators.minLength(2)]]
  });

  dataSection: any;

  constructor(public router: Router, public server: ServerService, private fb: FormBuilder) { }

  ngOnInit() { }

  sectionCreate() {
    this.dataSection = null;
    this.server.sectionCreate(this.myForm.value).subscribe((data) => {
      this.dataSection = data;
      if (this.dataSection.msg != null) {
        this.myForm.reset();
        this.router.navigate(['dashboard/']);
        this.showNotification('top', 'center', 2, 2, 'SECCIÓN CREADA SATISFACTORIAMENTE!!!')
        return true;
      }
    });
    return false;
  }

  showNotification(from, align, col, time, message) {
    const type = ['', 'info', 'success', 'warning', 'danger'];

    // const color = Math.floor((Math.random() * 4) + 1);
    // 4 red 3 yellow 2 green 1 blue
    const color = col;

    $.notify({
      icon: 'notifications',
      message: message

    }, {
      type: type[color],
      timer: time * 1000,
      placement: {
        from: from,
        align: align
      },
      template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span class="text-center" data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }
}
