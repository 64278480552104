import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ServerService } from './../../services/server.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
declare var $: any;

@Component({
  selector: 'app-edit-account',
  templateUrl: './edit-account.component.html',
  styleUrls: ['./edit-account.component.scss'],
})
export class EditAccountComponent implements OnInit {

  favoriteSeason: string = "euro";
  user_role:any;

  myForm = this.fb.group({
    name: ['', [Validators.required]],
    type: ['', [Validators.required]],
    description: ['', [Validators.required]],
  });

  accountList:any;
  data:any;
  isLoading: boolean;
  constructor(public server: ServerService, public router: Router, private fb: FormBuilder, public activatedRoute: ActivatedRoute) { 
    this.user_role = localStorage.getItem('user_role')
  }

  ngOnInit() {
    console.log('Dentro del Init de la app', this.activatedRoute.snapshot.params.id)
    this.getAccount();
  }

  getAccount(id?){
    this.isLoading= true;
    this.server.getOneAccount(this.activatedRoute.snapshot.params.id)
      .subscribe(response => {
        this.data = response;
        var info = this.data.data;
        this.myForm.patchValue({
          name: info.name,
          type: info.type,
          description: info.description,
        });
        this.isLoading = false;
      });
  }

  editAccount() {
    this.isLoading = true;
    this.server.editAccount(this.activatedRoute.snapshot.params.id ,this.myForm.value).subscribe((response) => {
      this.showNotification("top", "center", 2, 2, "La cuenta ha sido editada satisfactoriamente.");
      this.myForm.reset();
      this.isLoading= false;
      this.router.navigate(['accounts']);
    })   

  }

  showNotification(from, align, col, time, message) {
    const type = ['', 'info', 'success', 'warning', 'danger'];

    // const color = Math.floor((Math.random() * 4) + 1);
    // 4 red 3 yellow 2 green 1 blue
    const color = col;

    $.notify({
      icon: 'notifications',
      message: message

    }, {
      type: type[color],
      timer: time * 1000,
      placement: {
        from: from,
        align: align
      },
      template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span class="text-center" data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }

}



