import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ServerService } from './../../services/server.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ConfirmComponent } from './../confirm/confirm.component'
import { shoppingStatus } from '../../components/models/shoppingStatus.model';

declare var $: any;

@Component({
  selector: 'app-view-shopping',
  templateUrl: './view-shopping.component.html',
  styleUrls: ['./view-shopping.component.scss'],
  providers: [ServerService]
})
export class ViewShoppingComponent implements OnInit {

  favoriteSeason: string = "support";
  user_role: any;
  status = shoppingStatus;

  myForm = this.fb.group({
    username: ['', [Validators.required, Validators.minLength(3)]],
    password: ['', [Validators.required, Validators.minLength(3)]],
    password_confirm: ['', [Validators.required, Validators.minLength(3)]],
    email: ['', [Validators.required, Validators.email]],
    telephone: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(6)]],
    role: ['', [Validators.required]],
  });

  shoppingList: any;
  data: any;
  buyer: any;
  isLoading: boolean;

  constructor(public _ServerService: ServerService, public router: Router, private fb: FormBuilder, public dialog: MatDialog,
    public activatedRoute: ActivatedRoute) {
    this.user_role = localStorage.getItem('user_role');

  }

  ngOnInit() {
    this.getUserShoppingList();
    this.getBuyer();
  }

  keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  getBuyer(id?) {
    this._ServerService.getOneBuyer(this.activatedRoute.snapshot.params.id).subscribe((response) => {
      this.buyer = response;
      console.log (response)
    });
  }


  
  getBack(){
    this.router.navigate([`/user-buyer`]);
  }

  getUserShoppingList(id?) {
    this.isLoading = true;
    this._ServerService.getUserShoppings(this.activatedRoute.snapshot.params.id).subscribe((response) => {
      console.log(response);
      this.data = response;
      this.shoppingList = this.data.data;
      this.shoppingList.forEach(element => {
        switch (element.statusOther) {
          case 'pending':
            element.selectedStatus = 'Pendiente'
            break;
          case 'received':
            element.selectedStatus = 'Recibido'
            break;          
          case 'sent':
            element.selectedStatus = 'Enviado'
            break;
          
        }
      })
      this.shoppingList.sort((a, b) => Date.parse(b.updated_at) -  Date.parse(a.updated_at));
      this.isLoading = false;
    });
  }

  addShopping() {
    localStorage.setItem('buyer',this.buyer.data.nombre);
    let id= this.activatedRoute.snapshot.params.id
    localStorage.setItem('backId', id);
    this.router.navigate(['/add-edit-shopping']);
  }

  finishShopping(id) {
    this.isLoading = true;
    this._ServerService.finishShopping(id,{status: 'finished'}).subscribe((response) => {
      console.log(response);
      this.showNotification("top", "center", 2, 2, "La compra ha sido finalizada con exito.");
      this.getUserShoppingList();
  })
}

  editShopping(id: any) {
    let backId = this.activatedRoute.snapshot.params.id
    localStorage.setItem('backId', backId);
    console.log(id);

    this.router.navigate([`/add-edit-shopping/${id}`]);
  }

  deleteShopping(id: any) {
    // var dialogRef = this.dialog.open(ConfirmComponent);
    if (confirm("La compra será eliminada permanentemente.\n ¿Desea continuar?")) {
      this.isLoading = true;
      this._ServerService.deleteShopping(id).subscribe((response) => {
        console.log(response);
        this.showNotification("top", "center", 2, 2, "La compra ha sido eliminada del sistema.");
        this.getUserShoppingList();
      })
    }
  }

  changeStatus(id,status) {
    let statusOther;
    switch (status) {      
      case 'Pendiente':
        statusOther = 'pending'
        break;
      case 'Recibido':
        statusOther = 'received'
        break;          
      case 'Enviado':
        statusOther = 'sent'
        break;      
    }
    this._ServerService.changeShoppingStatus(id,{statusOther: statusOther}).subscribe((response) => {
      console.log(response);
    } )
  }

  showNotification(from, align, col, time, message) {
    const type = ['', 'info', 'success', 'warning', 'danger'];

    // const color = Math.floor((Math.random() * 4) + 1);
    // 4 red 3 yellow 2 green 1 blue
    const color = col;

    $.notify({
      icon: 'notifications',
      message: message

    }, {
      type: type[color],
      timer: time * 1000,
      placement: {
        from: from,
        align: align
      },
      template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span class="text-center" data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }

}
