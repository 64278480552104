import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ServerService } from '../../services/server.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'app/services/auth/auth.service';
import { ItemsAllowCubaService } from 'app/services/items-allow-cuba.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { element } from 'protractor';

declare var $: any;


@Component({
  selector: 'app-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss'],
})
export class AddProductComponent implements OnInit {

  myForm = this.fb.group({
    link: [''],
    price: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    description: ['', [Validators.required]],
    quantity: [Number, [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(1)]],
    customValue: [''],
    quantityAllowed: [''],
    customUm: [''],
    customName: [''],
  });

  filteredCustoms: Observable<string[]>;
  items = [];
  itemQuantity: string;
  itemValue: string;
  itemUm: string;
  itemName: string;
  customToFind = new FormControl();
  data: any;
  isLoading: boolean;
  objInfo: any;
  isEditing: boolean;

  constructor(public server: ServerService,
    private authService: AuthService,
    public router: Router,
    private fb: FormBuilder,
    public activatedRoute: ActivatedRoute,
    private itemsCuba: ItemsAllowCubaService,
    private dialogRef: MatDialogRef<AddProductComponent>,
    @Inject(MAT_DIALOG_DATA) public dataProduct: any,) {

  }

  ngOnInit() {
    this.objInfo = this.dataProduct;
    if (this.objInfo) {
      this.isEditing = true;
      this.myForm.patchValue({
        link: this.objInfo.link,
        price: this.objInfo.price,
        description: this.objInfo.description,
        quantity: this.objInfo.quantity,
        customValue: this.objInfo.customValue,
        customUm: this.objInfo.customUm,
        customName: this.objInfo.customName,
        quantityAllowed: this.objInfo.quantityAllowed,
      });

    }else {
      this.isEditing = false;      
      this.myForm.patchValue({
      price: 0,
      quantity: 1
    });
    }
    this.formatCustomValoration();
  }

  formatCustomValoration() {
    let all = this.itemsCuba.getItems();
    all.map(x => { return x.element }).forEach(y => {
      y.forEach(z => {
        var n;
        var i = 0;
        while (n !== '.') {
          n = z.name.charAt(i)
          i++;
        }
        var sliced = z.name.slice(i + 1, -1)
        z.name = sliced;
        this.items.push(z)
      })
    })
    this.initializeCustomAutoComplete();
  }


  
  initializeCustomAutoComplete() {
    this.filteredCustoms = this.customToFind.valueChanges.pipe(
      startWith(''),
      map(value => value.length >= 1 ? this._filterCustom(value) : [])
    );
  }

  
  private _filterCustom(value: string): string[] {
    const customfilterValue = value.toLowerCase();
    return this.items.filter(option => option.name.toLowerCase().includes(customfilterValue));
  }

  finalPriceCalculator() {
    let finalPrice = (this.myForm.value.precio * this.myForm.value.quantity) + (Number(this.itemValue) * this.myForm.value.quantity);
    this.myForm.patchValue({
      precio: finalPrice
    });  
  }

  

  searchCustomDetails() {
    this.itemQuantity = '';
    this.itemValue = '';
    this.itemUm = '';
    this.itemName = '';
    let pattern = this.customToFind.value;
    let tmp = this.items.find((m) => m.name === pattern);
    if (tmp) {
      this.itemName = tmp.name;
      this.itemQuantity = tmp.quantity;
      this.itemValue = tmp.value;
      this.itemUm = tmp.um;
      this.myForm.patchValue({
        customValue : this.itemValue,
        quantityAllowed: this.itemQuantity,
        customUm: this.itemUm,
        customName: this.itemName,
      });
    }
  }

  

  addProduct() {    
    this.dialogRef.close(this.myForm.value);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  getBack() {
    this.router.navigate(['shoppings']);
  }

  showNotification(from, align, col, time, message) {
    const type = ['', 'info', 'success', 'warning', 'danger'];

    // const color = Math.floor((Math.random() * 4) + 1);
    // 4 red 3 yellow 2 green 1 blue
    const color = col;

    $.notify({
      icon: 'notifications',
      message: message

    }, {
      type: type[color],
      timer: time * 1000,
      placement: {
        from: from,
        align: align
      },
      template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span class="text-center" data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }


}
