import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ServerService } from '../../services/server.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ConfirmComponent } from '../confirm/confirm.component'
import {MatDatepickerModule} from '@angular/material/datepicker';
import { environment } from './../../../environments/environment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthService } from 'app/services/auth/auth.service';
import { shoppingStatus } from '../../components/models/shoppingStatus.model';
declare var $: any;



@Component({
  selector: 'app-shopping',
  templateUrl: './shopping.component.html',
  styleUrls: ['./shopping.component.scss'],
})
export class ShoppingComponent implements OnInit {

  favoriteSeason: string = "euro";
  user_role: any;  
  data: any;
  shoppingLists: any;
  isLoading: boolean;
  userToFind = new FormControl();  
  filteredUsers: Observable<string[]>;
  listToShow = [];
  status = shoppingStatus;

  constructor(public _ServerService: ServerService,
              public router: Router, private fb: FormBuilder, 
              public dialog: MatDialog,
              private authService: AuthService,
              public activatedRoute: ActivatedRoute) {
              this.user_role = localStorage.getItem('user_role');
  }

  ngOnInit() {
    this.shoppingList(); 

}

  shoppingList() {
    this.isLoading = true;
    this._ServerService.getAllShoppings().subscribe((response) => {
      console.log(response);
      this.data = response;
      this.shoppingLists = this.data.data;  
      this.shoppingLists.forEach(element => {
        switch (element.statusOther) {
          case 'pending':
            element.selectedStatus = 'Pendiente'
            break;
          case 'received':
            element.selectedStatus = 'Recibido'
            break;          
          case 'sent':
            element.selectedStatus = 'Enviado'
            break;
          
        }
      })
      this.shoppingLists.sort((a, b) => Date.parse(b.updated_at) -  Date.parse(a.updated_at));
      this.initializeAutoComplete();
      this.listToShow = this.shoppingLists;       
      this.isLoading = false;
    });
  }

  initializeAutoComplete() {
    this.filteredUsers = this.userToFind.valueChanges.pipe(
      startWith(''),
      map(value => value.length >=1 ? this._filterUser(value) : [])     
    );
  }
  
  private _filterUser(value: string): string[] {
    const filterValue = value.toLowerCase();  
    let data = this.shoppingLists.filter(option => option.comprador.nombre.toLowerCase().indexOf(filterValue) === 0);
    let onlyName = [];
    data.forEach(option => onlyName.push(option.comprador.nombre))
    let onlyNameFiltered = onlyName.filter((item,index)=>{
      return onlyName.indexOf(item) === index;
    }) 
    return onlyNameFiltered;
  
  }
  
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();  
    return this.shoppingLists.filter(option => option.comprador.nombre.toLowerCase().indexOf(filterValue) === 0);
  }

  resetList (){
    this.listToShow = this.shoppingLists; 
    this.userToFind.setValue('');
  }

  searchUser() {
    let pattern = this.userToFind.value;
    this.listToShow = this._filter(pattern);    
  }

  addShopping() {    
    this.router.navigate(['/add-edit-shopping']);
  }

  

  editShopping(id: any) {    
    this.router.navigate([`/add-edit-shopping/${id}`]);
  }

  deleteShopping(id: any) {
    // var dialogRef = this.dialog.open(ConfirmComponent);
    if (confirm("La compra será eliminada permanentemente.\n ¿Desea continuar?")) {
      this.isLoading = true;
      this._ServerService.deleteShopping(id).subscribe((response) => {
        console.log(response);
        this.showNotification("top", "center", 2, 2, "La compra ha sido eliminada del sistema.");
        this.shoppingList();
      })
    }
  }

  changeStatus(id,status) {
    let statusOther;
    switch (status) {      
      case 'Pendiente':
        statusOther = 'pending'
        break;
      case 'Recibido':
        statusOther = 'received'
        break;          
      case 'Enviado':
        statusOther = 'sent'
        break;      
    }
    this._ServerService.changeShoppingStatus(id,{statusOther: statusOther}).subscribe((response) => {
      console.log(response);
    } )
  }

  finishShopping(id) {
    this.isLoading = true;
    let userId = this.authService.getUserID();
    this._ServerService.finishShopping(id,{status: 'finished', userId: userId }).subscribe((response) => {
      console.log(response);
      this.showNotification("top", "center", 2, 2, "La compra ha sido finalizada con exito.");
      this.shoppingList();
  })
} 

  calculateSaldo(account: any): Number {
    let saldo = 0;
    account.movements.forEach(m => {
      saldo += m.amount;
    })
    return saldo;
  }

 
  
  showNotification(from, align, col, time, message) {
    const type = ['', 'info', 'success', 'warning', 'danger'];

    // const color = Math.floor((Math.random() * 4) + 1);
    // 4 red 3 yellow 2 green 1 blue
    const color = col;

    $.notify({
      icon: 'notifications',
      message: message

    }, {
      type: type[color],
      timer: time * 1000,
      placement: {
        from: from,
        align: align
      },
      template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span class="text-center" data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }

}
