import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ServerService } from './../../services/server.service';
import { Subject, from, merge, Observable, Subscription } from 'rxjs';
import { switchMap, map, windowCount, scan, take, tap } from 'rxjs/operators';
import { ChatModule, Message, User, Action, ExecuteActionEvent, SendMessageEvent } from '@progress/kendo-angular-conversational-ui';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

declare var $: any;
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  providers: [ServerService]
})
export class ChatComponent implements OnInit, OnDestroy {

  @ViewChild('kendo') kendoS: ElementRef;

  public feed: Observable<Message[]>;
  private local: Subject<Message> = new Subject<Message>();

  public readonly admin: User = {
    id: 1
  };

  public readonly client: User = {
    id: 0
  };

  private subsciption: Subscription;

  conversations: any;
  client_list: any = [];
  client_list_temp: any = [];
  conversations_for_details: any = [];
  selected_client: string = "...";
  messages: any;
  data: any;
  id_client_support: any;
  i: any;
  resAsync: any;
  spinner: boolean = false;
  progress_bar: boolean = true;
  first_view: boolean = true;
  mobileSize: boolean;

  // public adapter: ChatAdapter;

  constructor(public service: ServerService) {
    this.getAllChatInformation();
    this.service.newMessageReceived().subscribe(res => {
      this.resAsync = res;
      const emmiterMsg = this.client_list_temp.find(e => e._id == this.resAsync.emmiter)
      setTimeout(() => {
        if (emmiterMsg.clientname == 'admin') {
          this.local.next({
            author: this.admin,
            text: this.resAsync.msg,
            timestamp: new Date()
          });
        } else {
          this.local.next({
            author: this.client,
            text: this.resAsync.msg,
            timestamp: new Date()
          });
        }
      }, 300);
    });
    this.i = setInterval(() => {

      this.getAllChatInformation();

    }, 5000);
  }

  ngOnInit() {
    this.datosdelConstructor();
  }

  ngOnDestroy(): void {
    clearInterval(this.i);
    this.subsciption.unsubscribe();
  }

  onResize(event) {
    this.mobileSize = (event.target.innerWidth <= 600) ? true : false;
  }

  datosdelConstructor() {
    var miarray: Message[] = []

    const hello: Message = {
      author: this.client,
      timestamp: new Date(),
      text: ''
    };

    const dell: Message = {
      author: this.client,
      timestamp: new Date(),
      text: 'Aqiu puedo poner el mensaje del cliente'
    }

    miarray = []

    // Merge local and remote messages into a single stream
    this.feed = merge(
      from(miarray),
      this.local,
      this.service.responses.pipe(
        map((response): Message => ({
          author: this.client,
          text: response
        })
        ))
    ).pipe(
      // ... and emit an array of all messages
      scan((acc: Message[], x: Message) => [...acc, x], [])
    );
  }

  public sendMessage(e: SendMessageEvent): void {
    this.service.sendMessage({ msg: e.message.text, conversation: localStorage.getItem('id_coversation'), emmiter: this.id_client_support });

    this.service.addMessage(e.message.text, localStorage.getItem('id_coversation'), this.id_client_support).subscribe((res) => {
      this.refresh();
    })

  }

  getAllChatInformation() {
    this.subsciption = this.service.getAllChatInformation().subscribe((response) => {
      this.data = response;
      this.conversations = this.data.conversations;
      this.conversations_for_details = this.data.conversations_for_details;
      this.client_list_temp = this.data.clients_for_details.sort((f1, f2) => {
        if (f1.conversation.date_updated > f2.conversation.date_updated) {
          return -1;
        }
        if (f1.conversation.date_updated < f2.conversation.date_updated) {
          return 1;
        }
        return 0;
      });
      this.client_list = this.client_list_temp.filter(element => element.clientname != 'admin');
      this.messages = this.data.messages;
      const suportC = this.client_list_temp.find(element => element.clientname == 'admin');
      this.id_client_support = suportC._id;
      localStorage.setItem('client_support', this.id_client_support);

      this.progress_bar = false;

    })
  }

  //hacer una funcion de indexado con los usuarios.

  getMessageNotReaded(cliente?: any): number {
    var cont = 0;
    const messageArray = this.conversations_for_details.find((element) => element.client == cliente._id).id_message;
    for (let i = 0; i < messageArray.length; i++) {
      const clientS = this.client_list_temp.find(element => element._id == messageArray[i].emitter);//optimizame con un index...
      if (clientS.clientname == 'admin') {
        continue;
      } else {
        if (!messageArray[i].readed) {
          cont++;
        }
      }
    }
    return cont;
  }

  refresh() {
    this.service.getAllChatInformation().subscribe((response) => {
      this.data = response;
      this.conversations = this.data.conversations;
      this.messages = this.data.messages;
    })
  }

  viewClient(client?) {
    this.spinner = true;
    this.first_view = false;
    this.selected_client = client.clientname

    this.feed = merge(
      from([]),
      this.local,
      this.service.responses.pipe(
        map((response): Message => ({
          author: this.client,
          text: response
        })
        ))
    ).pipe(
      scan((acc: Message[], x: Message) => [...acc, x], [])
    );

    this.refresh()
    setTimeout((x) => {
      localStorage.setItem('id_coversation', client.conversation._id);

      //emitiendo evento al socket
      this.service.leaveConversationEmit({ conversation: localStorage.getItem('last') })
      this.service.viewConversationEmit({ conversation: client.conversation._id })
      localStorage.setItem('last', client.conversation._id);

      //para cambiar el estado de los mensajes de una conversacion a visto
      this.service.changeStatusMessage(client.conversation._id).subscribe(response => {
        console.log(response)

        const ArrayData = this.conversations.find(element => element._id == client.conversation._id);
        var ArrayMessages = []
        for (let i = 0; i < ArrayData.id_message.length; i++) {
          const msg = this.messages.find(element => element._id == ArrayData.id_message[i]);
          ArrayMessages.push(msg);
        }
        for (let i = 0; i < ArrayMessages.length; i++) {
          const clientS = this.client_list_temp.find(element => element._id == ArrayMessages[i].emitter)
          if (clientS.clientname == 'admin') {
            this.local.next({
              author: this.admin,
              text: ArrayMessages[i].body,
              timestamp: new Date(ArrayMessages[i].date)
            });
          } else {
            this.local.next({
              author: this.client,
              text: ArrayMessages[i].body,
              timestamp: new Date(ArrayMessages[i].date)
            });
          }

        }

        this.spinner = false;

      });

    }, 2000);

  }

  showNotification(from, align, col, time, message) {
    const type = ['', 'info', 'success', 'warning', 'danger'];

    // const color = Math.floor((Math.random() * 4) + 1);
    // 4 red 3 yellow 2 green 1 blue
    const color = col;

    $.notify({
      icon: 'notifications',
      message: message

    }, {
      type: type[color],
      timer: time * 1000,
      placement: {
        from: from,
        align: align
      },
      template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span class="text-center" data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }


}
