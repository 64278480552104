
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ServerService } from './../../services/server.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ConfirmComponent } from './../confirm/confirm.component'

declare var $: any;

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss'],
  providers: [ServerService]
})
export class AccountsComponent implements OnInit {

  favoriteSeason: string = "euro";
  user_role: any;

  myForm = this.fb.group({
    name: ['', [Validators.required]],    
    type: ['', [Validators.required]],
    description: ['', [Validators.required]],
  });

  accountsList: any;
  data: any;
  isLoading: boolean;

  constructor(public _ServerService: ServerService, public router: Router, private fb: FormBuilder, public dialog: MatDialog) {
    this.user_role = localStorage.getItem('user_role');
  }

  ngOnInit() {
    this.accountList();    
}

  accountList() {
    this.isLoading = true;
    this._ServerService.getAllAccounts().subscribe((response) => {
      console.log(response);
      this.data = response;
      this.accountsList = this.data.data;
      this.isLoading =false;
    });
  }

  addAccount() {
      this.isLoading = true;
      this._ServerService.addAccount(this.myForm.value).subscribe((response) => {
        console.log(response);
        //verificar el response y ver errores en respuestas etc (manana)
        this.showNotification("top", "center", 2, 2, "La cuenta ha sido creada satisfactoriamente.");
        this.myForm.reset();        
        this.accountList();
      })
    
  }

  editAccount(id: any) {
    console.log(id);
    this.router.navigate([`/edit-account/${id}`]);
  }

  deleteAccount(id: any) {
    // var dialogRef = this.dialog.open(ConfirmComponent);
    if (confirm("La cuenta será eliminada permanentemente.\n ¿Desea continuar?")) {
      this.isLoading = true;
      this._ServerService.deleteAccount(id).subscribe((response) => {
        console.log(response);
        this.showNotification("top", "center", 2, 2, "La cuenta ha sido eliminada del sistema.");
        this.accountList();
      })
    }
  }

  calculateSaldo(account: any): Number {
    let saldo = 0;
    account.movements.forEach(m => {
      saldo += m.amount;
    })
    return saldo;
  }

  viewMovements(id) {
    this.router.navigate([`/view-account-movements/${id}`]);
  }
  
  showNotification(from, align, col, time, message) {
    const type = ['', 'info', 'success', 'warning', 'danger'];

    // const color = Math.floor((Math.random() * 4) + 1);
    // 4 red 3 yellow 2 green 1 blue
    const color = col;

    $.notify({
      icon: 'notifications',
      message: message

    }, {
      type: type[color],
      timer: time * 1000,
      placement: {
        from: from,
        align: align
      },
      template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span class="text-center" data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }

}
