import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';
declare const $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {

  @ViewChild('username', {static: false}) usernameChild!: ElementRef;

  message: string;
  data: any;
  error: any;

  myForm = this.fb.group({
    username: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });

  constructor(public authService: AuthService, private fb: FormBuilder,  private router: Router) {
    this.message = '';
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.usernameChild.nativeElement.focus());
  }

  isMobile() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  };

  async login(username: string, password: string) {
    this.message = '';
    this.authService.login(username, password).subscribe(
      data => {
        this.data = data;
        console.log(data);
        if(this.data.success){
          localStorage.setItem('username', this.data.user.username);
          localStorage.setItem('user_id', this.data.user._id);
          localStorage.setItem('user_role', this.data.user.role);
          localStorage.setItem('connection', this.data.user.isConnected);
          localStorage.setItem('email', this.data.user.email);
          localStorage.setItem('telephone', this.data.user.telephone);
          this.router.navigate(['/dashboard']);
        }       
      }, error => {
        this.error = error;
        if (this.error.status == 401) {
          this.message = 'CREDENCIALES INCORRECTAS !!!';
          setTimeout(function () {
            this.message = '';
          }.bind(this), 2500);
        }
      });

  }

  logout(): boolean {
    this.authService.logout();
    return false;
  }

}
