import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ServerService } from '../../services/server.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ConfirmComponent } from '../confirm/confirm.component'
import { MatDatepickerModule } from '@angular/material/datepicker';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AddPackageComponent } from "app/components/add-package/add-package.component";
import { PayPackageComponent } from "app/components/pay-package/pay-package.component";
declare var $: any;



@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss'],
})
export class PackagesComponent implements OnInit {

  favoriteSeason: string = "euro";
  user_role: any;
  data: any;
  packagesLists: any;
  isLoading: boolean;
  userToFind = new FormControl();
  filteredUsers: Observable<string[]>;
  listToShow = [];

  constructor(public _ServerService: ServerService,
    public router: Router, private fb: FormBuilder,
    public dialog: MatDialog,
    public activatedRoute: ActivatedRoute) {
    this.user_role = localStorage.getItem('user_role');
  }

  ngOnInit() {
    this.packageList();

  }

  packageList() {
    this.isLoading = true;
    this._ServerService.getAllPackages().subscribe((response) => {
      console.log(response);
      this.data = response;
      this.packagesLists = this.data.data;
      this.packagesLists.sort((a, b) => Date.parse(b.updated_at) - Date.parse(a.updated_at));
      this.initializeAutoComplete();
      this.listToShow = this.packagesLists;
      this.isLoading = false;
    });
  }

  initializeAutoComplete() {
    this.filteredUsers = this.userToFind.valueChanges.pipe(
      startWith(''),
      map(value => value.length >= 1 ? this._filterUser(value) : [])
    );
  }

  private _filterUser(value: string): string[] {
    const filterValue = value.toLowerCase();
    let data = this.packagesLists.filter(option => option.comprador.nombre.toLowerCase().indexOf(filterValue) === 0);
    let onlyName = [];
    data.forEach(option => onlyName.push(option.comprador.nombre))
    let onlyNameFiltered = onlyName.filter((item, index) => {
      return onlyName.indexOf(item) === index;
    })
    return onlyNameFiltered;

  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.packagesLists.filter(option => option.comprador.nombre.toLowerCase().indexOf(filterValue) === 0);
  }

  resetList() {
    this.listToShow = this.packagesLists;
    this.userToFind.setValue('');
  }

  searchUser() {
    let pattern = this.userToFind.value;
    this.listToShow = this._filter(pattern);
  }

  addPackage() {
    let data = {status: "adding", comprador: "", isComingFrom: ""}
    var dialogRefPackage = this.dialog.open(AddPackageComponent, {
      width: "1020px",
      height: "720px",
      data: data
    });
    dialogRefPackage.afterClosed().subscribe((result) => {
      if (result) {
        console.log("el resultado", result);
        this._ServerService.addPackage(result).subscribe((response) => {
          console.log(response);
          this.showNotification("top", "center", 2, 2, "El paquete ha sido creado con exito.");
          this.packageList();
        })
      }
    });
  }

  editPackage(id) {
    let item = this.packagesLists[id];
    item.status = "editing";
    item.isComingFrom = item.shopping ? "shopping" : "";
    var dialogRefPackage = this.dialog.open(AddPackageComponent, {
      width: "1020px",
      height: "720px",
      data: item,
    });
    dialogRefPackage.afterClosed().subscribe((result) => {
      if (result) {
        console.log("el resultado", result);
        this.isLoading = true;
        this._ServerService.editPackage(item._id, result).subscribe((response) => {
          console.log(response);
          this.showNotification("top", "center", 2, 2, "El paquete ha sido editado con exito.");
          this.packageList();
        })
        
      }
      this.packageList();
    });
  }

  deletePackage(id: any) {
    // var dialogRef = this.dialog.open(ConfirmComponent);
    if (confirm("El paquete será eliminado permanentemente.\n ¿Desea continuar?")) {
      this.isLoading = true;
      this._ServerService.deletePackage(id).subscribe((response) => {
        console.log(response);
        this.showNotification("top", "center", 2, 2, "El paquete ha sido eliminado del sistema.");
        this.packageList();
      })
    }
  }

  payPackage(id) {
    var dialogRefPackage = this.dialog.open(PayPackageComponent, {
      width: "800px",
     // height: "100px",
    });
    dialogRefPackage.afterClosed().subscribe((result) => {
      if (result) {
        console.log("el resultado", result)
        this.isLoading = true;
        this._ServerService.payPackage(id, result).subscribe((response) => {
          console.log(response);
          this.showNotification("top", "center", 2, 2, "El paquete ha sido pagado con exito.");
          this.packageList();
        })
      }
    })
  }

  calculateSaldo(account: any): Number {
    let saldo = 0;
    account.movements.forEach(m => {
      saldo += m.amount;
    })
    return saldo;
  }



  showNotification(from, align, col, time, message) {
    const type = ['', 'info', 'success', 'warning', 'danger'];

    // const color = Math.floor((Math.random() * 4) + 1);
    // 4 red 3 yellow 2 green 1 blue
    const color = col;

    $.notify({
      icon: 'notifications',
      message: message

    }, {
      type: type[color],
      timer: time * 1000,
      placement: {
        from: from,
        align: align
      },
      template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span class="text-center" data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }

}
