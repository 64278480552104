import { Component, OnInit, Inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ServerService } from "../../services/server.service";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormControl, FormArray } from "@angular/forms";
import { Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AuthService } from "app/services/auth/auth.service";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { AddProductComponent } from "app/components/add-product/add-product.component";
import { AddPackageComponent } from "app/components/add-package/add-package.component";
import { AddAttachmentComponent } from "app/components/add-attachment/add-attachment.component";
import { UploadFilesService } from "../../services/upload-files.service";
import { HttpEventType, HttpResponse } from "@angular/common/http";
import { toUnicode } from "punycode";
import { environment } from "./../../../environments/environment";
import { shoppingStatus } from '../../components/models/shoppingStatus.model';
import * as moment from "moment";

declare var $: any;

@Component({
  selector: "app-add-shopping",
  templateUrl: "./add-shopping.component.html",
  styleUrls: ["./add-shopping.component.scss"],
})
export class AddShoppingComponent implements OnInit {
  readonly url = environment.baseURL;

  get packages() {
    return this.myForm.get("packages") as FormArray;
  }

  get products() {
    return this.myForm.get("products") as FormArray;
  }

  get attachments() {
    return this.myForm.get("attachments") as FormArray;
  }

  myForm = this.fb.group({
    idRequest: ["", [Validators.required]],
    description: [""],
    statusOther: [""],
    statusSelected: [""],
    comprador: ["", [Validators.required]],
    weight: [Number, [Validators.pattern("^[.0-9]*$")]],
    precio: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    packages: this.fb.array([]),
    products: this.fb.array([]),
    attachments: this.fb.array([]),
  });

  maxFileSize = 51200;
  data: any;
  buyer: String;
  isLoading: boolean;
  buyerList = [];
  filteredUsers: Observable<string[]>;
  userToFind = new FormControl();
  users = [];
  isEditing: boolean;
  isAdding: boolean;
  shoppingId: string;
  chosenFiles: FileList;
  existingFile: File;
  progress = 0;
  msg = "";
  isUploading: boolean;
  fileArray: [File];
  toBeUploaded: boolean;
  fileNameList: string[];
  status = shoppingStatus;
  statusSelected: string;

  constructor(
    public server: ServerService,
    private authService: AuthService,
    public router: Router,
    private fb: FormBuilder,
    public activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private uploadFilesService: UploadFilesService
  ) { }

  ngOnInit() {
    this.toBeUploaded = false;
    this.isLoading = false;
    this.shoppingId = this.activatedRoute.snapshot.params.id;
    this.isEditing = this.shoppingId ? true : false;
    this.isAdding = this.shoppingId ? false : true;
    if (this.isEditing) {
      this.getShopping();
    } else {
      this.getAllBuyers();
      this.myForm.patchValue({
        idRequest: "Compra_" + this.getCodeUnique(),
        precio: 0,
        weight: 0,
        statusOther: 'pending'
      });
      this.statusSelected = 'Pendiente'
    }
    console.log("MyForm :", this.myForm.value);
  }

  getCodeUnique() {
    return (
      moment().unix() * Math.floor(Math.random() * (20000 - 10000) + 1000)
    )
      .toString(30)
      .toUpperCase();
  }

  

  getShopping(id?) {    
    this.isLoading = true;
    this.server.getOneShopping(this.shoppingId).subscribe((response) => {
      this.data = response;
      switch (this.data.data.statusOther) {
        case 'pending':
          this.statusSelected = 'Pendiente'
          break;
        case 'received':
          this.statusSelected = 'Recibido'
          break;          
        case 'sent':
          this.statusSelected = 'Enviado'
          break;
      }
      console.log("Data :", this.data);
      this.myForm.patchValue({
        idRequest: this.data.data.idRequest,
        total: this.data.data.total,
        description: this.data.data.description,
        statusOther: this.data.data.statusOther,
        comprador: this.data.data.comprador,
        weight: this.data.data.weight,
        precio: this.data.data.precio,
      });     
      if (this.data.data.packages.length !== 0) {
        console.log("Agrego paquetes");
        let tmpPackages = this.data.data.packages;
        tmpPackages.forEach((value) => {
          this.packages.push(new FormControl(value));
        });
      }
      if (this.data.data.products.length !== 0) {
        console.log("Agrego productos");
        let tmpProducts = this.data.data.products;
        tmpProducts.forEach((value) => {
          this.products.push(new FormControl(value));
        });
      }
      if (this.data.data.attachments.length !== 0) {
        console.log("Agrego adjuntos");

        this.uploadFilesService.getFiles(this.myForm.value.idRequest).subscribe((response) => {
          let fileList = response;
          this.fileNameList = fileList.map((x) => {
            let y = x.name;
            return y;
          });
          console.log("El file List :", this.fileNameList);
          let tmpAttachments = this.data.data.attachments;
          console.log("tmpattachments", tmpAttachments);
          tmpAttachments.forEach((value) => {
            let fileName = value.name;
            if (this.fileNameList.includes(fileName)) {
              this.attachments.push(new FormControl(value));
            }
          });

        })
      }
      this.isLoading = false;
    });
  }

  getAllBuyers() {
    this.isLoading = true;
    this.server.getAllBuyers().subscribe((response) => {
      this.data = response;
      this.buyerList = this.data.data;
      this.buyerList.forEach((element) => this.users.push(element.nombre));
      this.initializeUserAutoComplete();
      this.isLoading = false;
    });
  }

  initializeUserAutoComplete() {
    this.filteredUsers = this.userToFind.valueChanges.pipe(
      startWith(""),
      map((value) => (value.length >= 1 ? this._filterUser(value) : []))
    );
  }
  private _filterUser(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.buyerList.filter(
      (option) => option.nombre.toLowerCase().indexOf(filterValue) === 0
    );
  }

  searchUser() {
    let pattern = this.userToFind.value;
    let tmp = this.buyerList.find((m) => (m.nombre === pattern)) ;
    if (tmp) {
      this.myForm.patchValue({
        comprador: tmp,
      });
    }
  }

  calculateTotalProductPrice() {
    let totalPrice = 0;
    this.products.value.forEach(element => {
      totalPrice += Number(element.price) * Number(element.quantity);
    })
    this.myForm.patchValue({
      precio: totalPrice
    });
  }

  calculateTotalPackageWeight() {
    let totalWeight = 0;
    this.packages.value.forEach(element => {
      totalWeight += Number(element.weight);
    })
    this.myForm.patchValue({
      weight: totalWeight
    });
  }

  addProduct() {
    var dialogRefProduct = this.dialog.open(AddProductComponent, {
      width: "1020px",
      height: "720px",
    });
    dialogRefProduct.afterClosed().subscribe((result) => {
      if (result) {
        console.log("el resultado", result);
        this.products.push(new FormControl(result));
        this.calculateTotalProductPrice();
      }
    });
  }

  editProduct(id) {
    let item = this.products.at(id).value;
    var dialogRefPackage = this.dialog.open(AddProductComponent, {
      width: "1020px",
      height: "720px",
      data: item,
    });
    dialogRefPackage.afterClosed().subscribe((result) => {
      if (result) {
        console.log("el resultado", result);
        this.products.at(id).patchValue(result);
        this.calculateTotalProductPrice();
      }
    });
  }

  deleteProduct(id) {
    this.products.removeAt(id);
    this.calculateTotalProductPrice();
  }

  addPackage() {
     let data = {status: "adding", comprador:this.myForm.value.comprador, isComingFrom: "shopping"}
    var dialogRefPackage = this.dialog.open(AddPackageComponent, {
      width: "1020px",
      height: "720px",
      data: data
    });
    dialogRefPackage.afterClosed().subscribe((result) => {
      if (result) {
        console.log("el resultado", result);
        this.packages.push(new FormControl(result));
        this.calculateTotalPackageWeight();
        console.log(this.myForm.value);
      }
    });
  }

  editPackage(id) {
    let item = this.packages.at(id).value;
    item.status = "editing";
    item.isComingFrom = "shopping";
    var dialogRefPackage = this.dialog.open(AddPackageComponent, {
      width: "1020px",
      height: "720px",
      data: item,
    });
    dialogRefPackage.afterClosed().subscribe((result) => {
      if (result) {
        console.log("el resultado", result);
        this.server.editPackage(item._id, result).subscribe((value) => {
          this.packages.at(id).patchValue(value);
        this.calculateTotalPackageWeight();
        console.log(this.myForm.value);
        })        
      }
    });
  }

  deletePackage(id) {
    let item = this.packages.at(id).value;
    this.server.deletePackage(item._id).subscribe(result => {
      this.packages.removeAt(id);
      this.calculateTotalPackageWeight(); 
    })
    
  }

  userIsNotSelected(): boolean {
    if (this.myForm.value.comprador === null || this.myForm.value.comprador == '') {
      return true;
    }else{
      return false;
    } 
  }  


  deleteAttachment(attachment, id) {
    let dir = this.myForm.value.idRequest
    if (!attachment.isUploaded) {
      this.toBeUploaded = false;
    }
    this.uploadFilesService.deleteFiles(attachment.name, dir).subscribe((event) => {
      this.attachments.removeAt(id);
      this.showNotification("top", "center", 2, 1, "El archivo adjunto a sido eliminado satisfactoriamete.");
    });
  }

  chooseFile(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {      
      const fileData = event.target.files;
      const [file] = event.target.files;
      this.chosenFiles = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {        
        let file = {
          name: fileData[0].name,
          size: Math.round(fileData[0].size / 1024),
          isUploaded: false,
        };
        this.attachments.push(new FormControl(file));
        this.toBeUploaded = true;
      };
    }
  }

  addShopping() {  
    let status
    switch (this.statusSelected) {      
      case 'Pendiente':
        status = 'pending'
        break;
      case 'Recibido':
        status = 'received'
        break;          
      case 'Enviado':
        status = 'sent'
        break;      
    }
    this.myForm.patchValue({
      statusOther: status,
    }) 
    this.isLoading = true;   
    this.server.addShopping(this.myForm.value).subscribe((response) => {
      this.showNotification("top", "center", 2, 2, "La compra ha sido creada satisfactoriamente.");
      this.myForm.reset();
      this.isLoading = false;
      this.getBack();
    });
  }

  editShopping() {
    let status
    switch (this.statusSelected) {      
      case 'Pendiente':
        status = 'pending'
        break;
      case 'Recibido':
        status = 'received'
        break;          
      case 'Enviado':
        status = 'sent'
        break;      
    }
    this.myForm.patchValue({
      statusOther: status,
    })
    console.log("Envio la forma :", this.myForm.value);
    this.isLoading = true;
    this.server
      .editShopping(this.shoppingId, this.myForm.value)
      .subscribe((response) => {
        this.showNotification("top", "center", 2, 2, "La compra ha sido editada satisfactoriamente.");
        this.myForm.reset();
        this.isLoading = false;
        this.getBack();
      });
  }

  getBack() {
    this.router.navigate(["/shoppings"]);
  }

  uploadFile(attachment, id): void {
    if (attachment.size < this.maxFileSize) {
      let dir = this.myForm.value.idRequest;
      this.progress = 0;
      this.existingFile = this.chosenFiles.item(0);
      this.isUploading = true;
      this.uploadFilesService.uploadFile(this.existingFile, dir).subscribe(
        (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progress = Math.round((100 * event.loaded) / event.total);
          } else if (event instanceof HttpResponse) {
            this.msg = event.body.message;
            this.attachments.at(id).value.isUploaded = true;
            console.log("el attachment :", this.attachments.value);
            this.showNotification("top", "center", 2, 1, "Archivo subido al servidor satifactoriamente.");
            this.isUploading = false;
            this.toBeUploaded = false;
            this.chosenFiles = undefined;
          }
        },
        (error) => {
          this.msg = error.error.message;
          this.progress = 0;
          this.showNotification("top", "center", 4, 1, this.msg);
          this.existingFile = undefined;
          this.isUploading = false;
        }
      );
    } else {
      this.showNotification("top", "center", 4, 1, "El archivo que esta intentando subir al servidor supera el tamaño maximo permitido de 50 Mb.");
    }
  }

  showNotification(from, align, col, time, message) {
    const type = ["", "info", "success", "warning", "danger"];

    // const color = Math.floor((Math.random() * 4) + 1);
    // 4 red 3 yellow 2 green 1 blue
    const color = col;

    $.notify(
      {
        icon: "notifications",
        message: message,
      },
      {
        type: type[color],
        timer: time * 1000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span class="text-center" data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }
}
