import { Component, Inject, OnInit } from '@angular/core';

import { FormBuilder, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServerService } from "../../services/server.service";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import {MatExpansionModule} from '@angular/material/expansion';


declare var $: any;
@Component({
  selector: 'app-package',
  templateUrl: './add-package.component.html',
  styleUrls: ['./add-package.component.scss'],
})

export class AddPackageComponent implements OnInit {

  myForm = this.fb.group({
    comprador: [''],
    senderName: [''],
    senderId: [''],
    senderAddress: [''],
    senderEmail: ['', [Validators.email]],
    senderPhone: ['', [Validators.pattern("^[0-9]*$"), Validators.minLength(6)]],
    receptorName: [''],
    receptorId: [''],
    receptorAddress: [''],
    receptorEmail: ['', [Validators.email]],
    receptorPhone: ['', [Validators.pattern("^[0-9]*$"), Validators.minLength(6)]],
    shopping: [''],
    province: ['', [Validators.required]],
    price: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    weight: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    description: ['']
  });

  provinces = [
    'Artemisa',
    'Camagüey',
    'Ciego de Ávila',
    'Cienfuegos',
    'Granma',
    'Guantánamo',
    'Holguín',
    'Isla de la Juventud',
    'La Habana',
    'Las Tunas',
    'Matanzas',
    'Mayabeque',
    'Pinar del Rio',
    'Sancti Spíritus', 
    'Santiago de Cuba',    
    'Villa Clara',    
  ];

  objInfo: any;
  isEditing: boolean;
  buyerList: any;
  users: any;
  filteredUsers: Observable<string[]>;
  userToFind = new FormControl();
  isLoading: boolean;
  data1: any;
  isComingFromShopping: boolean;
  panelOpenState = false;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public server: ServerService,
    private dialogRef: MatDialogRef<AddPackageComponent>) {
  }

  ngOnInit() {
    this.objInfo = this.data;
    this.isComingFromShopping = this.objInfo.isComingFrom === "shopping" ? true : false;
    console.log('Recibo como objInfo :', this.objInfo);
    this.getAllBuyers();
    if (this.data.status === 'editing') {
      console.log("Estoy editando")
      this.isEditing = true;
      this.myForm.patchValue({
        comprador: this.objInfo.comprador,
        senderName: this.objInfo.senderName,
        senderId: this.objInfo.senderId,
        senderAddress: this.objInfo.senderAddress,
        senderEmail: this.objInfo.senderEmail,
        senderPhone: this.objInfo.senderPhone,
        receptorName: this.objInfo.receptorName,
        receptorId: this.objInfo.receptorId,
        receptorAddress: this.objInfo.receptorAddress,
        receptorEmail: this.objInfo.receptorEmail,
        receptorPhone: this.objInfo.receptorPhone,
        shopping: this.objInfo.shopping,
        province: this.objInfo.province,
        price: this.objInfo.price,
        weight: this.objInfo.weight,
        description: this.objInfo.description,
      });
    } else {
      console.log("Estoy añadiendo")
      this.isEditing = false;
      this.myForm.patchValue({
        comprador: this.objInfo.comprador,
        price: 0,
        weight: 0
      });
    }

  }

  getAllBuyers() {
    this.isLoading = true;
    this.server.getAllBuyers().subscribe((response) => {
      this.data1 = response;
      this.buyerList = this.data1.data;
      console.log('usuarios :', this.buyerList);
      this.initializeUserAutoComplete();
      this.isLoading = false;
    });
  }

  initializeUserAutoComplete() {
    this.filteredUsers = this.userToFind.valueChanges.pipe(
      startWith(""),
      map((value) => (value.length >= 1 ? this._filterUser(value) : []))
    );
  }
  private _filterUser(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.buyerList.filter(
      (option) => option.nombre.toLowerCase().indexOf(filterValue) === 0
    );
  }

  searchUser() {
    let pattern = this.userToFind.value;
    let tmp = this.buyerList.find((m) => m.nombre === pattern);
    if (tmp) {
      this.myForm.patchValue({
        comprador: tmp,
      });
    }
  }


  addPackage() {
    this.dialogRef.close(this.myForm.value);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }



  showNotification(from, align, col, time, message) {
    const type = ['', 'info', 'success', 'warning', 'danger'];

    // const color = Math.floor((Math.random() * 4) + 1);
    // 4 red 3 yellow 2 green 1 blue
    const color = col;

    $.notify({
      icon: 'notifications',
      message: message

    }, {
      type: type[color],
      timer: time * 1000,
      placement: {
        from: from,
        align: align
      },
      template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span class="text-center" data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }


}
