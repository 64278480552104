export const Provinces: string[] = [
    'Artemisa',
    'Camagüey',
    'Ciego de Ávila',
    'Cienfuegos',
    'Granma',
    'Guantánamo',
    'Holguín',
    'Isla de la Juventud',
    'La Habana',
    'La HabDomic',
    'Las Tunas',
    'Matanzas',
    'Mayabeque',
    'Pinar del Rio',
    'Sancti Spíritus',
    'Santiago de Cuba',
    'Villa Clara',
] 