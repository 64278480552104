import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ServerService } from './../../services/server.service';
import { Subject, from, merge, Observable } from 'rxjs';
import { Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { switchMap, map, windowCount, scan, take, tap } from 'rxjs/operators';
import { ChatModule, Message, User, Action, ExecuteActionEvent, SendMessageEvent } from '@progress/kendo-angular-conversational-ui';
declare var $: any;


@Component({
  selector: 'app-shared-element',
  templateUrl: './shared-element.component.html',
  styleUrls: ['./shared-element.component.scss'],
})
export class SharedElementComponent implements OnInit {

  @ViewChild('kendo') kendoS: ElementRef;

  public feed: Observable<Message[]>;
  private local: Subject<Message> = new Subject<Message>();

  public readonly admin: User = {
    id: 1
  };

  public readonly client: User = {
    id: 0
  };

  conversations: any;
  client_list: any = [];
  client_list_temp: any = [];
  messages: any;
  data: any;
  data2: any;
  _id_conversation: any;
  _id_client: any;
  id_client_support: any;
  visible: boolean = true;
  visible1: boolean = true;
  visible2: boolean = false;
  visible3: boolean = false;
  emailnoexiste = false;
  searchAccount: boolean = false;
  spinner: boolean = false;
  resAsync: any;

  myForm = this.fb.group({
    clientname: ['', [Validators.required, Validators.minLength(3)]],
    email: ['', [Validators.required, Validators.email]],
    body: ['', [Validators.required, Validators.minLength(6)]],
  });

  myFormSearc = this.fb.group({
    emailsearch: ['', [Validators.required, Validators.email]],
  });

  constructor(public service: ServerService, private fb: FormBuilder) {
    this.service.newMessageReceived().subscribe(res => {
      this.resAsync = res;
      const emmiterMsg = this.client_list_temp.find(e => e._id == this.resAsync.emmiter)
      setTimeout(() => {
        if (emmiterMsg.clientname == 'admin') {
          this.local.next({
            author: this.client,
            text: this.resAsync.msg,
            timestamp: new Date()
          });
        } else {
          this.local.next({
            author: this.admin,
            text: this.resAsync.msg,
            timestamp: new Date()//la fecha hay que poner la que viene del server con el mensaje, me da un error...
          });
        }
      }, 300);
    })
  }

  ngOnInit() {
  }

  public sendMessage(e: SendMessageEvent): void {
    this.service.sendMessage({ msg: e.message.text, conversation: this._id_conversation, emmiter: this._id_client });

    //salvando el dato 
    this.service.addMessage(e.message.text, this._id_conversation, this._id_client).subscribe((res) => {
      console.log(res);
    });

  }

  getAllChatInformation() {
    return new Promise((resolve, reject) => {
      this.service.getAllChatInformation().subscribe((response) => {
      this.data = response;
      console.log(response);
      this.conversations = this.data.conversations;
      this.client_list_temp = this.data.clients;
      this.client_list = this.client_list_temp.filter(element => element.clientname != 'admin');
      // this.client_list = this.data.clients;
      this.messages = this.data.messages;
      // const suportC = tempo.find(element => element.clientname == 'admin');
      const suportC = this.client_list_temp.find(element => element.clientname == 'admin');
      this.id_client_support = suportC._id;
      localStorage.setItem('client_support', this.id_client_support);
      resolve("Listo el dato");
      }, (error) => {
        reject("Error Al cargar");
      });
    });   
  }

  enviarCrear() {
    this.searchAccount = true;
    this.service.createConversationNewClient(this.myForm.value).subscribe(response => {
      this.data = response;

      if (this.data.msg == "BAD REQUEST") {
        //es debido a que no se creo la conversacion porque existe el usuario 
        console.log(this.myForm.value.email)
        this.service.getClientByEmail(this.myForm.value.email).subscribe(response => {
          this.data = response;
          this.service.sendMessage({ msg: this.myForm.value.body, conversation: this.data.data.conversation, emmiter: this.data.data._id });         
          this.service.addMessage(this.myForm.value.body, this.data.data.conversation, this.data.data._id).subscribe((res) => {  
              this.spinner = true;
              this._id_conversation = this.data.data.conversation;
              this._id_client = this.data.data._id;

              this.getAllChatInformation().then((msg) => {

                this.feed = merge(
                from([]),
                this.local,
                this.service.responses.pipe(
                  map((response): Message => ({
                    author: this.client,
                    text: response
                  })
                  ))
              ).pipe(
                scan((acc: Message[], x: Message) => [...acc, x], [])
              );
    
              setTimeout((x) => {
                this.service.viewConversationEmit({ conversation: this._id_conversation })
    
                const ArrayData = this.conversations.find(element => element._id == this._id_conversation);
                var ArrayMessages = []
                for (let i = 0; i < ArrayData.id_message.length; i++) {
                  const msg = this.messages.find(element => element._id == ArrayData.id_message[i]);
                  ArrayMessages.push(msg);
                }
                for (let i = 0; i < ArrayMessages.length; i++) {
                  const clientS = this.client_list_temp.find(element => element._id == ArrayMessages[i].emitter)
                  if (clientS.clientname == 'admin') {
                    this.local.next({
                      author: this.client,
                      text: ArrayMessages[i].body,
                      timestamp: new Date(ArrayMessages[i].date)
                    });
                  } else {
                    this.local.next({
                      author: this.admin,
                      text: ArrayMessages[i].body,
                      timestamp: new Date(ArrayMessages[i].date)
                    });
                  }
    
                }
                this.spinner = false;                        
              }, 3000);
              this.visible1 = false;
              this.visible2 = true;
              this.emailnoexiste = false;
              this.searchAccount = false;    

              })    

                
          });
              
        });
      } 

      else {
        this.spinner = true;
        this._id_conversation = this.data.data.conversation._id;
        this._id_client = this.data.data._id;

        this.getAllChatInformation().then((msg) => {

          this.feed = merge(
          from([]),
          this.local,
          this.service.responses.pipe(
            map((response): Message => ({
              author: this.client,
              text: response
            })
            ))
        ).pipe(
          scan((acc: Message[], x: Message) => [...acc, x], [])
        );

        setTimeout((x) => {
          this.service.viewConversationEmit({ conversation: this._id_conversation })

          const ArrayData = this.conversations.find(element => element._id == this._id_conversation);
          var ArrayMessages = []
          for (let i = 0; i < ArrayData.id_message.length; i++) {
            const msg = this.messages.find(element => element._id == ArrayData.id_message[i]);
            ArrayMessages.push(msg);
          }
          for (let i = 0; i < ArrayMessages.length; i++) {
            const clientS = this.client_list_temp.find(element => element._id == ArrayMessages[i].emitter)
            if (clientS.clientname == 'admin') {
              this.local.next({
                author: this.client,
                text: ArrayMessages[i].body,
                timestamp: new Date(ArrayMessages[i].date)
              });
            } else {
              this.local.next({
                author: this.admin,
                text: ArrayMessages[i].body,
                timestamp: new Date(ArrayMessages[i].date)
              });
            }
          }
          this.spinner = false;
        }, 3000);
        this.visible1 = false;
        this.visible2 = true;
        this.searchAccount = false;
        });        
      }
    }, error => {
      console.log('Ya existe')
      // this.showNotification("top", "center", 4, 4, "La cuenta de correo existe, verifique sus datos o entre al chat creado previamente.");
    });
  }

  searchAccountByEmailAPI() {
    this.searchAccount = true;    
    console.log(this.myFormSearc.value.emailsearch)
    this.service.getClientByEmail(this.myFormSearc.value.emailsearch).subscribe(response => {
      this.data = response;
      if (this.data.msg == "BAD REQUEST") {
        this.showNotification("top", "center", 4, 4, "La cuenta de correo no existe, verifique sus datos o cancele para crear una nueva conversación.");
      } else {
        if (this.data.data != null) {
          this.spinner = true;
          this._id_conversation = this.data.data.conversation;
          this._id_client = this.data.data._id;

          this.getAllChatInformation().then((msg) => {

            this.feed = merge(
            from([]),
            this.local,
            this.service.responses.pipe(
              map((response): Message => ({
                author: this.client,
                text: response
              })
              ))
          ).pipe(
            scan((acc: Message[], x: Message) => [...acc, x], [])
          );

          setTimeout((x) => {
            this.service.viewConversationEmit({ conversation: this._id_conversation })

            const ArrayData = this.conversations.find(element => element._id == this._id_conversation);
            var ArrayMessages = []
            for (let i = 0; i < ArrayData.id_message.length; i++) {
              const msg = this.messages.find(element => element._id == ArrayData.id_message[i]);
              ArrayMessages.push(msg);
            }
            for (let i = 0; i < ArrayMessages.length; i++) {
              const clientS = this.client_list_temp.find(element => element._id == ArrayMessages[i].emitter)
              if (clientS.clientname == 'admin') {
                this.local.next({
                  author: this.client,
                  text: ArrayMessages[i].body,
                  // timestamp: new Date()
                  timestamp: new Date(ArrayMessages[i].date)
                });
              } else {
                this.local.next({
                  author: this.admin,
                  text: ArrayMessages[i].body,
                  timestamp: new Date(ArrayMessages[i].date)
                });
              }

            }
            this.spinner = false;                        
          }, 3000);
          this.visible3 = false;
          this.visible2 = true;
          this.emailnoexiste = false;
          this.searchAccount = false; 

          });

                   
        } else {
          this.emailnoexiste = true;
          setTimeout(() => {
            this.emailnoexiste = false;
          }, 5000);
          this.searchAccount = false;
        }
      }  
    })  
  }

  visibleState() {
    if (this.visible) {
      this.visible = false;
      if (this.visible2) {
        this.visible2 = false;
        this.visible3 = true;
      }
    } else {
      this.visible = true;
    }
  }

  searchAccountByEmail() {
    this.visible1 = false;
    this.visible3 = true;
  }

  searchAccountByEmailCancel() {
    this.visible1 = true;
    this.visible3 = false;
  }

  showNotification(from, align, col, time, message) {
    const type = ['', 'info', 'success', 'warning', 'danger'];

    // const color = Math.floor((Math.random() * 4) + 1);
    // 4 red 3 yellow 2 green 1 blue
    const color = col;

    $.notify({
      icon: 'notifications',
      message: message

    }, {
      type: type[color],
      timer: time * 1000,
      placement: {
        from: from,
        align: align
      },
      template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span class="text-center" data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }

}
