import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AuthService, AUTH_PROVIDERS } from './services/auth/auth.service';
import { LoginGuardGuard } from './login-guard.guard';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';



import {
  AgmCoreModule
} from '@agm/core';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { IonicModule } from '@ionic/angular';
import { MatNativeDateModule } from '@angular/material/core';
// import { NbThemeModule, NbLayoutModule } from '@nebular/theme';
// import { NbEvaIconsModule } from '@nebular/eva-icons';
// import { ChatModule } from '@progress/kendo-angular-conversational-ui';

@NgModule({
  imports: [
    
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    MatNativeDateModule,
    BrowserModule,    
    AgmCoreModule.forRoot({
      apiKey: 'YOUR_GOOGLE_MAPS_API_KEY'
    }),
    IonicModule.forRoot(),
    
    // NbThemeModule.forRoot({ name: 'default' }),
    // ChatModule,
    // NbLayoutModule,
    // NbEvaIconsModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent
  ],
  providers: [
    LoginGuardGuard,
    AUTH_PROVIDERS,
    AuthService,
    MatNativeDateModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
