import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { AuthService } from '../../services/auth/auth.service';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  myForm = this.fb.group({
    telephone: ['', [Validators.required, Validators.minLength(6)]],
    country: ['', [Validators.required, Validators.minLength(3)]],
    email: ['', [Validators.required, Validators.email]],
    username: ['', [Validators.required, Validators.minLength(4)]],
    password: ['', [Validators.required, Validators.minLength(8)]],
  });

  message: string;
  status: boolean;
  data: any;

  constructor(public authService: AuthService, private router: Router, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.message = '';
    this.status = false;
  }

  register(telephone: string, country: string, email: string, username: string, password: string): boolean {
    this.message = '';
    this.authService.register(telephone, country, email, username, password)
      .subscribe(data => {
        this.data = data;
        if (this.data.msg != null) {
          this.router.navigate(['/login']);
        }
      });
    this.status = false;
    return false;
  }

}
