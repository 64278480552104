import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ServerService } from './../../services/server.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'app/services/auth/auth.service';
declare var $: any;


@Component({
  selector: 'app-add-movement-with-user',
  templateUrl: './add-movement-with-user.component.html',
  styleUrls: ['./add-movement-with-user.component.scss'],
})
export class AddMovementWithUserComponent implements OnInit {

  allAccounts: any;
  arrayAccounts: any;
  _selectedAccount: any;
  favoriteSeason: string = "mlc";
  user_role:any;
  payments = ['mlc', 'cup', 'transferencia' ]
  public accountExchangeRateUnknow = false;
  public set selectedAccount(value) {
    const account = this.arrayAccounts.find(x => x._id == value);
    console.log("inside selectedAccount", account)
    this.accountExchangeRateUnknow = account.type == 'crypto' || account.type == 'mn' || account.type == 'otros'
    this._selectedAccount = value;
  }
  public get selectedAccount(){
    return this._selectedAccount;
  }

  myForm = this.fb.group({
    amount: [Number, [Validators.required, Validators.pattern("^[-.0-9]*$")]],
    account: ['', [Validators.required]],
   description: ['', [Validators.required]],
   rate: [1, [Validators.required, Validators.pattern("^[-.0-9]*$")]],
   compradorId: [''],
   userId: this.authService.getUserID(),
   date: [''],
  });

  
  
    

  compradorList:any;
  data:any;
  isLoading: boolean;

  constructor(public server: ServerService, private authService: AuthService, public router: Router, private fb: FormBuilder, public activatedRoute: ActivatedRoute) { 
    this.user_role = localStorage.getItem('user_role')
  }

  ngOnInit() {
    this.getAllBuyers();
    this.getAllAccounts();
    
    console.log('Dentro del Init de la app', this.activatedRoute.snapshot.params.id);
    this.myForm.patchValue({
      amount: 0,     
    });   
  }

  getBack() {  
    let backId = localStorage.getItem('backId-2');
    this.router.navigate([`/view-account-movements/${backId}`]); 
  }
 getAllAccounts() {
   this.isLoading = true;
  this.server.getAllAccounts().subscribe((response) => {
    this.allAccounts = response;
    this.arrayAccounts = this.allAccounts.data
    console.log ('Las cuentas :', this.arrayAccounts);
    this.isLoading = false;
  })   
 }

 getAllBuyers() {
   this.isLoading = true;
  this.server.getAllBuyers().subscribe((response) => {    
    this.data = response;
    this.compradorList = this.data.data;
    console.log(this.compradorList);
    this.isLoading = false;
  });
}



  addMovement() {
    this.isLoading = true;
    console.log (this.myForm.value);
    if (this.myForm.value.compradorId == '') {
      this.server.addMovementForAccount(this.myForm.value).subscribe((response) => {
        this.showNotification("top", "center", 2, 2, "El movimiento se ha realizado satisfactoriamente.");
        this.myForm.reset();
        this.isLoading = false;
        this.getBack();
      })   
    }else {
      this.server.addMovementForAccountAndUser(this.myForm.value).subscribe((response) => {
        this.showNotification("top", "center", 2, 2, "El movimiento se ha realizado satisfactoriamente.");
        this.myForm.reset();
        this.isLoading = false;
        this.getBack();
      })   
    }
    

  }

  showNotification(from, align, col, time, message) {
    const type = ['', 'info', 'success', 'warning', 'danger'];

    // const color = Math.floor((Math.random() * 4) + 1);
    // 4 red 3 yellow 2 green 1 blue
    const color = col;

    $.notify({
      icon: 'notifications',
      message: message

    }, {
      type: type[color],
      timer: time * 1000,
      placement: {
        from: from,
        align: align
      },
      template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span class="text-center" data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }


}
