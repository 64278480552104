import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscriber, Subject } from 'rxjs';
import * as io from 'socket.io-client';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServerService {

  // readonly url = 'https://api.support.paketea.com/api/';
  readonly url = environment.baseURL;

  // private socket: SocketIOClient.Socket;
  private socket;

  constructor(private http: HttpClient) {
    // this.socket = io('https://api.support.paketea.com');
    this.socket = io(environment.socket);
  }

  /*
  |-----------------------------------------------
  |   Endpoints Initial_Page_Test Section
  |-----------------------------------------------
  */

  initialPageTest() {
    return this.http.get(this.url)
      .pipe(map(response => response));
  }

  /*
  |-----------------------------------------------
  |   Endpoints Section_Actions Section
  |-----------------------------------------------
  */

  getAllSections() {
    return this.http.get(this.url + 'section')
      .pipe(map(response => response));
  }

  getSection(id: any) {
    return this.http.get(this.url + 'section/' + id)
      .pipe(map(response => response));
  }

  sectionEdit(id: any, Form: any) {
    return this.http.post(this.url + 'section/' + id, Form);
  }

  sectionCreate(Form: any) {
    return this.http.post(this.url + 'section', Form);
  }

  sectionDelete(id: any) {
    return this.http.delete(this.url + 'section/' + id)
      .pipe(map(response => response));
  }
  /*
  |-----------------------------------------------
  |   Endpoints Articles_Actions Section
  |-----------------------------------------------
  */
  addArticleToSection(id: any, Form: any) {
    return this.http.post(this.url + 'section/add-article/' + id, Form);
  }

  getArticle(id: any) {
    return this.http.get(this.url + 'article/' + id)
      .pipe(map(response => response));
  }

  getAllArticles() {
    return this.http.get(this.url + 'article')
      .pipe(map(response => response));
  }

  articleEdit(id: any, Form: any) {
    return this.http.post(this.url + 'article/' + id, Form);
  }

  articleDelete(id: any) {
    return this.http.delete(this.url + 'article/' + id)
      .pipe(map(response => response));
  }
  /*
  |-----------------------------------------------
  |   Endpoints Paragraph_Actions Section
  |-----------------------------------------------
  */
  addParagraphToArticle(id: any, Form: any) {
    return this.http.post(this.url + 'article/add-paragraph/' + id, Form);
  }

  paragraphDelete(id: any) {
    return this.http.delete(this.url + 'paragraph/' + id)
      .pipe(map(response => response));
  }

  getParagraph(id: any) {
    return this.http.get(this.url + 'paragraph/' + id)
      .pipe(map(response => response));
  }

  paragraphEdit(id: any, Form: any) {
    return this.http.post(this.url + 'paragraph/' + id, Form);
  }

  getAllParagraphs() {
    return this.http.get(this.url + 'paragraph')
      .pipe(map(response => response));
  }
  /*
  |-----------------------------------------------
  |   Endpoints Chat
  |-----------------------------------------------
  */
  createConversation(Form) {
    return this.http.post(this.url + `chat/create`, Form)
      .pipe(map(response => response));
  }

  addMessage(msg, id_conv, id_client) {
    return this.http.post(this.url + `chat/message/${id_conv}/${id_client}`, { body: msg })
      .pipe(map(response => response));
  }

  getConversation(id) {
    return this.http.get(this.url + `chat/conversation/${id}`)
      .pipe(map(response => response));
  }

  getConversations() {
    return this.http.get(this.url + `chat/conversation`)
      .pipe(map(response => response));
  }

  getAllChatInformation() {
    return this.http.get(this.url + `chat/all`)
      .pipe(map(response => response));
  }

  changeStatusMessage(id) {
    return this.http.get(this.url + `chat/conversation/status/${id}`)
      .pipe(map(response => response));
  }

  /*
 |-----------------------------------------------
 |   Endpoints Users
 |-----------------------------------------------
 */
  getAllUsers() {
    return this.http.get(this.url + `user`)
      .pipe(map(response => response));
  }

  getOneUser(id) {
    return this.http.get(this.url + `user/${id}`)
      .pipe(map(response => response));
  }

  createUser(Form) {
    return this.http.post(this.url + `user`, Form)
      .pipe(map(response => response));
  }

  editUser(id, Form) {
    return this.http.put(this.url + `user/${id}`, Form)
      .pipe(map(response => response));
  }

  deleteUser(id) {
    return this.http.delete(this.url + `user/${id}`)
      .pipe(map(response => response));
  }

  changeStatus(id, Form) {
    return this.http.post(this.url + `user/${id}`, Form)
      .pipe(map(response => response));
  }
 /*
 |-----------------------------------------------
 |   Endpoints Compradores
 |-----------------------------------------------
 */
getAllBuyers() {
  return this.http.get(this.url + `comprador` )
    .pipe(map(response => response));
}

getOneBuyer(id) {
  return this.http.get(this.url + `comprador/${id}`)
    .pipe(map(response => response));
}

createBuyer(Form) {
  return this.http.post(this.url + `comprador`, Form)
    .pipe(map(response => response));
}

editBuyer(id, Form) {
  return this.http.put(this.url + `comprador/${id}`, Form)
    .pipe(map(response => response));
}

deleteBuyer(id) {
  return this.http.delete(this.url + `comprador/${id}`)
    .pipe(map(response => response));
}

rechargeBuyer(id,form) {
  return this.http.post(this.url + `comprador/${id}`+ `/recharge`,form)
    .pipe(map(response => response));
}


/*
 |-----------------------------------------------
 |   Endpoints Compras
 |-----------------------------------------------
 */

addShopping(form) {
  return this.http.post(this.url + `shopping`,form)
    .pipe(map(response => response));
}

getUserShoppings(id_user) {
  return this.http.get(this.url + `shopping/${id_user}`+ `/get`)
    .pipe(map(response => response));
}
deleteShopping(id) {
  return this.http.delete(this.url + `shopping/${id}`)
    .pipe(map(response => response));
}

getAllShoppings() {
  return this.http.get(this.url + `shopping`)
    .pipe(map(response => response));
}

getOneShopping(id) {
  return this.http.get(this.url + `shopping/${id}`)
    .pipe(map(response => response));
}

editShopping(id, Form) {
  return this.http.put(this.url + `shopping/${id}`, Form)
    .pipe(map(response => response));
}

finishShopping(id,form) {
  return this.http.post(this.url + `shopping/${id}`,form)
    .pipe(map(response => response));
}

changeShoppingStatus(id,form) {
  return this.http.post(this.url + `shopping/${id}/status`,form)
    .pipe(map(response => response));
}
/*
 |-----------------------------------------------
 |   Endpoints Envios
 |-----------------------------------------------
 */

addShipping(form) {
  return this.http.post(this.url + `shipping`,form)
    .pipe(map(response => response));
}

addShippingArray(week_id,form) {
  return this.http.post(this.url + `shipping/${week_id}/array`,form)
    .pipe(map(response => response));
}

editShipping(id, Form) {
  return this.http.put(this.url + `shipping/${id}`, Form)
    .pipe(map(response => response));
}

deleteShipping(id, week_id) {
  return this.http.delete(this.url + `shipping/${id}/${week_id}`)
    .pipe(map(response => response));
}

getAllShippings() {
  return this.http.get(this.url + `shipping`)
    .pipe(map(response => response));
}

getOneShipping(id) {
  return this.http.get(this.url + `shipping/${id}`)
    .pipe(map(response => response));
}
getSenderNameShippings() {
  return this.http.get(this.url + `shipping/senderName`)
    .pipe(map(response => response));
}

getSenderIdShippings() {
  return this.http.get(this.url + `shipping/senderId`)
    .pipe(map(response => response));
}

getSenderAddressShippings() {
  return this.http.get(this.url + `shipping/senderAddress`)
    .pipe(map(response => response));
}

getSenderEmailShippings() {
  return this.http.get(this.url + `shipping/senderEmail`)
    .pipe(map(response => response));
}

getSenderPhoneShippings() {
  return this.http.get(this.url + `shipping/senderPhone`)
    .pipe(map(response => response));
}



getReceptorNameShippings() {
  return this.http.get(this.url + `shipping/receptorName`)
    .pipe(map(response => response));
}

getReceptorIdShippings() {
  return this.http.get(this.url + `shipping/receptorId`)
    .pipe(map(response => response));
}

getReceptorAddressShippings() {
  return this.http.get(this.url + `shipping/receptorAddress`)
    .pipe(map(response => response));
}



getReceptorPhoneShippings() {
  return this.http.get(this.url + `shipping/receptorPhone`)
    .pipe(map(response => response));
}

getSpreadSheetTabsName() {
  return this.http.get(this.url + `shipping/spreadSheetTabs`)
    .pipe(map(response => response));
}
/*
 |-----------------------------------------------
 |   Endpoints Semanas
 |-----------------------------------------------
 */

addWeek(form) {
  return this.http.post(this.url + `weeks`,form)
    .pipe(map(response => response));
}

getAllWeeks() {
  return this.http.get(this.url + `weeks`)
    .pipe(map(response => response));
}

getOneWeek(id) {
  return this.http.get(this.url + `weeks/${id}`)
    .pipe(map(response => response));
}

editWeek(id, Form) {
  return this.http.put(this.url + `weeks/${id}`, Form)
    .pipe(map(response => response));
}

/*
 |-----------------------------------------------
 |   Endpoints Configuracion
 |-----------------------------------------------
 */



getConfig(name) {
  return this.http.get(this.url + `config/${name}`)
    .pipe(map(response => response));
}

editConfig(id, Form) {
  return this.http.put(this.url + `config/${id}`, Form)
    .pipe(map(response => response));
}



/*
 |-----------------------------------------------
 |   Endpoints Paquetes
 |-----------------------------------------------
 */

addPackage(form) {
  return this.http.post(this.url + `packages`,form)
    .pipe(map(response => response));
}

getUserPackages(id_user) {
  return this.http.get(this.url + `packages/${id_user}`+ `/get`)
    .pipe(map(response => response));
}
deletePackage(id) {
  return this.http.delete(this.url + `packages/${id}`)
    .pipe(map(response => response));
}

getAllPackages() {
  return this.http.get(this.url + `packages`)
    .pipe(map(response => response));
}

getOnePackage(id) {
  return this.http.get(this.url + `packages/${id}`)
    .pipe(map(response => response));
}

editPackage(id, Form) {
  return this.http.put(this.url + `packages/${id}`, Form)
    .pipe(map(response => response));
}

payPackage(id,form) {
  return this.http.post(this.url + `packages/${id}`,form)
    .pipe(map(response => response));
}
/*
 |-----------------------------------------------
 |   Endpoints Cuentas
 |-----------------------------------------------
 */
getAllAccounts() {
  return this.http.get(this.url + `cuenta`)
    .pipe(map(response => response));
}

getOneAccount(id) {
  return this.http.get(this.url + `cuenta/${id}`)
    .pipe(map(response => response));
}

addAccount(Form) {
  return this.http.post(this.url + `cuenta`, Form)
    .pipe(map(response => response));
}

editAccount(id, Form) {
  return this.http.put(this.url + `cuenta/${id}`, Form)
    .pipe(map(response => response));
}

deleteAccount(id) {
  return this.http.delete(this.url + `cuenta/${id}`)
    .pipe(map(response => response));
}




/*
 |-----------------------------------------------
 |   Endpoints Movimientos
 |-----------------------------------------------
 */

getAllMovements() {
  return this.http.get(this.url + `movimientos`)
    .pipe(map(response => response));
}

getMovementsByAccount(id, data) {
  let url = this.url + `movimientos/account/${id}/find?description=${data.description}`
  if (data.dategt != ''){
    url += `&dategt=${data.dategt}`
  }
  if (data.datelt != ''){
    url += `&datelt=${data.datelt}`
  }
  if (data.amountgt != ''){
    url += `&amountgt=${data.amountgt}`
  }
  if (data.amountlt != ''){ 
    url += `&amountlt=${data.amountlt}`
  }
  return this.http.get(url).pipe(map(response => response));
}

getMovementsByUser(id, data) {
  let url = this.url + `movimientos/user/${id}/find?description=${data.description}`
  if (data.dategt != ''){
    url += `&dategt=${data.dategt}`
  }
  if (data.datelt != ''){
    url += `&datelt=${data.datelt}`
  }
  if (data.amountgt != ''){
    url += `&amountgt=${data.amountgt}`
  }
  if (data.amountlt != ''){ 
    url += `&amountlt=${data.amountlt}`
  }
  return this.http.get(url).pipe(map(response => response));
}

getOneMovement(id) {
  return this.http.get(this.url + `movimientos/${id}`)
    .pipe(map(response => response));
}

addMovement(Form) {
  return this.http.post(this.url + `movimientos`, Form)
    .pipe(map(response => response));
}

addMovementForAccountAndUser (Form) {
  return this.http.post(this.url + `movimientos/user`, Form)
    .pipe(map(response => response));
}

addMovementForAccount (Form) {
  return this.http.post(this.url + `movimientos/`, Form)
    .pipe(map(response => response));
}


deleteMovement(id) {
  return this.http.delete(this.url + `movimientos/${id}`)
    .pipe(map(response => response));
}

veryfyMovement(id,form) {
  return this.http.put(this.url + `movimientos/${id}`,form)
    .pipe(map(response => response));
}

findMovement(data){
  let url = this.url + `movimientos/find?description=${data.description}`
  if (data.dategt != ''){
    url += `&dategt=${data.dategt}`
  }
  if (data.datelt != ''){
    url += `&datelt=${data.datelt}`
  }
  if (data.amountgt != ''){
    url += `&amountgt=${data.amountgt}`
  }
  if (data.amountlt != ''){ 
    url += `&amountlt=${data.amountlt}`
  }
  return this.http.get(url).pipe(map(response => response));
}

/*
 |-----------------------------------------------
 |   Endpoints Client Conversation
 |-----------------------------------------------
 */
  createConversationNewClient(Form) {
    return this.http.post(this.url + `client`, Form)
      .pipe(map(response => response));
  }

  getClientByEmail(email: any) {
    return this.http.get(this.url + `client/email/${email}`)
      .pipe(map(response => response));
  }

  sendMessageToClient(Form: any) {
    return this.http.post(this.url + 'client/message/send/', Form);
  }

  /*
  |-----------------------------------------------
  |   Web Sockets
  |-----------------------------------------------
  */
  sendMessage(data) {
    this.socket.emit('message', data);
  }

  viewConversationEmit(data) {
    this.socket.emit('join', data);
  }

  leaveConversationEmit(data) {
    this.socket.emit('leave', data);
  }

  newMessageReceived() {
    let observable = new Observable<{ msg: String }>(observer => {
      this.socket.on('new', (data) => {
        console.log('El datra en el servicio', data)
        observer.next(data);
      });
      return () => { this.socket.disconnect(); }
    });
    return observable;
  }

  public readonly responses: Subject<string> = new Subject<string>();
  public submit(question: string): void {
    const length = question.length;
    const answer = `"${question}" contiene exactamente ${length} simbolos.`;

    setTimeout(
      () => this.responses.next(answer),
      1000
    );
  }

}
