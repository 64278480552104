export class TarifHelper {
    public fares: any;

    public tarifFromWeight(weight: number): number {
        let fareToUse;
        switch (weight) {
            case 2:
              fareToUse = this.fares.habana_Base_2_0;
              break;
            case 2.5:
              fareToUse = this.fares.habana_Base_2_5;
              break;
            case 3.0:
              fareToUse = this.fares.habana_Base_3_0;
              break;
            case 3.5:
              fareToUse = this.fares.habana_Base_3_5;
              break;
            case 4.0:
              fareToUse = this.fares.habana_Base_4_0;
              break;
            case 4.5:
              fareToUse = this.fares.habana_Base_4_5;
              break;
            case 5.0:
              fareToUse = this.fares.habana_Base_5_0;
              break;
            case 5.5:
              fareToUse = this.fares.habana_Base_5_5;
              break;
            case 6.0:
              fareToUse = this.fares.habana_Base_6_0;
              break;
            case 6.5:
              fareToUse = this.fares.habana_Base_6_5;
              break;
            case 7.0:
              fareToUse = this.fares.habana_Base_7_0;
              break;
            case 7.5:
              fareToUse = this.fares.habana_Base_7_5;
              break;
            case 8.0:
              fareToUse = this.fares.habana_Base_8_0;
              break;
            case 8.5:
              fareToUse = this.fares.habana_Base_8_5;
              break;
            case 9.0:
              fareToUse = this.fares.habana_Base_9_0;
              break;
            case 9.5:
              fareToUse = this.fares.habana_Base_9_5;
              break;
            case 10.0:
              fareToUse = this.fares.habana_Base_10_0;
              break;
            case 10.5:
              fareToUse = this.fares.habana_Base_10_5;
              break;
            default:
              if (weight > 10.5) fareToUse = (this.fares.fare_per_kilo * weight).toFixed(2);
              break;
        } 
        return Number(fareToUse);
    }

    selectClientFare(weight: number): number {
        let fareToUse;
        switch (weight) {
          case 1.5:
            fareToUse = this.fares.client_1_5;
            break;
          case 2.0:
            fareToUse = this.fares.client_2_0;
            break;
          case 2.5:
            fareToUse = this.fares.client_2_5;
            break;
          case 3.0:
            fareToUse = this.fares.client_3_0;
            break;
          case 3.5:
            fareToUse = this.fares.client_3_5;
            break;
          case 4.0:
            fareToUse = this.fares.client_4_0;
            break;
          case 4.5:
            fareToUse = this.fares.client_4_5;
            break;
          case 5.0:
            fareToUse = this.fares.client_5_0;
            break;
          case 5.5:
            fareToUse = this.fares.client_5_5;
            break;
          case 6.0:
            fareToUse = this.fares.client_6_0;
            break;
          case 6.5:
            fareToUse = this.fares.client_6_5;
            break;
          case 7.0:
            fareToUse = this.fares.client_7_0;
            break;
          case 7.5:
            fareToUse = this.fares.client_7_5;
            break;
          case 8.0:
            fareToUse = this.fares.client_8_0;
            break;
          case 8.5:
            fareToUse = this.fares.client_8_5;
            break;
          case 9.0:
            fareToUse = this.fares.client_9_0;
            break;
          case 9.5:
            fareToUse = this.fares.client_9_5;
            break;
          case 10.0:
            fareToUse = this.fares.client_10_0;
            break;
          case 10.5:
            fareToUse = this.fares.client_10_5;
            break;
          case 11.0:
            fareToUse = this.fares.client_11_0;
            break;
          case 11.5:
            fareToUse = this.fares.client_11_5;
            break;
          case 12.0:
            fareToUse = this.fares.client_12_0;
            break;
          case 12.5:
            fareToUse = this.fares.client_12_5;
            break;
          case 13.0:
            fareToUse = this.fares.client_13_0;
            break;
          case 13.5:
            fareToUse = this.fares.client_13_5;
            break;
          case 14.0:
            fareToUse = this.fares.client_14_0;
            break;
          case 14.5:
            fareToUse = this.fares.client_14_5;
            break;
          case 15.0:
            fareToUse = this.fares.client_15_0;
            break;
          case 15.5:
            fareToUse = this.fares.client_15_5;
            break;
          case 16.0:
            fareToUse = this.fares.client_16_0;
            break;
          case 16.5:
            fareToUse = this.fares.client_16_5;
            break;
          case 17.0:
            fareToUse = this.fares.client_17_0;
            break;
          case 17.5:
            fareToUse = this.fares.client_17_5;
            break;
          case 18.0:
            fareToUse = this.fares.client_18_0;
            break;
          case 18.5:
            fareToUse = this.fares.client_18_5;
            break;
          case 19.0:
            fareToUse = this.fares.client_19_0;
            break;
          case 19.5:
            fareToUse = this.fares.client_19_5;
            break;
          case 20.0:
            fareToUse = this.fares.client_20_0;
            break;
        }
        return Number(fareToUse);
    }
}