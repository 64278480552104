
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ServerService } from './../../services/server.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ConfirmComponent } from './../confirm/confirm.component'
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'app-user-buyer',
  templateUrl: './user-buyer.component.html',
  styleUrls: ['./user-buyer.component.scss'],
  providers: [ServerService]
})
export class UserBuyerComponent implements OnInit {

  favoriteSeason: string = "support";
  user_role: any; 

  myForm = this.fb.group({
    nombre: ['', [Validators.required, Validators.minLength(3)]],
    address:  ['', [Validators.required, Validators.minLength(3)]],
    idNumber: ['', [Validators.required, Validators.minLength(3)]],
    email: ['', [Validators.required, Validators.email]],
    phone: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(6)]],
  });

  userToShow = [];
  userToFind = new FormControl();
  users = [];
  filteredUsers: Observable<string[]>;
  userList = [];
  data: any;
  isLoading: Boolean;
  showFinder: Boolean;
   

  constructor(public _ServerService: ServerService, 
              public router: Router, 
              private fb: FormBuilder, 
              public dialog: MatDialog) {
              this.user_role = localStorage.getItem('user_role');
  }

  ngOnInit() { 
    this.toggleFinder('on');   
    this.usersList();
    console.log('El role :',this.user_role);
  }

  keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  usersList() {
    this.isLoading = true;
    this.users.length = 0;
    this._ServerService.getAllBuyers().subscribe((response) => {
      this.data = response;
      this.userList = this.data.data;
      this.userList.forEach(element => this.users.push(element.nombre));
      this.initializeAutoComplete();
      this.isLoading = false;
    });
  }

  initializeAutoComplete() {
    this.filteredUsers = this.userToFind.valueChanges.pipe(
      startWith(''),
      map(value => value.length >=1 ? this._filter(value) : [])     
    );
  }

  addUser() {
    this.isLoading= true;
    this._ServerService.createBuyer(this.myForm.value).subscribe((response) => {
      console.log(response);
      //verificar el response y ver errores en respuestas etc (manana)
      this.showNotification("top", "center", 2, 2, "El usuario comprador ha sido creado satisfactoriamente.");
      this.myForm.reset();
      this.isLoading= false;
      this.usersList();
    
    })

  }

  calculateSaldo(user: any): Number {
    let saldo = 0;
    user.movimientos.forEach(m => {
      saldo += m.amount;
    })
    return saldo;
  }

  editUser(id: any) {
    console.log(id);
    this.router.navigate([`/edit-buyer/${id}`]);
  }

  deleteUser(id: any) {
    // var dialogRef = this.dialog.open(ConfirmComponent);
    if (confirm("El usuario comprador será eliminado permanentemente.\n ¿Desea continuar?")) {
      this.isLoading= true;
      this._ServerService.deleteBuyer(id).subscribe((response) => {
        console.log(response);
        this.showNotification("top", "center", 2, 2, "El usuario comprador ha sido eliminado del sistema.");
        this.usersList();
      })
    }
  }

  filterUser() {
    let pattern = this.userToFind.value;
    let tmp = this.userList.find((m) => m.nombre === pattern);
    if (tmp) {
      this.userList.length = 0;
      this.userList.push(tmp);
      }
  }

  viewMovements(id) {
    this.router.navigate([`/view-movements/${id}`]);
  }

  viewShopping(id) {
    this.router.navigate([`/view-shopping/${id}`]);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.users.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  toggleFinder(option: string) {
    switch (option) {
      case 'on':
        this.showFinder = true;
        break;
      case 'off':
        this.showFinder = false;
        break;   
    };   
  };

  
  showNotification(from, align, col, time, message) {
    const type = ['', 'info', 'success', 'warning', 'danger'];

    // const color = Math.floor((Math.random() * 4) + 1);
    // 4 red 3 yellow 2 green 1 blue
    const color = col;

    $.notify({
      icon: 'notifications',
      message: message

    }, {
      type: type[color],
      timer: time * 1000,
      placement: {
        from: from,
        align: align
      },
      template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span class="text-center" data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }

}
