import { NgModule, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { HttpModule } from '@angular/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule} from '@angular/material/sidenav';
import { MatPaginatorModule} from '@angular/material/paginator';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatBadgeModule} from '@angular/material/badge';
import {MatRadioModule} from '@angular/material/radio';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatExpansionModule} from '@angular/material/expansion';

import { ServerService } from './../services/server.service';
import {ItemsAllowCubaService} from './../services/items-allow-cuba.service'

import { CreateSectionComponent } from './create-section/create-section.component';
import { EditSectionComponent } from './edit-section/edit-section.component';
import { AddArticleToSectionComponent } from './add-article-to-section/add-article-to-section.component';
import { AddParagraphToArticleComponent } from './add-paragraph-to-article/add-paragraph-to-article.component';
import { GetArticlesComponent } from './get-articles/get-articles.component';
import { GetParagraphsComponent } from './get-paragraphs/get-paragraphs.component';
import { EditArticleComponent } from './edit-article/edit-article.component';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './register/register.component';
import { ProfileComponent } from './profile/profile.component';
import { EditParagraphComponent } from './edit-paragraph/edit-paragraph.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

import {NgxPaginationModule} from 'ngx-pagination';
import { NgChatModule } from 'ng-chat';
import { ChatComponent } from './chat/chat.component';
import { ChatModule } from '@progress/kendo-angular-conversational-ui';
import { UserPanelComponent } from './user-panel/user-panel.component';
import { UserBuyerComponent} from './user-buyer/user-buyer.component';
import { AccountsComponent} from './accounts/accounts.component';
import {EditBuyerComponent} from './edit-buyer/edit-buyer.component';
import {EditAccountComponent} from './edit-account/edit-account.component';
import {RechargeBuyerComponent} from './recharge-buyer/recharge-buyer.component';
import {AddShoppingComponent}from './add-shopping/add-shopping.component';
import {ViewShoppingComponent} from './view-shopping/view-shopping.component';
import {EditShoppingComponent} from './edit-shopping/edit-shopping.component';
import {ViewRechargeComponent} from './view-recharge/view-recharge.component';
import {ViewAccountMovementsComponent} from './view-account-movements/view-account-movements.component';
import {AddMovementWithUserComponent} from './add-movement-with-user/add-movement-with-user.component';
import { SharedElementComponent } from './shared-element/shared-element.component';
import { createCustomElement } from '@angular/elements';
import { ClientViewComponent } from './client-view/client-view.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { SetNewPasswordComponent } from './set-new-password/set-new-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { EditarUserComponent } from './editar-user/editar-user.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { MovementsComponent } from './movements/movements.component';
import { ShoppingComponent} from './shopping/shopping.component'
import {MatDatepickerModule} from '@angular/material/datepicker';
import {AddProductComponent} from './add-product/add-product.component';
import {AddPackageComponent} from './add-package/add-package.component';
import {AddAttachmentComponent} from './add-attachment/add-attachment.component';
import {ShippingsComponent} from './shippings/shippings.component';
import {ConfigComponent} from './config/config.component';
import {AddShippingComponent} from './add-shipping/add-shipping.component';
import {AddWeekComponent} from './add-week/add-week.component';
import { BrowserModule } from '@angular/platform-browser';
import { HighlightPipe } from './pipes/highlight.pipe';

import { PackagesComponent} from './packages/packages.component';
import { PayPackageComponent} from './pay-package/pay-package.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatPaginatorModule,
    MatButtonModule,
    MatInputModule,
    MatRippleModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatSelectModule,
    MatExpansionModule,
    HttpModule,
    MatTableModule,
    NgxPaginationModule,
    MatDialogModule,
    MatCardModule,
    MatDividerModule,
    MatListModule,
    MatCheckboxModule,
    NgChatModule,
    MatAutocompleteModule,
    MatIconModule,
    MatBadgeModule,
    ChatModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    BrowserModule,
    
  ],
  declarations: [
    EditParagraphComponent,
    GetParagraphsComponent,
    AddParagraphToArticleComponent,
    EditArticleComponent,
    GetArticlesComponent,
    CreateSectionComponent,
    AddArticleToSectionComponent,
    EditSectionComponent,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    LoginComponent,
    HomeComponent,
    RegisterComponent,
    ProfileComponent,
    ChatComponent,
    UserPanelComponent,
    UserBuyerComponent,
    AccountsComponent,
    MovementsComponent,
    EditAccountComponent,
    ViewAccountMovementsComponent,
    AddMovementWithUserComponent,
    RechargeBuyerComponent,
    AddShoppingComponent,
    AddProductComponent,
    AddPackageComponent,
    AddAttachmentComponent,
    ViewShoppingComponent,
    EditShoppingComponent,
    ViewRechargeComponent,
    SharedElementComponent,
    ClientViewComponent,
    ShoppingComponent,
    PackagesComponent,
    PayPackageComponent,
    SpinnerComponent,
    SetNewPasswordComponent,
    ForgotPasswordComponent,
    EditarUserComponent,
    EditBuyerComponent,
    ConfirmComponent,
    ShippingsComponent,
    AddShippingComponent,
    AddWeekComponent,
    HighlightPipe,
    ConfigComponent,     
  ],
  entryComponents: [
    SharedElementComponent,
    ConfirmComponent
  ],
  providers: [
    ServerService,
    ItemsAllowCubaService
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent
  ]
})
export class ComponentsModule { 

  constructor(private injector: Injector){}
  ngDoBootstrap(){
    const myElement = createCustomElement(SharedElementComponent, {injector: this.injector});
    customElements.define('shared-element', myElement);
  }
}

