import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ServerService } from '../../services/server.service';
import { Router } from '@angular/router';
import { FormBuilder, FormControl } from "@angular/forms";
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

import { AddShippingComponent } from '../../components/add-shipping/add-shipping.component';
import { AddWeekComponent } from '../../components/add-week/add-week.component';
import { HideSideBarService } from '../../services/hide-side-bar.service'


import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: 'app-shippings',
  templateUrl: './shippings.component.html',
  styleUrls: ['./shippings.component.scss'],
})


export class ShippingsComponent implements OnInit {

 urlSafe: SafeResourceUrl;

  sideBarState: boolean;
  shippingToFind = new FormControl();
  filteredShippings: Observable<string[]>;
  isSearching: boolean;
  toHighlight: string = '';
  shippingArray: any;
  weekArray: any;

  dataset: any[];
  data: any;
  data1: any;
  isLoading: boolean = false;
  activeWeek: any;
  tabsNames: string[];
  config: any;
  tmp: any;

  constructor(
    public _ServerService: ServerService,
    public router: Router, private fb: FormBuilder,
    public dialog: MatDialog,
    public hideSideBarService: HideSideBarService,
    public sanitizer: DomSanitizer,

  ) {

  }



  ngOnInit() {    
    this.getConfig();      
    this.sideBarState = true;   

  }

  getConfig() {
    this.isLoading = true;
    this._ServerService.getConfig('config1').subscribe(response => {
      this.config = response;     
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.config.data.spreadSheetUrl);  
      this.getSpreadSheetTabsName();  
    
    })
  }

  hideSideBar() {
    console.log(this.sideBarState)
    this.sideBarState = !this.sideBarState;
    console.log(this.sideBarState)
    this.hideSideBarService.changeStatus(this.sideBarState);
  }

  getSpreadSheetTabsName() {
    
    this._ServerService.getSpreadSheetTabsName().subscribe((response) => {
      console.log(response);
      this.data = response;
      this.tabsNames = this.data.data;
      this.isLoading = false;
    });
  }

  initializeAutoComplete() {
    this.filteredShippings = this.shippingToFind.valueChanges.pipe(
      startWith(''),
      map(value => value.length >= 1 ? this._filterElements(value) : [])
    );
  }


  searchElement() {
    let pattern = this.shippingToFind.value;
    console.log('el pattern :', pattern);
    if (pattern !== "") {
      this.isSearching = true;
      this.dataset = this._filter(pattern);
    } else {
      this.isSearching = false;
      this.dataset = this.activeWeek.shippings;
    }

  }

  private _filterElements(value: string): string[] {
    this.toHighlight = value;
    const filterValue = value.toLowerCase();

    let senderNameList = this.shippingArray.filter(option => option.senderName != null && option.senderName.toLowerCase().includes(filterValue));
    let senderNameListSingle = [];
    senderNameList.forEach(option => senderNameListSingle.push(option.senderName))
    let senderNameFiltered = senderNameListSingle.filter((item, index) => {
      return senderNameListSingle.indexOf(item) === index;
    })

    let senderIdList = this.shippingArray.filter(option => option.senderId != null && option.senderId.toLowerCase().includes(filterValue));
    let senderIdListSingle = [];
    senderIdList.forEach(option => senderIdListSingle.push(option.senderId))
    let senderIdFiltered = senderIdListSingle.filter((item, index) => {
      return senderIdListSingle.indexOf(item) === index;
    })

    let senderAddressList = this.shippingArray.filter(option => option.senderAddress != null && option.senderAddress.toLowerCase().includes(filterValue));
    let senderAddressListSingle = [];
    senderAddressList.forEach(option => senderAddressListSingle.push(option.senderAddress))
    let senderAddressFiltered = senderAddressListSingle.filter((item, index) => {
      return senderAddressListSingle.indexOf(item) === index;
    })

    let senderEmailList = this.shippingArray.filter(option => option.senderEmail != null && option.senderEmail.toLowerCase().includes(filterValue));
    let senderEmailListSingle = [];
    senderEmailList.forEach(option => senderEmailListSingle.push(option.senderEmail))
    let senderEmailFiltered = senderEmailListSingle.filter((item, index) => {
      return senderEmailListSingle.indexOf(item) === index;
    })

    let senderPhoneList = this.shippingArray.filter(option => option.senderPhone != null && option.senderPhone.toLowerCase().includes(filterValue));
    let senderPhoneListSingle = [];
    senderPhoneList.forEach(option => senderPhoneListSingle.push(option.senderPhone))
    let senderPhoneFiltered = senderPhoneListSingle.filter((item, index) => {
      return senderPhoneListSingle.indexOf(item) === index;
    })

    let receptorNameList = this.shippingArray.filter(option => option.receptorName != null && option.receptorName.toLowerCase().includes(filterValue));
    let receptorNameListSingle = [];
    receptorNameList.forEach(option => receptorNameListSingle.push(option.receptorName))
    let receptorNameFiltered = receptorNameListSingle.filter((item, index) => {
      return receptorNameListSingle.indexOf(item) === index;
    })

    let receptorIdList = this.shippingArray.filter(option => option.receptorId != null && option.receptorId.toLowerCase().includes(filterValue));
    let receptorIdListSingle = [];
    receptorIdList.forEach(option => receptorIdListSingle.push(option.receptorId))
    let receptorIdFiltered = receptorIdListSingle.filter((item, index) => {
      return receptorIdListSingle.indexOf(item) === index;
    })

    let receptorAddressList = this.shippingArray.filter(option => option.receptorAddress != null && option.receptorAddress.toLowerCase().includes(filterValue));
    let receptorAddressListSingle = [];
    receptorAddressList.forEach(option => receptorAddressListSingle.push(option.receptorAddress))
    let receptorAddressFiltered = receptorAddressListSingle.filter((item, index) => {
      return receptorAddressListSingle.indexOf(item) === index;
    })

    let receptorPhoneList = this.shippingArray.filter(option => option.receptorPhone != null && option.receptorPhone.toLowerCase().includes(filterValue));
    let receptorPhoneListSingle = [];
    receptorPhoneList.forEach(option => receptorPhoneListSingle.push(option.receptorPhone))
    let receptorPhoneFiltered = receptorPhoneListSingle.filter((item, index) => {
      return receptorPhoneListSingle.indexOf(item) === index;
    })


    return senderNameFiltered
      .concat(senderIdFiltered)
      .concat(senderAddressFiltered)
      .concat(senderEmailFiltered)
      .concat(senderPhoneFiltered)
      .concat(receptorNameFiltered)
      .concat(receptorIdFiltered)
      .concat(receptorAddressFiltered)
      .concat(receptorPhoneFiltered)

  }



  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    let senderName = this.shippingArray.filter(option => option.senderName != null && option.senderName.toLowerCase().includes(filterValue));
    let senderId = this.shippingArray.filter(option => option.senderId != null && option.senderId.toLowerCase().includes(filterValue));
    let senderAddress = this.shippingArray.filter(option => option.senderAddress != null && option.senderAddress.toLowerCase().includes(filterValue));
    let senderEmail = this.shippingArray.filter(option => option.senderEmail != null && option.senderEmail.toLowerCase().includes(filterValue));
    let senderPhone = this.shippingArray.filter(option => option.senderPhone != null && option.senderPhone.toLowerCase().includes(filterValue));
    let receptorName = this.shippingArray.filter(option => option.receptorName != null && option.receptorName.toLowerCase().includes(filterValue));
    let receptorId = this.shippingArray.filter(option => option.receptorId != null && option.receptorId.toLowerCase().includes(filterValue));
    let receptorAddress = this.shippingArray.filter(option => option.receptorAddress != null && option.receptorAddress.toLowerCase().includes(filterValue));
    let receptorPhone = this.shippingArray.filter(option => option.receptorPhone != null && option.receptorPhone.toLowerCase().includes(filterValue));

    return senderName
      .concat(senderId)
      .concat(senderAddress)
      .concat(senderEmail)
      .concat(senderPhone)
      .concat(receptorName)
      .concat(receptorId)
      .concat(receptorAddress)
      .concat(receptorPhone)
  }


  createNewShipping() {
    if(this.isLoading) return;    
    var dialogRefPackage = this.dialog.open(AddShippingComponent, {
      width: "1020px",
      height: "720px",      
      data: this.tabsNames
    });
    dialogRefPackage.afterClosed().subscribe((result) => {
      if (result) {
        this.isLoading = true;
        console.log("Lo obtenido del form", result);
        this._ServerService.addShipping(result).subscribe((response) => {
          console.log(response);
          this.isLoading = false;
          this.showNotification("top", "center", 2, 1, "Envio añadido satisfactoriamente.");
        })

      };
    })
  }

  createNewWeek() {
    var dialogRefPackage = this.dialog.open(AddWeekComponent, {
      width: "1020px",
      height: "500px",
    });
    dialogRefPackage.afterClosed().subscribe((result) => {
      if (result) {
        console.log("el resultado", result);         
        this._ServerService.addWeek(result).subscribe((response) => {
          console.log(response);
          this.showNotification("top", "center", 2, 2, "La semana ha sido añadida correctamente.");
        })
      }
    });
  }



  resetList() {
    this.shippingToFind.setValue('');
  }



  showNotification(from, align, col, time, message) {
    const type = ['', 'info', 'success', 'warning', 'danger'];

    // const color = Math.floor((Math.random() * 4) + 1);
    // 4 red 3 yellow 2 green 1 blue
    const color = col;

    $.notify({
      icon: 'notifications',
      message: message

    }, {
      type: type[color],
      timer: time * 1000,
      placement: {
        from: from,
        align: align
      },
      template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span class="text-center" data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }

}
