import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';


declare var $: any;


@Component({
  selector: 'app-attachment',
  templateUrl: './add-attachment.component.html',
  styleUrls: ['./add-attachment.component.scss'],
})
export class AddAttachmentComponent implements OnInit {

  myForm = this.fb.group({
    name: ['', [Validators.required]],
    description: ['', [Validators.required]], 
    file:  ['', [Validators.required]],
     
  });

  
    constructor(private fb: FormBuilder,
                private dialogRef: MatDialogRef<AddAttachmentComponent>) {
                }

  ngOnInit() {    
    
  }

 
  addAttachment() {    
    this.dialogRef.close(this.myForm.value);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onFileChange(event, index?) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      console.log('El result :', event.target.result);
      console.log('El file :', event.target.files);
      const fileData = event.target.files;
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        console.log('Fichero', reader.result);          
          this.myForm.patchValue({
            file: reader.result,
            name: fileData[0].name
          });       
      };
    }
  }

  showNotification(from, align, col, time, message) {
    const type = ['', 'info', 'success', 'warning', 'danger'];

    // const color = Math.floor((Math.random() * 4) + 1);
    // 4 red 3 yellow 2 green 1 blue
    const color = col;

    $.notify({
      icon: 'notifications',
      message: message

    }, {
      type: type[color],
      timer: time * 1000,
      placement: {
        from: from,
        align: align
      },
      template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span class="text-center" data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }


}
