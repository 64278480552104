import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  data: any;
  // readonly url = 'http://localhost:3000/api/authentication/';
  readonly url = environment.baseURL + 'authentication/';

  constructor(public http: HttpClient, private router: Router) { }

  login(username: string, password: string) {
    return this.http
      .post(this.url + 'login',
        {
          username,
          password
        });
  }

  logout(): any {
    localStorage.removeItem('username');
    localStorage.removeItem('registration_token');
    localStorage.removeItem('user_role');
    localStorage.removeItem('user_id');

  }

  register(telephone: string, country: string, email: string, username: string, password: string) {
    return this.http.post(this.url + 'register',
      {
        username,
        country,
        password,
        email,
        telephone
      }
    );
  }

  forgotPassword(forgotForm: any) {
    return this.http.post(this.url + 'forgot', forgotForm);
  }

  resetPassword(resetForm: any ) {
    return this.http.post(this.url + 'reset', resetForm);
  }

  getUsername(): any {
    return localStorage.getItem('username');
  }

  getUserID(): any {
    return localStorage.getItem('user_id');
  }

  getRole(): any {
    return localStorage.getItem('user_role');
  }

  isLoggedIn(): boolean {
    return this.getUsername() != null;
  }

  isLoggedAgency(): boolean {
    return this.getUsername() !== null && this.getRole() === 'agency';
  }

  isLoggedClient(): boolean {
    return this.getUsername() !== null && this.getRole() === 'client';
  }

}

export const AUTH_PROVIDERS: Array<any> = [
  { provide: AuthService, useClass: AuthService }
];
