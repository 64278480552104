import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ServerService } from './../../services/server.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
declare var $: any;

@Component({
  selector: 'app-edit-shopping',
  templateUrl: './edit-shopping.component.html',
  styleUrls: ['./edit-shopping.component.scss'],
})
export class EditShoppingComponent implements OnInit {

  myForm = this.fb.group({
    link: ['', [Validators.required]],
    precio: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    description: [''],
    comprador: ['', [Validators.required]],
    
  });

  data:any;
  isLoading: boolean;

  constructor(public server: ServerService, public router: Router, private fb: FormBuilder, public activatedRoute: ActivatedRoute) { 
    
  }

  ngOnInit() {
    this.getShopping();
  }

  getShopping(id?){
    this.isLoading = true;
    this.server.getOneShopping(this.activatedRoute.snapshot.params.id)
      .subscribe(response => {
        this.data = response;
        this.myForm.patchValue({
          link: this.data.data.link,
          precio: this.data.data.precio,
          description: this.data.data.description,
          comprador: this.data.data.comprador,      
        });
        this.isLoading = false;
      });
  }

getBack() {  
  let backId = localStorage.getItem('backId');
  this.router.navigate([`view-shopping/${backId}`]); 
}

  editShopping() {
    this.isLoading = true;
    let id= this.activatedRoute.snapshot.params.id
    this.server.editShopping(id,this.myForm.value).subscribe((response) => {
      this.showNotification("top", "center", 2, 2, "La compra ha sido editada satisfactoriamente.");
      this.myForm.reset();
      this.isLoading = false;
      this.getBack();
    })   

  }

  showNotification(from, align, col, time, message) {
    const type = ['', 'info', 'success', 'warning', 'danger'];

    // const color = Math.floor((Math.random() * 4) + 1);
    // 4 red 3 yellow 2 green 1 blue
    const color = col;

    $.notify({
      icon: 'notifications',
      message: message

    }, {
      type: type[color],
      timer: time * 1000,
      placement: {
        from: from,
        align: align
      },
      template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span class="text-center" data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }

}
