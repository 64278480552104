import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ServerService } from './../../services/server.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ConfirmComponent } from './../confirm/confirm.component'
import { environment } from './../../../environments/environment';

declare var $: any;

class AccountSummary {
  name: string;
  total: number;
  entradas: number;
  salidas: number;
  totalEuros: number;
  entradasEuros: number;
  salidasEuros: number;

  constructor(
    name: string
    , total: number
    , entradas: number
    , salidas: number
    , totalEuros: number
    , entradasEuros: number
    , salidasEuros: number
  ) {
    this.name = name;
    this.total = total;
    this.entradas = entradas;
    this.salidas = salidas;

    this.totalEuros = totalEuros;
    this.entradasEuros = entradasEuros;
    this.salidasEuros = salidasEuros;
  }
}

@Component({
  selector: 'app-view-account-movements',
  templateUrl: './view-account-movements.component.html',
  styleUrls: ['./view-account-movements.component.scss'],
})


export class ViewAccountMovementsComponent implements OnInit {

  favoriteSeason: string = "support";
  user_role: any;
  isLoading: boolean;

  myForm = this.fb.group({
    description: [''],    
    amountgt: [''],
    amountlt: [''],
    dategt: [''],
    datelt: [''],
  });

  public accountsSummary: AccountSummary[] = [];
  public summaryTotal: AccountSummary = new AccountSummary("Total", 0,0,0, 0, 0, 0);
  private _movementList: any[];
  public get movementList() {
    return this._movementList;
  }

  public set movementList(data) {
    this._movementList = data;
    this.buildAccountSummary();
  };
  data: any;
  account: any;

  constructor(public _ServerService: ServerService, public router: Router, private fb: FormBuilder, public dialog: MatDialog,
    public activatedRoute: ActivatedRoute) {
    this.user_role = localStorage.getItem('user_role');

  }

  ngOnInit() {
    this.getAccount();
    console.log(this.user_role)
  }

  private buildAccountSummary(){
    this.accountsSummary = [];
    this.movementList.forEach(movement => {
    
      let currentAccount = this.accountsSummary
        .find(x => 
          x.name == movement.account.name
        );
      const account = currentAccount
          ? currentAccount
          : this.buildAccountAndAddToArray(movement.account.name);
      account.total += movement.amount;
      account.totalEuros += movement.amount / movement.exchangeRate
      account.entradas += movement.amount > 0 
        ? movement.amount 
        : 0
      account.entradasEuros +=movement.amount > 0 
        ? movement.amount / movement.exchangeRate
        : 0
      account.salidas += movement.amount < 0 
        ? movement.amount 
        : 0
      account.salidasEuros += movement.amount < 0 
        ? movement.amount / movement.exchangeRate
        : 0

    })
    const total = this.accountsSummary.length > 0 ? this.accountsSummary
      .map(x => x.total)
      .reduce( (x,y) => x + y) : 0;
    const entradas = this.accountsSummary.length > 0 ? this.accountsSummary
      .map(x => x.entradas)
      .reduce( (x,y) => x + y) : 0;
    const salidas = this.accountsSummary.length > 0 ? this.accountsSummary
      .map(x => x.salidas)
      .reduce( (x,y) => x + y) : 0;


      const totalEuros = this.accountsSummary.length > 0 ? this.accountsSummary
      .map(x => x.totalEuros)
      .reduce( (x,y) => x + y) : 0;
    const entradasEuros = this.accountsSummary.length > 0 ? this.accountsSummary
      .map(x => x.entradasEuros)
      .reduce( (x,y) => x + y) : 0;
    const salidasEuros = this.accountsSummary.length > 0 ? this.accountsSummary
      .map(x => x.salidasEuros)
      .reduce( (x,y) => x + y) : 0;

    this.summaryTotal = new AccountSummary(
      "Total"
      , total
      , entradas
      , salidas
      , totalEuros
      , entradasEuros
      , salidasEuros
    )
  }

  getAmountEuro(movement):Number{
      return movement.amount / movement.exchangeRate;
  }

  private buildAccountAndAddToArray(name: string): AccountSummary {
    let account = new AccountSummary(
      name,
      0,
      0,
      0,
      0,
      0,
      0
    )
    this.accountsSummary.push(account)
    return account;
  }

  getAccount(id?) {
    this.isLoading= true;
    this._ServerService.getOneAccount(this.activatedRoute.snapshot.params.id).subscribe(response => {
      this.account = response['data'];
    })
    this.isLoading = false;
    this.filterMovements()
  }

  filterMovements(){
    console.log (this.myForm.value);
    this.isLoading = true;
    this._ServerService.getMovementsByAccount(this.activatedRoute.snapshot.params.id, this.myForm.value).subscribe((response) => {
      this.movementList = response['data'];
      this.movementList.sort((a, b) => Date.parse(b.date) -  Date.parse(a.date));
      console.log(response)
      this.isLoading = false;
    })   
  }

  deleteMovement(id) {
    if (confirm("El movimiento será eliminado permanentemente.\n ¿Desea continuar?")) {
      this.isLoading = true;
      this._ServerService.deleteMovement(id).subscribe((response) => {
        console.log(response);
        this.showNotification("top", "center", 2, 2, "El movimiento ha sido eliminado satisfactoriamente.");
        this.isLoading = false;
        this.getAccount();
      })
    }
  }
  
  verifyMovement(id) {
    this.isLoading = true;
    this._ServerService.veryfyMovement(id, { status: "verified" }).subscribe((response) => {
      console.log(response);
      this.getAccount();
    })
  }

  getBack(){
    this.router.navigate([`/accounts`]);
  }

  
    addMovement() {
      let id= this.activatedRoute.snapshot.params.id
      localStorage.setItem('backId-2', id);
      this.router.navigate([`/add-movement-with-user/${id}`]);
    }



  showNotification(from, align, col, time, message) {
    const type = ['', 'info', 'success', 'warning', 'danger'];

    // const color = Math.floor((Math.random() * 4) + 1);
    // 4 red 3 yellow 2 green 1 blue
    const color = col;

    $.notify({
      icon: 'notifications',
      message: message

    }, {
      type: type[color],
      timer: time * 1000,
      placement: {
        from: from,
        align: align
      },
      template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span class="text-center" data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }
  

  

  
  

  

}
