import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { environment } from './../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UploadFilesService {

  readonly url = environment.baseURL;

  constructor(private httpClient: HttpClient) { }

  uploadFile(file: File, dir: string): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();    
    formData.append('file', file);
    formData.append('dir', dir);

    const request = new HttpRequest('POST', `${this.url}files/`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.httpClient.request(request);
  }

  getFiles(dir:string): Observable<any> {
    return this.httpClient.get(`${this.url}files/${dir}`);
  }

  deleteFiles(fileName:string, dir:string): Observable<any> {
    return this.httpClient.get(`${this.url}files/delete/${dir}/${fileName}`);
  }
  
}
