import { Component, OnInit } from '@angular/core';
import { ServerService } from '../../services/server.service';
import { FormBuilder } from "@angular/forms";
import { Validators } from '@angular/forms';

declare var $: any;
@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss'],
})


export class ConfigComponent implements OnInit {
  data: any;
  fares: any;
  sheetConfig: any;
  isLoading: boolean;
  myForm = this.fb.group({
    name: [""],
    price_cup_custom_cuba: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    price_cup_remittance_calculation: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    price_cup_collaborators_cuba: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    price_cup_payment_shopping: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    price_mlc: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    habana_Base_1_5: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    habana_Base_2_0: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    habana_Base_2_5: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    habana_Base_3_0: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    habana_Base_3_5: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    habana_Base_4_0: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    habana_Base_4_5: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    habana_Base_5_0: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    habana_Base_5_5: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    habana_Base_6_0: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    habana_Base_6_5: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    habana_Base_7_0: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    habana_Base_7_5: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    habana_Base_8_0: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    habana_Base_8_5: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    habana_Base_9_0: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    habana_Base_9_5: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    habana_Base_10_0: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    habana_Base_10_5: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    fare_per_kilo: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_1_5: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_2_0: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_2_5: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_3_0: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_3_5: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_4_0: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_4_5: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_5_0: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_5_5: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_6_0: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_6_5: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_7_0: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_7_5: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_8_0: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_8_5: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_9_0: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_9_5: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_10_0: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_10_5: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_11_0: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_11_5: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_12_0: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_12_5: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_13_0: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_13_5: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_14_0: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_14_5: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_15_0: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_15_5: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_16_0: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_16_5: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_17_0: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_17_5: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_18_0: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_18_5: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_19_0: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_19_5: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    client_20_0: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    home_pickup_service: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],    
    spreadSheetUrl: ['', [Validators.required]],
    descriptionColumn: ['', [Validators.required]],
    descriptionColumnTitle: ['', [Validators.required]],
    detailsColumn: ['', [Validators.required]],
    detailsColumnTitle: ['', [Validators.required]],
    senderNameColumn: ['', [Validators.required]],
    senderNameColumnTitle: ['', [Validators.required]],
    senderIdColumn: ['', [Validators.required]],
    senderIdColumnTitle: ['', [Validators.required]],
    senderAddressColumn: ['', [Validators.required]],
    senderAddressColumnTitle: ['', [Validators.required]],
    senderEmailColumn: ['', [Validators.required]],
    senderEmailColumnTitle: ['', [Validators.required]],
    senderPhoneColumn: ['', [Validators.required]],
    senderPhoneColumnTitle: ['', [Validators.required]],
    receptorNameColumn: ['', [Validators.required]],
    receptorNameColumnTitle: ['', [Validators.required]],
    receptorIdColumn: ['', [Validators.required]],
    receptorIdColumnTitle: ['', [Validators.required]],
    receptorAddressColumn: ['', [Validators.required]],
    receptorAddressColumnTitle: ['', [Validators.required]],
    provinceColumn: ['', [Validators.required]],
    provinceColumnTitle: ['', [Validators.required]],
    receptorPhoneColumn: ['', [Validators.required]],
    receptorPhoneColumnTitle: ['', [Validators.required]],
    customsDutyTypeColumn: ['', [Validators.required]],
    customsDutyTypeColumnTitle: ['', [Validators.required]],
    shippingTypeColumn: ['', [Validators.required]],
    shippingTypeColumnTitle: ['', [Validators.required]],
    packageQuantityColumn: ['', [Validators.required]],
    packageQuantityColumnTitle: ['', [Validators.required]],
    weightToChargeColumn: ['', [Validators.required]],
    weightToChargeColumnTitle: ['', [Validators.required]],
    realWeightColumn: ['', [Validators.required]],
    realWeightColumnTitle: ['', [Validators.required]],
    homePickupColumn: ['', [Validators.required]],
    homePickupColumnTitle: ['', [Validators.required]],
    shoppingAndOthersColumn: ['', [Validators.required]],
    shoppingAndOthersColumnTitle: ['', [Validators.required]],
    packingColumn: ['', [Validators.required]],
    packingColumnTitle: ['', [Validators.required]],
    enviarCorreoFormula: ['', [Validators.required]],
    aerovarFormula: ['', [Validators.required]],
    totalFormula: ['', [Validators.required]],
    precioVentaFormula: ['', [Validators.required]],
    servicioRecogidaFormula: ['', [Validators.required]],
    impuestoAduanaFormula: ['', [Validators.required]],
    envioProvinciaFormula: ['', [Validators.required]],
    pagadorFormula: ['', [Validators.required]],
    transporteFormula: ['', [Validators.required]],
    extrasArancelesFormula: ['', [Validators.required]],
    totalPagarFormula: ['', [Validators.required]],
    informeResumidoFormula: ['', [Validators.required]],
    informeDetallesFormula: ['', [Validators.required]],
    informeBancoFormula: ['', [Validators.required]],
    etiquetaEnvioRealFormula: ['', [Validators.required]],
    etiquetaEnvioColaboradoresFormula: ['', [Validators.required]],
    informeColaboradorFormula: ['', [Validators.required]],
    informePagoFormula: ['', [Validators.required]],
    imprimirFacturaFormula: ['', [Validators.required]],
    
  });


  constructor(
    public _ServerService: ServerService,
    private fb: FormBuilder,) { }

  ngOnInit() {
    this.getConfig();



  }

  getConfig() {
    this.isLoading = true;
    this._ServerService.getConfig('config1').subscribe(response => {
      this.data = response;
      this.fares = this.data.data;
      this.sheetConfig = this.data.data;
      this.updateForm();
      console.log(this.fares)
      console.log('El form updated :', this.myForm.value)
      this.isLoading = false;
    })
  }

  updateForm() {
    this.myForm.patchValue({
      name: this.fares.name,
      price_cup_custom_cuba: this.fares.price_cup_custom_cuba,
      price_cup_remittance_calculation: this.fares.price_cup_remittance_calculation,
      price_cup_collaborators_cuba: this.fares.price_cup_collaborators_cuba,
      price_cup_payment_shopping: this.fares.price_cup_payment_shopping,
      price_mlc: this.fares.price_mlc,
      habana_Base_1_5: this.fares.habana_Base_1_5,
      habana_Base_2_0: this.fares.habana_Base_2_0,
      habana_Base_2_5: this.fares.habana_Base_2_5,
      habana_Base_3_0: this.fares.habana_Base_3_0,
      habana_Base_3_5: this.fares.habana_Base_3_5,
      habana_Base_4_0: this.fares.habana_Base_4_0,
      habana_Base_4_5: this.fares.habana_Base_4_5,
      habana_Base_5_0: this.fares.habana_Base_5_0,
      habana_Base_5_5: this.fares.habana_Base_5_5,
      habana_Base_6_0: this.fares.habana_Base_6_0,
      habana_Base_6_5: this.fares.habana_Base_6_5,
      habana_Base_7_0: this.fares.habana_Base_7_0,
      habana_Base_7_5: this.fares.habana_Base_7_5,
      habana_Base_8_0: this.fares.habana_Base_8_0,
      habana_Base_8_5: this.fares.habana_Base_8_5,
      habana_Base_9_0: this.fares.habana_Base_9_0,
      habana_Base_9_5: this.fares.habana_Base_9_5,
      habana_Base_10_0: this.fares.habana_Base_10_0,
      habana_Base_10_5: this.fares.habana_Base_10_5,
      fare_per_kilo: this.fares.fare_per_kilo,
      client_1_5: this.fares.client_1_5,
      client_2_0: this.fares.client_2_0,
      client_2_5: this.fares.client_2_5,
      client_3_0: this.fares.client_3_0,
      client_3_5: this.fares.client_3_5,
      client_4_0: this.fares.client_4_0,
      client_4_5: this.fares.client_4_5,
      client_5_0: this.fares.client_5_0,
      client_5_5: this.fares.client_5_5,
      client_6_0: this.fares.client_6_0,
      client_6_5: this.fares.client_6_5,
      client_7_0: this.fares.client_7_0,
      client_7_5: this.fares.client_7_5,
      client_8_0: this.fares.client_8_0,
      client_8_5: this.fares.client_8_5,
      client_9_0: this.fares.client_9_0,
      client_9_5: this.fares.client_9_5,
      client_10_0: this.fares.client_10_0,
      client_10_5: this.fares.client_10_5,
      client_11_0: this.fares.client_11_0,
      client_11_5: this.fares.client_11_5,
      client_12_0: this.fares.client_12_0,
      client_12_5: this.fares.client_12_5,
      client_13_0: this.fares.client_13_0,
      client_13_5: this.fares.client_13_5,
      client_14_0: this.fares.client_14_0,
      client_14_5: this.fares.client_14_5,
      client_15_0: this.fares.client_15_0,
      client_15_5: this.fares.client_15_5,
      client_16_0: this.fares.client_16_0,
      client_16_5: this.fares.client_16_5,
      client_17_0: this.fares.client_17_0,
      client_17_5: this.fares.client_17_5,
      client_18_0: this.fares.client_18_0,
      client_18_5: this.fares.client_18_5,
      client_19_0: this.fares.client_19_0,
      client_19_5: this.fares.client_19_5,
      client_20_0: this.fares.client_20_0,
      home_pickup_service: this.fares.home_pickup_service,      
      spreadSheetUrl: this.sheetConfig.spreadSheetUrl,
      descriptionColumn: this.sheetConfig.descriptionColumn,
      descriptionColumnTitle: this.sheetConfig.descriptionColumnTitle,
      detailsColumn: this.sheetConfig.detailsColumn,
      detailsColumnTitle: this.sheetConfig.detailsColumnTitle,
      senderNameColumn: this.sheetConfig.senderNameColumn,
      senderNameColumnTitle: this.sheetConfig.senderNameColumnTitle,
      senderIdColumn: this.sheetConfig.senderIdColumn,
      senderIdColumnTitle: this.sheetConfig.senderIdColumnTitle,
      senderAddressColumn: this.sheetConfig.senderAddressColumn,
      senderAddressColumnTitle: this.sheetConfig.senderAddressColumnTitle,
      senderEmailColumn: this.sheetConfig.senderEmailColumn,
      senderEmailColumnTitle: this.sheetConfig.senderEmailColumnTitle,
      senderPhoneColumn: this.sheetConfig.senderPhoneColumn,
      senderPhoneColumnTitle: this.sheetConfig.senderPhoneColumnTitle,
      receptorNameColumn: this.sheetConfig.receptorNameColumn,
      receptorNameColumnTitle: this.sheetConfig.receptorNameColumnTitle,
      receptorIdColumn: this.sheetConfig.receptorIdColumn,
      receptorIdColumnTitle: this.sheetConfig.receptorIdColumnTitle,
      receptorAddressColumn: this.sheetConfig.receptorAddressColumn,
      receptorAddressColumnTitle: this.sheetConfig.receptorAddressColumnTitle,
      provinceColumn: this.sheetConfig.provinceColumn,
      provinceColumnTitle: this.sheetConfig.provinceColumnTitle,
      receptorPhoneColumn: this.sheetConfig.receptorPhoneColumn,
      receptorPhoneColumnTitle: this.sheetConfig.receptorPhoneColumnTitle,
      customsDutyTypeColumn: this.sheetConfig.customsDutyTypeColumn,
      customsDutyTypeColumnTitle: this.sheetConfig.customsDutyTypeColumnTitle, 
      shippingTypeColumn: this.sheetConfig.shippingTypeColumn,
      shippingTypeColumnTitle: this.sheetConfig.shippingTypeColumnTitle,
      packageQuantityColumn: this.sheetConfig.packageQuantityColumn,
      packageQuantityColumnTitle: this.sheetConfig.packageQuantityColumnTitle,
      weightToChargeColumn: this.sheetConfig.weightToChargeColumn,
      weightToChargeColumnTitle: this.sheetConfig.weightToChargeColumnTitle,
      realWeightColumn: this.sheetConfig.realWeightColumn,
      realWeightColumnTitle: this.sheetConfig.realWeightColumnTitle,
      homePickupColumn: this.sheetConfig.homePickupColumn,
      homePickupColumnTitle: this.sheetConfig.homePickupColumnTitle,
      shoppingAndOthersColumn: this.sheetConfig.shoppingAndOthersColumn,
      shoppingAndOthersColumnTitle: this.sheetConfig.shoppingAndOthersColumnTitle,
      packingColumn: this.sheetConfig.packingColumn,
      packingColumnTitle: this.sheetConfig.packingColumnTitle,
      enviarCorreoFormula: this.sheetConfig.enviarCorreoFormula,
      aerovarFormula: this.sheetConfig.aerovarFormula,
      totalFormula: this.sheetConfig.totalFormula,
      precioVentaFormula: this.sheetConfig.precioVentaFormula,
      servicioRecogidaFormula: this.sheetConfig.servicioRecogidaFormula,
      impuestoAduanaFormula: this.sheetConfig.impuestoAduanaFormula,
      envioProvinciaFormula: this.sheetConfig.envioProvinciaFormula,
      pagadorFormula: this.sheetConfig.pagadorFormula,
      transporteFormula: this.sheetConfig.transporteFormula,
      extrasArancelesFormula: this.sheetConfig.extrasArancelesFormula,
      totalPagarFormula: this.sheetConfig.totalPagarFormula,
      informeResumidoFormula: this.sheetConfig.informeResumidoFormula,
      informeDetallesFormula: this.sheetConfig.informeDetallesFormula,
      informeBancoFormula: this.sheetConfig.informeBancoFormula,
      etiquetaEnvioRealFormula: this.sheetConfig.etiquetaEnvioRealFormula,
      etiquetaEnvioColaboradoresFormula: this.sheetConfig.etiquetaEnvioColaboradoresFormula,
      informeColaboradorFormula: this.sheetConfig.informeColaboradorFormula,
      informePagoFormula: this.sheetConfig.informePagoFormula,
      imprimirFacturaFormula: this.sheetConfig.imprimirFacturaFormula,

    });
  }

  editConfig() {
    console.log('el form del edit :', this.myForm.value)
    this._ServerService.editConfig(this.fares._id, this.myForm.value).subscribe(response => {
      this.showNotification("top", "center", 2, 2, "La configuracion ha sido actualizada");
      this.getConfig();
    })
  }

  showNotification(from, align, col, time, message) {
    const type = ['', 'info', 'success', 'warning', 'danger'];

    // const color = Math.floor((Math.random() * 4) + 1);
    // 4 red 3 yellow 2 green 1 blue
    const color = col;

    $.notify({
      icon: 'notifications',
      message: message

    }, {
      type: type[color],
      timer: time * 1000,
      placement: {
        from: from,
        align: align
      },
      template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span class="text-center" data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }

}
