import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServerService } from '../../services/server.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ConfirmComponent } from '../confirm/confirm.component'
import { MatDatepickerModule } from '@angular/material/datepicker';
import { environment } from '../../../environments/environment';
import { Provinces } from '../models/provinces.models';
import { Descriptions } from '../models/descriptions.models';
import { Agents } from '../models/agents.models';
import { CustomDuty } from '../models/customDuty.models';
import { ShippingType } from '../models/shippingType.models';
import { Status } from '../models/status.models';
import * as XLSX from 'xlsx';
import { element } from 'protractor';

declare var $: any;


@Component({
  selector: 'app-add-week',
  templateUrl: './add-week.component.html',
  styleUrls: ['./add-week.component.scss'],
})


export class AddWeekComponent implements OnInit {

  favoriteSeason: string = "support";
  user_role: any;


  myForm = this.fb.group({
    name: ["", [Validators.required]],
    description: [''],
    begining: [Date, [Validators.required]],
    end: [Date, [Validators.required]],
  });



  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public _ServerService: ServerService,
    public router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public activatedRoute: ActivatedRoute,
    private dialogRef: MatDialogRef<AddWeekComponent>) {
    this.user_role = localStorage.getItem('user_role');

  }


  ngOnInit() {

  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  addWeek() {
    this.dialogRef.close(this.myForm.value);
  }

}
