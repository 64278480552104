import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServerService } from '../../services/server.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Provinces } from '../../components/models/provinces.models';
import { Descriptions } from '../../components/models/descriptions.models';
import { Agents } from '../../components/models/agents.models';
import { CustomDuty } from '../../components/models/customDuty.models';
import { ShippingType } from '../../components/models/shippingType.models';
import { Status } from '../../components/models/status.models';
import * as XLSX from 'xlsx';
import { ShippingAutoComplete } from './helpers/add-shipping-autocomplete';
import { TarifHelper } from './helpers/tarif-helper';
import { CalculatorHelper } from './helpers/calculator-helper';
import { MatAutocompleteTrigger} from '@angular/material/autocomplete'


declare var $: any;
type AOA = any[][];

@Component({
  selector: 'app-add-shipping',
  templateUrl: './add-shipping.component.html',
  styleUrls: ['./add-shipping.component.scss'],
})



export class AddShippingComponent implements OnInit {

  @ViewChild('autoCompleteInput1', { read: MatAutocompleteTrigger })
  autoComplete1: MatAutocompleteTrigger;

  @ViewChild('autoCompleteInput2', { read: MatAutocompleteTrigger })
  autoComplete2: MatAutocompleteTrigger;

  config: any;
  fares: any
  descriptions = Descriptions;
  private tarifHelper: TarifHelper;
  private calculator: CalculatorHelper;
  provinces = Provinces;
  agentNameArray = Agents;
  customsDutyTypeArray = CustomDuty;
  shippingTypeArray = ShippingType;
  statusArray = Status;
  weeks: string[];
  

  myForm = this.fb.group({
    week: ["", [Validators.required]],
    description: [''],
    details: [''],   
    senderName: ['', [Validators.required]],
    senderId: [''],
    senderAddress: [''],
    senderEmail: ["", [Validators.email]],
    senderPhone: [''],
    receptorName: ['', [Validators.required]],
    receptorId: [''],
    receptorAddress: [''],
    province: [""],
    receptorPhone: [''],
    customsDutyType: ["", [Validators.required]],
    shippingType: ["", [Validators.required]],
    packageQuantity: [Number, [Validators.required, Validators.min(1), Validators.pattern("^[0-9]*$")]],
    volumetricWeight: [Number, [Validators.pattern("^[.0-9]*$")]],
    length: [Number, [Validators.pattern("^[.0-9]*$")]],
    width: [Number, [Validators.pattern("^[.0-9]*$")]],
    height: [Number, [Validators.pattern("^[.0-9]*$")]],
    realWeight: [Number, [Validators.required, Validators.pattern("^[.0-9]*$")]],
    weightToCharge: [Number, [Validators.required, Validators.min(1), Validators.pattern("^[.0-9]*$")]],
    homePickup: [Number, [Validators.pattern("^[.0-9]*$")]],
    shoppingAndOthers: [Number, [Validators.pattern("^[.0-9]*$")]],
    packing: [Number, [Validators.pattern("^[.0-9]*$")]],
    
  });

 

  private _description: String;

  public get description(): String {
    return this._description
  }

  public set description(value: String) {
    this._description = value;
    if (this.details == "" || this.details == undefined) {
      this.details = value
    }
  }

  public details: String;

  isLoading: boolean;
  objInfo: any;

  private shippingAutocomplete;
  toHighlight: string;
  senderNames;
  receptorNames;  
  senderNameToFind;
  receptorNameToFind;
  filteredSenderNames;
  filteredReceptorNames;
  textToShow1; 
  textToShow2;
  _filter;
  patchSenderId;
  patchSenderAddress;
  patchSenderEmail;
  patchSenderPhone;
  patchReceptorId;
  patchReceptorAddress;
  patchReceptorPhone;
  patchReceptorUsers;
  senderIdArray; 
  senderAddressArray; 
  senderEmailArray;
  senderPhoneArray;
  receptorIdArray; 
  receptorAddressArray;  
  receptorPhoneArray;
  senderIdMultiple;   
  senderAddressMultiple;
  senderEmailMultiple;
  senderPhoneMultiple;
  receptorIdMultiple;   
  receptorAddressMultiple;  
  receptorPhoneMultiple;
  receptorUserMultiple;
  receptorNameArray;
  senderNameArray;
  getReceptorsLinkedToSender;
  getSendersLinkedToReceptor;
  senderSelected;
  receptorSelected;
  otherReceptorName;
  otherSenderName;
  otherReceptorAddress;
  receptorAddressObject;
  resetReceptor;
  resetSender;  
  receptorAutocompleted;
  isLoadingSenders;
  isLoadingReceptors;

  public initAutocompleteVariable() {
    this.senderNameToFind = this.shippingAutocomplete.senderNameToFind
    this.receptorNameToFind = this.shippingAutocomplete.receptorNameToFind
    this.filteredSenderNames = this.shippingAutocomplete.filteredSenderNames
    this.filteredReceptorNames = this.shippingAutocomplete.filteredReceptorNames
    this.textToShow1 = this.shippingAutocomplete.textToShow1    
    this.textToShow2 = this.shippingAutocomplete.textToShow2
    this.senderNames = this.shippingAutocomplete.senderNames;
    this.receptorNames = this.shippingAutocomplete.receptorNames;
    this.toHighlight = this.shippingAutocomplete.toHighlight;
    this.getSenderNames = this.shippingAutocomplete.getSenderNames;    
    this.searchSenderName = this.shippingAutocomplete.searchSenderName;    
    this.getReceptorNames = this.shippingAutocomplete.getReceptorNames;    
    this.searchReceptorName = this.shippingAutocomplete.searchReceptorName;    
    this.initializeAutocomplete = this.shippingAutocomplete.initializeAutocomplete;
    this._filter = this.shippingAutocomplete._filter;    
    this.patchSenderId = this.shippingAutocomplete.patchSenderId;
    this.patchSenderAddress = this.shippingAutocomplete.patchSenderAddress;
    this.patchSenderEmail = this.shippingAutocomplete.patchSenderEmail;
    this.patchSenderPhone = this.shippingAutocomplete.patchSenderPhone;
    this.patchReceptorUsers = this.shippingAutocomplete.patchReceptorUsers;
    this.senderAddressArray = this.shippingAutocomplete.senderAddressArray;
    this.senderIdArray = this.shippingAutocomplete.senderIdArray;
    this.senderEmailArray = this.shippingAutocomplete.senderEmailArray;
    this.senderPhoneArray = this.shippingAutocomplete.senderPhoneArray;

    this.patchReceptorId = this.shippingAutocomplete.patchReceptorId;
    this.patchReceptorAddress = this.shippingAutocomplete.patchReceptorAddress;    
    this.patchReceptorPhone = this.shippingAutocomplete.patchReceptorPhone;
    this.patchReceptorUsers = this.shippingAutocomplete.patchReceptorUsers;
    this.receptorAddressArray = this.shippingAutocomplete.receptorAddressArray;
    this.receptorIdArray = this.shippingAutocomplete.receptorIdArray;    
    this.receptorPhoneArray = this.shippingAutocomplete.receptorPhoneArray;
    this.receptorUserMultiple = this.shippingAutocomplete.receptorUserMultiple;
    this.receptorNameArray = this.shippingAutocomplete.receptorNameArray;
    this.senderNameArray = this.shippingAutocomplete.senderNameArray;
    this.getReceptorsLinkedToSender = this.shippingAutocomplete.getReceptorsLinkedToSender;
    this.getSendersLinkedToReceptor = this.shippingAutocomplete.getSendersLinkedToReceptor;
    this.senderSelected = this.shippingAutocomplete.senderselected;
    this.receptorSelected = this.shippingAutocomplete.receptorSelected;
    this.otherReceptorName = this.shippingAutocomplete.otherReceptorName;
    this.otherSenderName = this.shippingAutocomplete.otherSenderName;
    this.otherReceptorAddress = this.shippingAutocomplete.otherReceptorAddress;
    this.receptorAddressObject = this.shippingAutocomplete.receptorAddressObject;
    this.resetReceptor = this.shippingAutocomplete.resetReceptor;
    this.resetSender = this.shippingAutocomplete.resetSender;    
    this.receptorAutocompleted = this.shippingAutocomplete.receptorAutocompleted;
    this.isLoadingSenders = this.shippingAutocomplete.isLoadingSenders;
    this.isLoadingReceptors = this.shippingAutocomplete.isLoadingReceptors;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public _ServerService: ServerService,
    public router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public activatedRoute: ActivatedRoute,
    private dialogRef: MatDialogRef<AddShippingComponent>) {

  }

  ngOnInit() {
    window.addEventListener('scroll', this.scrollEvent, true);
    this.weeks = this.data;      
    this.shippingAutocomplete = new ShippingAutoComplete();
    this.shippingAutocomplete._ServerService = this._ServerService;
    this.shippingAutocomplete.myForm = this.myForm;
    this.initAutocompleteVariable();
    
    this.getconfig();
    this.setDefaults();
    this.getSenderNames();    
    this.getReceptorNames();
    
    
  }  

  setDefaults() {
    this.myForm.patchValue({
      description: 'Miscelaneas',
      details: 'Miscelaneas',
      province: '',
      packageQuantity: 1,
      volumetricWeight: 0,
      realWeight: 0,
      length: 0,
      width: 0,
      height: 0,
      weightToCharge: 0,
      homePickup: 0,
      shoppingAndOthers: 0,
      packing: 0,
    });
  }

  getconfig() {
    this._ServerService.getConfig('config1').subscribe(response => {
      this.config = response;
      this.fares = this.config.data;
      this.tarifHelper = new TarifHelper();
      this.calculator = new CalculatorHelper();
      this.tarifHelper.fares = this.config.data;
      this.calculator.fares = this.config.data;
      console.log(this.fares);
    })
  }

  getSenderNames;  
  searchSenderName;
  

  //-----------------------------------------------------

  getReceptorNames;  
  searchReceptorName;  
  initializeAutocomplete;  


  closeDialog(): void {
    this.dialogRef.close();
  }

  selectSenderId(item) {
    if (item === undefined) {
      this.senderIdMultiple = false;
    }
  }

  selectSenderAddress(item) {
    if (item === undefined) {
      this.senderAddressMultiple = false;
    }
  }

  selectSenderEmail(item) {
    if (item === undefined) {
      this.senderEmailMultiple = false;
    }
  }

  selectSenderPhone(item) {
    if (item === undefined) {
      this.senderPhoneMultiple = false;
    }
  }

  selectSenderName(item) {
    if (item === undefined) {
      this.otherSenderName = true;
      this.senderNameArray = [];
      this.myForm.patchValue({senderName: ""});
      this.resetSender("other");
    }else {
      let senderNameObject = this.senderNames.find(element => element.senderName === item);    
      this.searchSenderName(senderNameObject);
    }
    
  }

  selectReceptorName(item) {
    if (item === undefined) {
      this.otherReceptorName = true;
      this.receptorNameArray = [];
      this.myForm.patchValue({receptorName: ""})
      this.resetReceptor("other");
    }else {
      let receptorNameObject = this.receptorNames.find(element => element.receptorName === item);    
      this.searchReceptorName(receptorNameObject);
    } 
  }

  selectReceptorId(item) {
    if (item === undefined) {
      this.receptorIdMultiple = false;
    }
  }

  selectReceptorAddress(item) {
    if (item === undefined) {      
      this.receptorAddressMultiple = false;
      this.otherReceptorAddress = true;
      this.myForm.patchValue({ province: ""});      
    }else {
      let receptorSelected = this.receptorAddressObject.find(element => element.address === item);
      this.myForm.patchValue({ province: receptorSelected.province});
      this.otherReceptorAddress = false;      
    }
  }  

  
  selectReceptorPhone(item) {
    if (item === undefined) {
      this.receptorPhoneMultiple = false;
    }
  }


  addShipping() {
    console.log('el form :', this.myForm.value)
    this.dialogRef.close(this.myForm.value);
  }

  public get packageQuantityDisabled() {
    if (this.shippingType == 'ENVIOS +2 KG') {
      this.packageQuantity = 1;
      return true
    } else {
      return false
    }
  }  

 
  private _province: string
  public get province(): string {
    return this._province
  }
  public set province(value: string) {
    this._province = value;
    if (value == "La Habana") {
    
    } else {
      if (this.shippingType == 'ENVIOS +2 KG') {
        if (this.customsDutyType == '') {
          this.customsDutyType = 'Arancel por importar misceláneas con peso en exceso de los 1.5 kg';
        }
      }
    }
  }

  private _customsDutyType: string;
  public get customsDutyType(): string {
    return this._customsDutyType;
  }
  public set customsDutyType(value: string) {
    this._customsDutyType = value;    
  }


  private _shippingType: string;
  public get shippingType(): string {
    return this._shippingType
  }
  public set shippingType(value: string) {
    this._shippingType = value;
    console.log("shippingType", value);
    if (value == 'ENVIOS 1.5 KG') {
      this.volumetricWeight = this.realWeight = 1.5 * this.packageQuantity;

    } else {
      if (this.customsDutyType != 'Sin Arancel' && value != '' && value != undefined) {
        this.customsDutyType = 'Arancel por importar misceláneas con peso en exceso de los 1.5 kg';
      }
    }    
  }

  private _packageQuantity: number = 1
  public get packageQuantity(): number {
    return this._packageQuantity
  }

  public set packageQuantity(value: number) {
    this._packageQuantity = value;
    if (this.shippingType == 'ENVIOS 1.5 KG') {
      this.volumetricWeight = this.realWeight = 1.5 * value;
    }
    this.myForm.patchValue({
      packageQuantity: value
    })
    
  }


  private _width: number = 0;
  private _height: number = 0;
  private _length: number = 0;



  private static VOLWEIGHTFACTOR: number = 6000
  private static RESTORECALCULATIONS: number = 100
  private setVolWeighFromSizes() {
    if (!this._calculatedSizesFromVolume) {
      this._calculatedFromSizes = true;
      setTimeout(() => { this._calculatedFromSizes = false }, AddShippingComponent.RESTORECALCULATIONS)
      this.volumetricWeight =
        Math.ceil(this.width * this.height * this.length / AddShippingComponent.VOLWEIGHTFACTOR);
    }
  }

  public get width(): number {
    return this._width;
  }
  public set width(value: number) {
    this._width = value;
    this.setVolWeighFromSizes();
  }

  public get height(): number {
    return this._height;
  }
  public set height(value: number) {
    this._height = value;
    this.setVolWeighFromSizes();
  }

  public get length(): number {
    return this._length;
  }
  public set length(value: number) {
    this._length = value;
    this.setVolWeighFromSizes();   
  }

  private _calculatedFromSizes = false;
  private _calculatedSizesFromVolume = false;
  private _volumetricWeight: number = 0;
  public get volumetricWeight(): number {
    return this._volumetricWeight
  }
  public set volumetricWeight(value: number) {
    console.log("SET volwiehgt", value)
    this._volumetricWeight = value;
    
    if (!this._calculatedFromSizes) {
      this._calculatedSizesFromVolume = true;
      setTimeout(() => {
        this._calculatedSizesFromVolume = false;
      }, 100)
      this.width = this.height = this.length =
        Math.round(
          Math.cbrt(value * 6000)
        )
    }

    if (value == 0 || value == undefined) {
      return
    }
    if (parseInt(`${value}`) > parseInt(`${this.realWeight}`)) {
      this.weightToCharge = value
    } else {
      this.weightToCharge = this.realWeight;
    }

  }

  private _realWeight: number = 0;
  public get realWeight(): number {
    return this._realWeight;
  }
  public set realWeight(value: number) {
    console.log("SET wiehgt", value, this.volumetricWeight);    
    this._realWeight = value;
    if (value == 0 || value == undefined) {
      return
    }
    if (this.volumetricWeight == 0) {
      this.volumetricWeight = value
    }
    console.log("before if", value, this.volumetricWeight, value > this.volumetricWeight);
    if (parseInt(`${value}`) > parseInt(`${this.volumetricWeight}`)) {
      this.weightToCharge = value
    } else {
      this.weightToCharge = this.volumetricWeight;
    }
  }

  private _weightToCharge: number = 0;
  public get weightToCharge(): number {
    return this._weightToCharge;
  }
  public set weightToCharge(value: number) {
    console.log("SET weightToCharge", value)
    if (value == 0 || value == undefined) {
      return
    }
    this._weightToCharge = value;    
    if (this.volumetricWeight == 0) {
      this.volumetricWeight = value
    }
    if (this.realWeight == 0) {
      this.realWeight = value;
    }
  }


  public get formInvalid() {
    console.log(this.shippingType, this.weightToCharge)
    console.log(this.shippingType !== 'ENVIOS 1.5 KG' && this.weightToCharge < 2)
    return this.shippingType !== 'ENVIOS 1.5 KG' && this.weightToCharge < 2 ? true : false;
  }

  scrollEvent = (event: any): void => {
    if(this.autoComplete1 && this.autoComplete1.panelOpen && this.autoComplete1.panelOpen !== undefined )
      //this.autoComplete1.closePanel();
      this.autoComplete1.updatePosition();
    if(this.autoComplete2 && this.autoComplete2.panelOpen && this.autoComplete2.panelOpen !== undefined )
      //this.autoComplete2.closePanel();
      this.autoComplete2.updatePosition(); 
};
}
