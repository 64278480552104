import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServerService } from '../../services/server.service';

import { MatPaginator } from '@angular/material/paginator';

declare var $: any;
@Component({
  selector: 'app-get-articles',
  templateUrl: './get-articles.component.html',
  styleUrls: ['./get-articles.component.scss'],
})
export class GetArticlesComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  p1 = 1;
  p2 = 1;

  data: any;
  dataSection: any;

  constructor(private activatedRoute: ActivatedRoute, public router: Router,
    public server: ServerService) { }

  ngOnInit() {
    this.getSection();
  }

  getSection() {
    this.server.getSection(this.activatedRoute.snapshot.params.id)
      .subscribe(response => {
        this.data = response;
        this.dataSection = this.data.data;
      });
  }

  addArticleToSection(): any {
    this.router.navigate(['add-article-to-section/' + this.activatedRoute.snapshot.params.id]);
  }

  addParagraphToArticle(id: any): any {
    this.router.navigate(['get-articles/' + this.dataSection._id + '/add-paragraph-to-article/' + id]);
  }

  getParagraphs(id: any) {
    this.router.navigate(['get-articles/' + this.dataSection._id + '/get-paragraphs/' + id]);
  }

  articleEdit(id: string): any {
    this.router.navigate(['get-articles/' + this.dataSection._id + '/edit-article/' + id]);
  }

  articleDelete(id: string): boolean {
    if (confirm('¿DESEA ELIMINAR ESTE ARTÍCULO?')) {
      this.data = null;
      this.server.articleDelete(id)
        .subscribe(data => {
          this.data = data;
          this.ngOnInit();
          this.showNotification('top', 'center', 2, 2, 'ARTÍCULO ELIMINADO SATISFACTORIAMENTE!!!')
          if (this.data.msg != null) {
            return true;
          }
        });
    }
    return false;
  }

  showNotification(from, align, col, time, message) {
    const type = ['', 'info', 'success', 'warning', 'danger'];

    // const color = Math.floor((Math.random() * 4) + 1);
    // 4 red 3 yellow 2 green 1 blue
    const color = col;

    $.notify({
      icon: 'notifications',
      message: message

    }, {
      type: type[color],
      timer: time * 1000,
      placement: {
        from: from,
        align: align
      },
      template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span class="text-center" data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }
}
